import { addresses as ADDRESS } from "../configs/constants";
import { SupportedChainId } from "./chainENV/chains.base";
import { getTokenBySymbol } from "src/configs/Tokens";
import DLP_1 from "src/assets/images/tokens/DLP-β.svg";
// import DLP_2 from "src/assets/images/tokens/DLP-α.svg";

//  DLP 默认查询  数据列表
type ListProps = {
  name: DLP_TYPES;
  icon: any;
  top: string;
  Dlp_address: string;
  DlpManager_address: string;
  vault_address: string;
  orderBook_address: string;
  isGNS?: boolean; // 是否是gns 模式
  routerSign_address?: string; // 新版签名模式
  vaultUtil_addr?: string; // 新版查询
  fee_router?: string;
};

type LpListProps = {
  name: string;
  stakeToken: string;
  isLp: boolean;
  pool: string;
  imgUrl: any;
  stablecoin: boolean;
  rewardToken: string;
  token0?: {
    address: string;
    decimals: number;
  };
  token1?: {
    address: string;
    decimals: number;
  };
};

export enum DLP_TYPES {
  DLP_1 = "DLP-β",
  // DLP_2 = "DLP-α",
}
export const DLP_LIST: { [key: number]: ListProps[] } = {
  [SupportedChainId.ARB_TEST]: [
    {
      name: DLP_TYPES.DLP_1,
      icon: DLP_1,
      top: "Top 5 ETF",
      Dlp_address: ADDRESS[SupportedChainId.ARB_TEST].PLP_β,
      vault_address: ADDRESS[SupportedChainId.ARB_TEST].Vault_PLP_β,
      vaultUtil_addr: ADDRESS[SupportedChainId.ARB_TEST].VaultUtils_PLP_β,
      DlpManager_address: ADDRESS[SupportedChainId.ARB_TEST].DlpManager_PLP_β,
      orderBook_address: ADDRESS[SupportedChainId.ARB_TEST].OrderBook_PLP_β,
      routerSign_address: ADDRESS[SupportedChainId.ARB_TEST].RouterSign_PLP_β,
      fee_router: ADDRESS[SupportedChainId.ARB_TEST].FeeRouter_PLP_β,
      isGNS: true,
    },
  ],
  [SupportedChainId.BLAST_TEST]: [
    {
      name: DLP_TYPES.DLP_1,
      icon: DLP_1,
      top: "Top 5 ETF",
      Dlp_address: ADDRESS[SupportedChainId.BLAST_TEST].PLP_β,
      vault_address: ADDRESS[SupportedChainId.BLAST_TEST].Vault_PLP_β,
      vaultUtil_addr: ADDRESS[SupportedChainId.BLAST_TEST].VaultUtils_PLP_β,
      DlpManager_address: ADDRESS[SupportedChainId.BLAST_TEST].DlpManager_PLP_β,
      orderBook_address: ADDRESS[SupportedChainId.BLAST_TEST].OrderBook_PLP_β,
      routerSign_address: ADDRESS[SupportedChainId.BLAST_TEST].RouterSign_PLP_β,
      fee_router: ADDRESS[SupportedChainId.BLAST_TEST].FeeRouter_PLP_β,
      isGNS: true,
    },
  ],
};

export const STAKE_LP_LIST: { [key: number]: LpListProps[] } = {
  [SupportedChainId.ARB_TEST]: [],
  [SupportedChainId.BLAST_TEST]: [],
};

export const PAIR_DETAILS: {
  [key: number]: {
    [lp: string]:
      | {
          token0: {
            address: string;
            decimals: number;
            name: string;
          };
          token1: {
            address: string;
            decimals: number;
            name: string;
          };
        }
      | undefined;
  };
} = {
  [SupportedChainId.ARB_TEST]: {
    "": {
      token0: {
        address: "",
        decimals: 18,
        name: "weth",
      },
      token1: {
        address: "",
        decimals: 18,
        name: "dyna",
      },
    },
  },
  [SupportedChainId.BLAST_TEST]: {
    "": {
      token0: {
        address: "",
        decimals: 18,
        name: "weth",
      },
      token1: {
        address: "",
        decimals: 18,
        name: "dyna",
      },
    },
  },
};

export function getNormalizedTokenSymbol(tokenSymbol) {
  if (["WBTC", "WETH", "WBNB"].includes(tokenSymbol)) {
    return tokenSymbol.substr(1);
  } else if (tokenSymbol === "BTC.b") {
    return "BTC";
  }
  return tokenSymbol;
}

const AVAILABLE_CHART_TOKENS = {
  // [SupportedChainId.ZKTEST]: [
  //   "ETH",
  //   "BTC",
  //   "BNB",
  //   "DOGE",
  //   "ARB",
  //   "LINK",
  //   "MATIC",
  //   "DOT",
  // ],
  // [SupportedChainId.ZKMAINNET]: ["ETH", "BTC", "USDB"],
  // [SupportedChainId.MANTLE_TEST]: [
  //   "ETH",
  //   "BTC",
  //   "BNB",
  //   "DOGE",
  //   "ARB",
  //   "LINK",
  //   "MATIC",
  //   "DOT",
  // ],
  // [SupportedChainId.MANTLE]: [
  //   "ETH",
  //   "BTC",
  //   "MNT",
  //   "ARB",
  //   "OP",
  //   "BNB",
  //   "SOL",
  //   "SUI",
  //   "DOGE",
  //   "MATIC",
  //   "PEPE",
  // ],
  [SupportedChainId.BLAST_TEST]: [
    "ETH",
    "BTC",
    "USDB",
    "SOL",
    "AVAX",
    "BNB",
    "ARB",
    "ORDI",
    "LINK",
  ],
  [SupportedChainId.ARB_TEST]: ["ETH", "BTC", "USDB"],
};

export function isChartAvailabeForToken(chainId: number, tokenSymbol: string) {
  const token = getTokenBySymbol(chainId, tokenSymbol);
  if (!token) return false;

  return (
    (token.isStable ||
      AVAILABLE_CHART_TOKENS[chainId]?.includes(
        getNormalizedTokenSymbol(tokenSymbol)
      )) ??
    false
  );
}
