import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ChainBtn = styled(Button)({
  height: "40px",
  lineHeight: "40px",
  maxHeight: "40px",
  color: "#FFFFFF",
  fontSize: 14,
  fontFamily: "Gilroy-Bold",
  textTransform: "uppercase",
  padding: "0 12px",
  background: "transparent",
  border: "none",
  borderRadius: "4px",
  ".downIcon": {
    marginLeft: "10px",
  },
  "&:hover": {
    background: "#FFFD02",
    color: "#ffffff",
  },
  "&.hoverbtns": {
    "&:hover": {
      background: "rgba(255, 255, 255, 0.2)",
    },
  },
});
