import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useGetDLPData = (dlpName?: string) => {
  const DLPMAP = useSelector((state: any) => {
    return state.app.DLPMAP || {};
  });
  return useMemo(() => {
    if (!dlpName || !DLPMAP[dlpName]) return {};
    const {
      routerSign_address,
      router_address,
      positionRouter_address,
      orderBook_address,
    } = DLPMAP[dlpName];
    return {
      routerAddress: router_address,
      routerSign_addr: routerSign_address,
      positionRouterAddress: positionRouter_address,
      orderBookAddress: orderBook_address,
      ...DLPMAP[dlpName],
    };
  }, [DLPMAP, dlpName]);
};
