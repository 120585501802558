import { Box, Dialog, useMediaQuery } from "@material-ui/core";
import { useCallback, useState, useMemo } from "react";

import "../../views/dashboard/dialog.scss";
import "./ConfirmationBox.css";
import PendingIcon from "../../assets/images/loading/pending.svg";
import {
  USD_DECIMALS,
  PRECISION,
  BASIS_POINTS_DIVISOR,
  LIMIT,
  MIN_PROFIT_TIME,
  INCREASE,
  expandDecimals,
  getExchangeRate,
  getProfitPrice,
  getTimeRemaining,
  formatAmount,
  formatAmountFree,
  useLocalStorageSerializeKey,
  getExchangeRateDisplay,
  DEFAULT_SLIPPAGE_AMOUNT,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  SLIPPAGE_BPS_KEY,
  formatDateTime,
  calculatePositionDelta,
  DECREASE,
  TRIGGER_PREFIX_ABOVE,
  TRIGGER_PREFIX_BELOW,
} from "../../helpers/Helpers";
import cx from "classnames";
import { getConstant } from "../../configs/getConstant";
import { getContractAddress } from "../../Addresses";

import { cancelDecreaseOrder, handleCancelOrder } from "../../Api";
import {
  getNativeToken,
  getToken,
  getWrappedToken,
} from "../../configs/Tokens";
import { useWeb3Context } from "src/hooks";
import Warn from "../../assets/images/warn.svg";
import InputSelect from "src/components/InputSelect";
import { ReactComponent as Opnepositn } from "src/assets/images/opnepositn.svg";
import OpenBg from "src/assets/images/trade/openBg.svg";
import Tooltips from "../Tooltips/totalTips";
import Column from "../Column";
import { ConfirmBtn } from "../Button/ConfirmBtn";
import { useSelector } from "react-redux";
import { useGetToken } from "src/hooks/useGetToken";
import DialogBox from "src/components/DialogBox";
import styled from "styled-components";
import Confimbg from "src/assets/images/trade/confimbg.png";
import Row, { RowBetween } from "../Row";
import {
  FeeBox,
  Klines,
  OpenBox,
  OpenCardBox,
  H5RowBetween,
} from "./ConfirmStyles";
import { ThemedText } from "src/theme/text";
import {} from "@lingui/macro";
import Dots from "src/components/Dots";
import { ReactComponent as ToIcon } from "src/assets/images/to.svg";
import { CancelBtn } from "../Button/CancelBtn";
import { BuyBtn } from "src/components/Button/BuyBtn";
import { ClaimBtn } from "src/components/Button/ClaimBtn";
const HIGH_SPREAD_THRESHOLD = expandDecimals(1, USD_DECIMALS).div(100); // 1%;

function getSpread(fromTokenInfo, toTokenInfo, isLong, nativeTokenAddress) {
  if (
    fromTokenInfo &&
    fromTokenInfo.maxPrice &&
    toTokenInfo &&
    toTokenInfo.minPrice
  ) {
    const fromDiff = fromTokenInfo.maxPrice.sub(fromTokenInfo.minPrice).div(2);
    const fromSpread = fromDiff
      .mul(PRECISION)
      .div(fromTokenInfo.maxPrice.add(fromTokenInfo.minPrice).div(2));
    const toDiff = toTokenInfo.maxPrice.sub(toTokenInfo.minPrice).div(2);
    const toSpread = toDiff
      .mul(PRECISION)
      .div(toTokenInfo.maxPrice.add(toTokenInfo.minPrice).div(2));

    let value = fromSpread.add(toSpread);

    const fromTokenAddress = fromTokenInfo.isNative
      ? nativeTokenAddress
      : fromTokenInfo.address;
    const toTokenAddress = toTokenInfo.isNative
      ? nativeTokenAddress
      : toTokenInfo.address;

    if (isLong && fromTokenAddress === toTokenAddress) {
      value = fromSpread;
    }

    return {
      value,
      isHigh: value.gt(HIGH_SPREAD_THRESHOLD),
    };
  }
}

const BgBox = styled(Box)`
  background: url(${Confimbg}) no-repeat;
`;
export default function ConfirmationBox(props) {
  const isVerySmallScreen = useMediaQuery("(max-width: 768px)");
  const {
    dlpName,
    fromToken,
    fromTokenInfo,
    toToken,
    toTokenInfo,
    isSwap,
    isLong,
    isMarketOrder,
    orderOption,
    isShort,
    toAmount,
    fromAmount,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    onConfirmationClick,
    setIsConfirming,
    shortCollateralAddress,
    hasExistingPosition,
    leverage,
    existingPosition,
    existingLiquidationPrice,
    displayLiquidationPrice,
    shortCollateralToken,
    isPendingConfirmation,
    triggerPriceUsd,
    triggerRatio,
    fees,
    feesUsd,
    isSubmitting,
    fromUsdMin,
    toUsdMax,
    nextAveragePrice,
    collateralTokenAddress,
    feeBps,
    orders,
    library,
    setPendingTxns,
    pendingTxns,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
  } = props;
  const handleClose = () => {
    setIsConfirming(false);
  };

  const { chainID } = useWeb3Context();

  const nativeTokenSymbol = getConstant(chainID, "nativeTokenSymbol");

  const [savedSlippageAmount] = useLocalStorageSerializeKey(
    [chainID, SLIPPAGE_BPS_KEY],
    DEFAULT_SLIPPAGE_AMOUNT
  );
  const [isProfitWarningAccepted, setIsProfitWarningAccepted] = useState(false);
  const [isTriggerWarningAccepted, setIsTriggerWarningAccepted] =
    useState(false);
  const [isLimitOrdersVisible, setIsLimitOrdersVisible] = useState(false);

  const onCancelOrderClick = useCallback(
    (order) => {
      handleCancelOrder(dlpName, chainID, library, order, {
        pendingTxns,
        setPendingTxns,
      });
    },
    [library, pendingTxns, setPendingTxns, chainID]
  );

  let minOut;
  let fromTokenUsd;
  let toTokenUsd;

  let collateralAfterFees = fromUsdMin;
  if (feesUsd) {
    collateralAfterFees = fromUsdMin.sub(feesUsd);
  }

  if (isSwap) {
    minOut = toAmount
      .mul(BASIS_POINTS_DIVISOR - savedSlippageAmount)
      .div(BASIS_POINTS_DIVISOR);

    fromTokenUsd = fromTokenInfo
      ? formatAmount(fromTokenInfo.minPrice, USD_DECIMALS, 2, true)
      : 0;
    toTokenUsd = toTokenInfo
      ? formatAmount(toTokenInfo.maxPrice, USD_DECIMALS, 2, true)
      : 0;
  }

  const getTitle = () => {
    if (!isMarketOrder) {
      return "CONFIRM LIMIT ORDER";
    }
    if (isSwap) {
      return "CONFIRM SWAP";
    }
    return isLong ? "CONFIRM " : "CONFIRM";
  };
  const title = getTitle();

  const existingOrder = useMemo(() => {
    const wrappedToken = getWrappedToken(chainID);
    for (const order of orders) {
      if (order.type !== INCREASE) continue;
      const sameToken =
        order.indexToken === wrappedToken.address
          ? toToken.isNative
          : order.indexToken === toToken.address;
      if (order.isLong === isLong && sameToken) {
        return order;
      }
    }
  }, [orders, chainID, isLong, toToken.address, toToken.isNative]);

  const existingOrders = useMemo(() => {
    const wrappedToken = getWrappedToken(chainID);
    return orders.filter((order) => {
      if (order.type !== INCREASE) return false;
      const sameToken =
        order.indexToken === wrappedToken.address
          ? toToken.isNative
          : order.indexToken === toToken.address;
      return order.isLong === isLong && sameToken;
    });
  }, [orders, chainID, isLong, toToken.address, toToken.isNative]);

  const existingTriggerOrders = useMemo(() => {
    const wrappedToken = getWrappedToken(chainID);
    return orders.filter((order) => {
      if (order.type !== DECREASE) return false;
      const sameToken =
        order.indexToken === wrappedToken.address
          ? toToken.isNative
          : order.indexToken === toToken.address;
      return order.isLong === isLong && sameToken;
    });
  }, [orders, chainID, isLong, toToken.address, toToken.isNative]);

  const decreaseOrdersThatWillBeExecuted = useMemo(() => {
    if (isSwap) return [];
    return existingTriggerOrders.filter((order) => {
      if (order.triggerAboveThreshold) {
        return existingPosition?.markPrice.gte(order.triggerPrice);
      } else {
        return existingPosition?.markPrice.lte(order.triggerPrice);
      }
    });
  }, [existingPosition, existingTriggerOrders, isSwap]);

  const getError = () => {
    if (!isSwap && hasExistingPosition && !isMarketOrder) {
      const { delta, hasProfit } = calculatePositionDelta(
        triggerPriceUsd,
        existingPosition
      );
      if (hasProfit && delta.eq(0)) {
        return "Invalid price, see warning";
      }
    }
    if (isMarketOrder && hasPendingProfit && !isProfitWarningAccepted) {
      return "Forfeit profit not checked";
    }
    return false;
  };

  const getPrimaryText = () => {
    if (
      decreaseOrdersThatWillBeExecuted.length > 0 &&
      !isTriggerWarningAccepted
    ) {
      return `Accept confirmation of trigger orders`;
    }

    if (!isPendingConfirmation) {
      const error = getError();
      if (error) {
        return error;
      }

      if (isSwap) {
        return title;
      }
      const action = isMarketOrder
        ? isLong
          ? "Long"
          : "Short"
        : "Create Order";

      if (
        isMarketOrder &&
        MIN_PROFIT_TIME > 0 &&
        hasExistingPosition &&
        existingPosition.delta.eq(0) &&
        existingPosition.pendingDelta.gt(0)
      ) {
        return isLong
          ? `Forfeit profit and ${action}`
          : `Forfeit profit and Short`;
      }

      return isMarketOrder && MIN_PROFIT_TIME > 0
        ? `Accept minimum and ${action}`
        : action;
    }

    if (!isMarketOrder) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <div className="">Creating Order</div>
          <img src={PendingIcon} height={20} className="ml-12" />
        </Box>
      );
    }
    if (isSwap) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <div className="">Swapping</div>
          <img src={PendingIcon} height={20} className="ml-12" />
        </Box>
      );
    }
    if (isLong) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <div className="">Longing</div>
          <img src={PendingIcon} height={20} className="ml-12" />
        </Box>
      );
    }
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <div className="">Shorting</div>
        <img src={PendingIcon} height={20} className="ml-12" />
      </Box>
    );
  };
  const isPrimaryEnabled = () => {
    if (getError()) {
      return false;
    }
    if (
      decreaseOrdersThatWillBeExecuted.length > 0 &&
      !isTriggerWarningAccepted
    ) {
      return false;
    }
    return !isPendingConfirmation && !isSubmitting;
  };
  const nativeTokenAddress = getContractAddress(chainID, "NATIVE_TOKEN");
  const spread = getSpread(
    fromTokenInfo,
    toTokenInfo,
    isLong,
    nativeTokenAddress
  );
  // it's meaningless for limit/stop orders to show spread based on current prices
  const showSpread = isMarketOrder && !!spread;

  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }

  const renderSpreadWarning = useCallback(() => {
    if (!isMarketOrder) {
      return null;
    }

    if (spread && spread.isHigh) {
      return (
        <div className="Confirmation-box-warning">
          The spread is {`>`} 1%, please ensure the trade details are acceptable
          before comfirming
        </div>
      );
    }
  }, [isMarketOrder, spread]);

  const renderFeeWarning = useCallback(() => {
    if (orderOption === LIMIT || !feeBps || feeBps <= 60) {
      return null;
    }

    if (isSwap) {
      return (
        <div className="Confirmation-box-warning">
          Fees are high to swap from {fromToken.symbol} to {toToken.symbol}.
        </div>
      );
    }

    if (!collateralTokenAddress) {
      return null;
    }

    const collateralToken = useGetToken(collateralTokenAddress);
    return (
      <div className="Confirmation-box-warning">
        Fees are high to swap from {fromToken.symbol} to{" "}
        {collateralToken.symbol}. <br />
        {collateralToken.symbol} is needed for collateral.
      </div>
    );
  }, [
    feeBps,
    isSwap,
    collateralTokenAddress,
    chainID,
    fromToken.symbol,
    toToken.symbol,
    orderOption,
  ]);

  const hasPendingProfit =
    MIN_PROFIT_TIME > 0 &&
    existingPosition &&
    existingPosition.delta.eq(0) &&
    existingPosition.pendingDelta.gt(0);

  const renderMinProfitWarning = useCallback(() => {
    if (MIN_PROFIT_TIME === 0) {
      return null;
    }
    if (!isSwap) {
      if (hasExistingPosition) {
        const minProfitExpiration =
          existingPosition.lastIncreasedTime + MIN_PROFIT_TIME;
        if (
          isMarketOrder &&
          existingPosition.delta.eq(0) &&
          existingPosition.pendingDelta.gt(0)
        ) {
          const profitPrice = getProfitPrice(
            existingPosition.markPrice,
            existingPosition
          );
          return (
            <div className="Confirmation-box-warning">
              Increasing this position at the current price will forfeit a&nbsp;
              <a href="" target="_blank" rel="noopener noreferrer">
                pending profit
              </a>{" "}
              of {existingPosition.deltaStr}.<br />
              <br />
              Profit price: {existingPosition.isLong ? ">" : "<"} $
              {formatAmount(profitPrice, USD_DECIMALS, 2, true)}. This rule only
              applies for the next {getTimeRemaining(minProfitExpiration)},
              until {formatDateTime(minProfitExpiration)}.
            </div>
          );
        }
        if (!isMarketOrder) {
          const { delta, hasProfit } = calculatePositionDelta(
            triggerPriceUsd,
            existingPosition
          );
          if (hasProfit && delta.eq(0)) {
            const profitPrice = getProfitPrice(
              existingPosition.markPrice,
              existingPosition
            );
            return (
              <div className="Confirmation-box-warning">
                This order will forfeit a&nbsp;
                <a href="" target="_blank" rel="noopener noreferrer">
                  profit
                </a>{" "}
                of {existingPosition.deltaStr}.<br />
                Profit price: {existingPosition.isLong ? ">" : "<"} $
                {formatAmount(profitPrice, USD_DECIMALS, 2, true)}. This rule
                only applies for the next{" "}
                {getTimeRemaining(minProfitExpiration)}, until{" "}
                {formatDateTime(minProfitExpiration)}.
              </div>
            );
          }
        }
      }

      return (
        <div className="Confirmation-box-warning">
          A minimum price change of&nbsp;
          <a href="" target="_blank" rel="noopener noreferrer">
            1.5%
          </a>{" "}
          is required for a position to be in profit. This only applies for the
          first {MIN_PROFIT_TIME / 60 / 60} hours after increasing a position.
        </div>
      );
    }
  }, [
    isSwap,
    hasExistingPosition,
    existingPosition,
    isMarketOrder,
    triggerPriceUsd,
  ]);

  const renderExistingOrderWarning = useCallback(() => {
    if (isSwap || !existingOrder) {
      return;
    }

    const indexToken = useGetToken(existingOrder.indexToken);
    const sizeInToken = formatAmount(
      existingOrder.sizeDelta.mul(PRECISION).div(existingOrder.triggerPrice),
      USD_DECIMALS,
      4,
      true
    );
    if (existingOrders?.length > 1) {
      return (
        <div>
          <div className="Confirmation-box-info">
            <span>
              You have multiple existing Increase{" "}
              {existingOrder.isLong ? "Long" : "Short"} {indexToken.symbol}{" "}
              limit orders{" "}
            </span>
            <span
              onClick={() => setIsLimitOrdersVisible((p) => !p)}
              className="view-orders"
            >
              ({isLimitOrdersVisible ? "hide" : "view"})
            </span>
          </div>
          {isLimitOrdersVisible && (
            <ul className="order-list">
              {existingOrders.map((order) => {
                const {
                  account,
                  index,
                  type,
                  triggerAboveThreshold,
                  triggerPrice,
                } = order;
                const id = `${account}-${index}`;
                const triggerPricePrefix = triggerAboveThreshold
                  ? TRIGGER_PREFIX_ABOVE
                  : TRIGGER_PREFIX_BELOW;
                const indexToken = useGetToken(order.indexToken);

                return (
                  <li key={id} className="font-sm">
                    <p>
                      {type === INCREASE ? "Increase" : "Decrease"}{" "}
                      {indexToken.symbol} {isLong ? "Long" : "Short"} &nbsp;
                      {triggerPricePrefix} $
                      {formatAmount(triggerPrice, USD_DECIMALS, 2, true)}
                    </p>
                    <button onClick={() => onCancelOrderClick(order)}>
                      Cancel
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      );
    }
    return (
      <div className="Confirmation-box-info">
        You have an active Limit Order to Increase{" "}
        {existingOrder.isLong ? "Long" : "Short"} {sizeInToken}{" "}
        {indexToken.symbol} ($
        {formatAmount(existingOrder.sizeDelta, USD_DECIMALS, 2, true)}) at price
        ${formatAmount(existingOrder.triggerPrice, USD_DECIMALS, 2, true)}
      </div>
    );
  }, [
    existingOrder,
    isSwap,
    chainID,
    existingOrders,
    isLong,
    isLimitOrdersVisible,
    onCancelOrderClick,
  ]);

  const renderExistingTriggerErrors = useCallback(() => {
    if (isSwap || decreaseOrdersThatWillBeExecuted?.length < 1) {
      return;
    }
    const existingTriggerOrderLength = decreaseOrdersThatWillBeExecuted.length;
    const orderText = existingTriggerOrderLength > 1 ? "orders" : "order";
    return (
      <>
        <div className="Confirmation-box-warning">
          You have{" "}
          {existingTriggerOrderLength > 1
            ? `${existingTriggerOrderLength}`
            : "an"}{" "}
          active trigger {orderText} that might execute immediately after you
          open this position. Please cancel the {orderText} or accept the
          confirmation to continue.
        </div>
        <ul className="order-list">
          {decreaseOrdersThatWillBeExecuted.map((order) => {
            const {
              account,
              index,
              type,
              triggerAboveThreshold,
              triggerPrice,
            } = order;
            const id = `${account}-${index}`;
            const triggerPricePrefix = triggerAboveThreshold
              ? TRIGGER_PREFIX_ABOVE
              : TRIGGER_PREFIX_BELOW;
            const indexToken = useGetToken(order.indexToken);
            return (
              <li key={id}>
                <p>
                  {type === INCREASE ? "Increase" : "Decrease"}{" "}
                  {indexToken.symbol} {isLong ? "Long" : "Short"}
                  &nbsp;{triggerPricePrefix} $
                  {formatAmount(triggerPrice, USD_DECIMALS, 2, true)}
                </p>
                <button
                  onClick={() =>
                    cancelDecreaseOrder(chainID, library, index, {
                      successMsg: "Order cancelled",
                      failMsg: "Cancel failed",
                      sentMsg: "Cancel submitted",
                      pendingTxns,
                      setPendingTxns,
                    })
                  }
                >
                  Cancel
                </button>
              </li>
            );
          })}
        </ul>
      </>
    );
  }, [
    decreaseOrdersThatWillBeExecuted,
    isSwap,
    chainID,
    library,
    pendingTxns,
    setPendingTxns,
    isLong,
  ]);

  const renderExistingTriggerWarning = useCallback(() => {
    if (
      isSwap ||
      existingTriggerOrders.length < 1 ||
      decreaseOrdersThatWillBeExecuted.length > 0 ||
      renderExistingOrderWarning()
    ) {
      return;
    }
    const existingTriggerOrderLength = existingTriggerOrders.length;
    return (
      <div className="Confirmation-box-info">
        You have{" "}
        {existingTriggerOrderLength > 1
          ? `${existingTriggerOrderLength}`
          : "an"}{" "}
        active trigger {existingTriggerOrderLength > 1 ? "orders" : "order"}{" "}
        that could impact this position.
      </div>
    );
  }, [
    existingTriggerOrders,
    isSwap,
    decreaseOrdersThatWillBeExecuted,
    renderExistingOrderWarning,
  ]);

  const renderMain = useCallback(() => {
    if (isSwap) {
      return (
        <OpenBox>
          <OpenCardBox>
            <div className="">
              <RowBetween className="lebse" pb={"14px"}>
                <Box className=" font-14 font600 color14">Pay</Box>
              </RowBetween>
              <RowBetween className="lebseimg" pb={"6px"}>
                <Row
                  gap={"8px"}
                  width={"atuo"}
                  className="font-18 color28 font700"
                >
                  <img width={30} src={fromToken?.imageUrl} alt="" />{" "}
                  {fromToken.symbol}
                </Row>
                <Row
                  gap={"8px"}
                  width={"atuo"}
                  className="font-18 color28 font700"
                >
                  <img width={30} src={toToken?.imageUrl} alt="" />{" "}
                  {toToken.symbol}
                </Row>
              </RowBetween>
            </div>
            <RowBetween gap={"18px"} alignItems={"center"}>
              <Row>
                <div className="openLine"></div>
              </Row>
              <img src={OpenBg} alt="" />
              <Row>
                <div className="openLine"></div>
              </Row>
            </RowBetween>
            <Box className="clsbtm" mt={"4px"}>
              <RowBetween className="toamout">
                <div className="font-20 font700 color28">
                  {formatAmount(fromAmount, fromToken.decimals, 4, true)}
                </div>
                <div className={cx("font-20 font700 color28")}>
                  {formatAmount(toAmount, toToken.decimals, 4, true)}
                </div>
              </RowBetween>
              <RowBetween className="tousd">
                <div className="font-14  color14">
                  {`$${formatAmount(fromUsdMin, USD_DECIMALS, 2, true)}`}
                </div>
                <div className="font-14  color14">{`$${formatAmount(
                  toUsdMax,
                  USD_DECIMALS,
                  2,
                  true
                )}`}</div>
              </RowBetween>
            </Box>
          </OpenCardBox>

          <H5RowBetween gap={"18px"} mt={"16px"}>
            <Box flex={"1"} className="stakeInfo" pl={"0"}>
              <div className="infoItem">
                <Box display="flex" alignItems="center" pl={"0px"}>
                  <RowBetween
                    display="flex"
                    alignItems="center"
                    className="infoTitle"
                    pl={"0"}
                  >
                    <Row>
                      <Dots bgColor="#FFFD02" />
                      <ThemedText.Text4Bold pl="8px">
                        Min. Receive
                      </ThemedText.Text4Bold>
                    </Row>
                    <Row>
                      <Dots bgColor="#FFFD02" />
                      <ThemedText.Text4Bold ml="8px">
                        Price
                      </ThemedText.Text4Bold>
                    </Row>
                  </RowBetween>
                </Box>
                <Box display="flex" alignItems="center" className="infoVal">
                  <RowBetween>
                    <Row>
                      <ThemedText.Text9Bold fontSize={14} alignItems={"center"}>
                        {formatAmount(minOut, toTokenInfo.decimals, 4, true)}{" "}
                        {toTokenInfo.symbol}
                      </ThemedText.Text9Bold>
                    </Row>
                    <Row>
                      <ThemedText.Text9Bold fontSize={14} alignItems={"center"}>
                        {getExchangeRateDisplay(
                          getExchangeRate(fromTokenInfo, toTokenInfo),
                          fromTokenInfo,
                          toTokenInfo
                        )}
                      </ThemedText.Text9Bold>
                    </Row>
                  </RowBetween>
                </Box>
              </div>
              <div className="infoItem">
                <RowBetween
                  display="flex"
                  alignItems="center"
                  className="infoTitle"
                >
                  <Row>
                    <Dots bgColor="#FFFD02" />
                    <ThemedText.Text4Bold ml="8px">Spread</ThemedText.Text4Bold>
                  </Row>
                  <Row>
                    <Dots bgColor="#FFFD02" />
                    <ThemedText.Text4Bold ml="8px">Fees</ThemedText.Text4Bold>
                  </Row>
                </RowBetween>
                <Box display="flex" alignItems="center" className="infoVal">
                  <RowBetween>
                    <Row>
                      <ThemedText.Text9Bold fontSize={14} alignItems={"center"}>
                        {formatAmount(
                          spread.value.mul(100),
                          USD_DECIMALS,
                          2,
                          true
                        )}
                        %
                      </ThemedText.Text9Bold>
                    </Row>
                    <Row>
                      <ThemedText.Text9Bold fontSize={14} alignItems={"center"}>
                        {formatAmount(feeBps, 2, 2, true)}% (
                        {formatAmount(fees, fromTokenInfo.decimals, 4, true)}{" "}
                        {fromTokenInfo.symbol}: $
                        {formatAmount(feesUsd, USD_DECIMALS, 2, true)})
                      </ThemedText.Text9Bold>
                    </Row>
                  </RowBetween>
                </Box>
              </div>
              <div className="infoItem">
                <RowBetween
                  display="flex"
                  alignItems="center"
                  className="infoTitle"
                >
                  <Row>
                    <Dots bgColor="#FFFD02" />
                    <ThemedText.Text4Bold ml="8px">
                      {fromTokenInfo.symbol} Price
                    </ThemedText.Text4Bold>
                  </Row>
                  <Row>
                    <Dots bgColor="#FFFD02" />
                    <ThemedText.Text4Bold ml="8px">
                      {toTokenInfo.symbol} Price
                    </ThemedText.Text4Bold>
                  </Row>
                </RowBetween>
                <Box display="flex" alignItems="center" className="infoVal">
                  <RowBetween>
                    <Row>
                      <ThemedText.Text9Bold fontSize={14}>
                        {fromTokenUsd} USD
                      </ThemedText.Text9Bold>
                    </Row>
                    <Row>
                      <ThemedText.Text9Bold fontSize={14}>
                        {toTokenUsd} USD
                      </ThemedText.Text9Bold>
                    </Row>
                  </RowBetween>
                </Box>
              </div>
            </Box>
          </H5RowBetween>
          {renderExistingOrderWarning()}
        </OpenBox>
      );
    }

    return (
      <OpenBox>
        <OpenCardBox>
          <div className="">
            <RowBetween className="lebse" pb={"14px"}>
              <Box className=" font-14 font600 color14">Pay</Box>
              <Row
                width={"atuo"}
                gap={"8px"}
                className={cx("font700 font-14", {
                  positive: isLong,
                  negative: !isLong,
                })}
              >
                {isLong ? "LONG" : "Short"}
                <span className=" color28">{`${formatAmount(
                  leverage,
                  4,
                  2
                )}x`}</span>
              </Row>
            </RowBetween>
            <RowBetween className="lebseimg" pb={"6px"}>
              <Row
                gap={"8px"}
                width={"atuo"}
                className="font-18 color28 font700"
              >
                <img width={30} src={fromToken?.imageUrl} alt="" />{" "}
                {fromToken.symbol}
              </Row>
              <Row
                gap={"8px"}
                width={"atuo"}
                className="font-18 color28 font700"
              >
                <img width={30} src={toToken?.imageUrl} alt="" />{" "}
                {toToken.symbol}
              </Row>
            </RowBetween>
          </div>
          <RowBetween gap={"18px"} alignItems={"center"}>
            <Row>
              <div className="openLine"></div>
            </Row>
            <img src={OpenBg} alt="" />
            <Row>
              <div className="openLine"></div>
            </Row>
          </RowBetween>
          <Box className="clsbtm" mt={"4px"}>
            <RowBetween className="toamout">
              <div className="font-20 font700 color28">
                {formatAmount(fromAmount, fromToken.decimals, 4, true)}
              </div>
              <div
                className={cx("font-20 font700 color28", {
                  positive: isLong,
                  negative: !isLong,
                })}
              >
                {formatAmount(toAmount, toToken.decimals, 4, true)}
              </div>
            </RowBetween>
            <RowBetween className="tousd">
              <div className="font-14  color14">
                {`$${formatAmount(fromUsdMin, USD_DECIMALS, 2, true)}`}
              </div>
              <div className="font-14  color14">{`$${formatAmount(
                toUsdMax,
                USD_DECIMALS,
                2,
                true
              )}`}</div>
            </RowBetween>
          </Box>
        </OpenCardBox>

        <H5RowBetween gap={"18px"} mt={"16px"}>
          <Box flex={"1"} className="stakeInfo">
            <div className="infoItem">
              <Box display="flex" alignItems="center" className="infoTitle">
                <Dots bgColor="#FFFD02" />
                <ThemedText.Text4Bold ml="8px">Profits In</ThemedText.Text4Bold>
              </Box>
              <Box display="flex" alignItems="center" className="infoVal">
                <ThemedText.Text9Bold fontSize={14}>
                  {isShort && useGetToken(shortCollateralAddress).symbol}
                  {isLong && toTokenInfo.symbol}
                </ThemedText.Text9Bold>
              </Box>
            </div>
            <div className="infoItem">
              <RowBetween
                display="flex"
                alignItems="center"
                className="infoTitle"
              >
                <Row>
                  <Dots bgColor="#FFFD02" />
                  <ThemedText.Text4Bold ml="8px">
                    Entry Price
                  </ThemedText.Text4Bold>
                </Row>
                <Row>
                  <Dots bgColor="#FFFD02" />
                  <ThemedText.Text4Bold ml="8px">
                    Liq. Price
                  </ThemedText.Text4Bold>
                </Row>
              </RowBetween>
              <Box display="flex" alignItems="center" className="infoVal">
                <RowBetween>
                  <Row>
                    {hasExistingPosition && toAmount && toAmount.gt(0) && (
                      <ThemedText.Text4 fontSize={14} mr="8px">
                        {` $${formatAmount(
                          existingPosition.averagePrice,
                          USD_DECIMALS,
                          toToken.tokenDecimals,
                          true
                        )}`}
                      </ThemedText.Text4>
                    )}

                    {hasExistingPosition && toAmount && toAmount.gt(0) && (
                      <ToIcon className="mr-8" />
                    )}

                    {nextAveragePrice ? (
                      <ThemedText.Text9Bold fontSize={14}>
                        {`$${formatAmount(
                          nextAveragePrice,
                          USD_DECIMALS,
                          toToken.tokenDecimals,
                          true
                        )}`}
                      </ThemedText.Text9Bold>
                    ) : (
                      <ThemedText.Text9Bold fontSize={14}>
                        {!nextAveragePrice && `-`}
                      </ThemedText.Text9Bold>
                    )}
                  </Row>
                  <Row>
                    {hasExistingPosition && toAmount && toAmount.gt(0) && (
                      <ThemedText.Text4 fontSize={14} mr="8px">
                        {` $${formatAmount(
                          existingLiquidationPrice,
                          USD_DECIMALS,
                          toToken.tokenDecimals,
                          true
                        )}`}
                      </ThemedText.Text4>
                    )}
                    {hasExistingPosition && toAmount && toAmount.gt(0) && (
                      <ToIcon className="mr-8" />
                    )}

                    {toAmount && displayLiquidationPrice && (
                      <ThemedText.Text9Bold fontSize={14} alignItems={"center"}>
                        {`$${formatAmount(
                          displayLiquidationPrice,
                          USD_DECIMALS,
                          toToken.tokenDecimals,
                          true
                        )}`}
                      </ThemedText.Text9Bold>
                    )}

                    {(!toAmount && displayLiquidationPrice) ||
                      (!displayLiquidationPrice && (
                        <ThemedText.Text9Bold fontSize={14}>
                          -
                        </ThemedText.Text9Bold>
                      ))}
                  </Row>
                </RowBetween>
              </Box>
            </div>
            <div className="infoItem">
              <RowBetween
                display="flex"
                alignItems="center"
                className="infoTitle"
              >
                <Row>
                  <Dots bgColor="#FFFD02" />
                  <ThemedText.Text4Bold ml="8px">Fees</ThemedText.Text4Bold>
                </Row>
                <Row>
                  <Dots bgColor="#FFFD02" />
                  <ThemedText.Text4Bold ml="8px">Spread</ThemedText.Text4Bold>
                </Row>
              </RowBetween>
              <Box display="flex" alignItems="center" className="infoVal">
                <RowBetween>
                  <Row>
                    <ThemedText.Text9Bold fontSize={14}>
                      ${formatAmount(feesUsd, USD_DECIMALS, 2, true)}
                    </ThemedText.Text9Bold>
                  </Row>
                  <Row>
                    <ThemedText.Text9Bold fontSize={14}>
                      {formatAmount(
                        spread.value.mul(100),
                        USD_DECIMALS,
                        2,
                        true
                      )}
                      %
                    </ThemedText.Text9Bold>
                  </Row>
                </RowBetween>
              </Box>
            </div>
          </Box>
          <FeeBox gap={"20px"} flex={1}>
            <Column>
              <ThemedText.Text4Bold>Borrow Fee</ThemedText.Text4Bold>
              <ThemedText.Text9Bold fontSize={14}>
                {isLong &&
                  toTokenInfo &&
                  formatAmount(toTokenInfo.fundingRate, 4, 4)}
                {isShort &&
                  shortCollateralToken &&
                  formatAmount(shortCollateralToken.fundingRate, 4, 4)}
                {((isLong && toTokenInfo && toTokenInfo.fundingRate) ||
                  (isShort &&
                    shortCollateralToken &&
                    shortCollateralToken.fundingRate)) &&
                  "% / 1h"}
              </ThemedText.Text9Bold>
            </Column>
            <Column>
              <ThemedText.Text4Bold>Execution Fee</ThemedText.Text4Bold>
              <ThemedText.Text9Bold fontSize={14}>
                {formatAmountFree(minExecutionFee, 18, 5)} {nativeTokenSymbol}
              </ThemedText.Text9Bold>
            </Column>
            <Column>
              <ThemedText.Text4Bold>Allowed Slippage</ThemedText.Text4Bold>
              <ThemedText.Text9Bold fontSize={14}>
                {formatAmount(allowedSlippage, 2, 2)}%
              </ThemedText.Text9Bold>
            </Column>
          </FeeBox>
        </H5RowBetween>
        {renderExistingOrderWarning()}
      </OpenBox>
    );
  }, [
    isSwap,
    fromAmount,
    fromToken,
    toToken,
    fromUsdMin,
    toUsdMax,
    isLong,
    toAmount,
  ]);

  const SWAP_ORDER_EXECUTION_GAS_FEE = getConstant(
    chainID,
    "SWAP_ORDER_EXECUTION_GAS_FEE"
  );
  const INCREASE_ORDER_EXECUTION_GAS_FEE = getConstant(
    chainID,
    "INCREASE_ORDER_EXECUTION_GAS_FEE"
  );
  const executionFee = isSwap
    ? SWAP_ORDER_EXECUTION_GAS_FEE
    : INCREASE_ORDER_EXECUTION_GAS_FEE;
  const renderExecutionFee = useCallback(() => {
    if (isMarketOrder) {
      return null;
    }
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mt-10"
      >
        <div className="color7">Execution Fee</div>
        <div className="">
          {formatAmount(executionFee, 18, 4)} {getNativeToken(chainID).symbol}
        </div>
      </Box>
    );
  }, [isMarketOrder, executionFee, chainID]);

  const renderAvailableLiquidity = useCallback(() => {
    let availableLiquidity;
    const riskThresholdBps = 5000;
    let isLiquidityRisk;
    const token = isSwap || isLong ? toTokenInfo : shortCollateralToken;

    if (!token || !token.poolAmount || !token.availableAmount) {
      return null;
    }

    if (isSwap) {
      const poolWithoutBuffer = token.poolAmount.sub(token.bufferAmount);
      availableLiquidity = token.availableAmount.gt(poolWithoutBuffer)
        ? poolWithoutBuffer
        : token.availableAmount;
      isLiquidityRisk = availableLiquidity
        .mul(riskThresholdBps)
        .div(BASIS_POINTS_DIVISOR)
        .lt(toAmount);
    } else {
      if (isShort) {
        availableLiquidity = token.availableAmount;

        let adjustedMaxGlobalShortSize;

        if (
          toTokenInfo.maxAvailableShort &&
          toTokenInfo.maxAvailableShort.gt(0)
        ) {
          adjustedMaxGlobalShortSize = toTokenInfo.maxAvailableShort
            .mul(expandDecimals(1, token.decimals))
            .div(expandDecimals(1, USD_DECIMALS));
        }

        if (
          adjustedMaxGlobalShortSize &&
          adjustedMaxGlobalShortSize.lt(token.availableAmount)
        ) {
          availableLiquidity = adjustedMaxGlobalShortSize;
        }

        const sizeTokens = toUsdMax
          .mul(expandDecimals(1, token.decimals))
          .div(token.minPrice);
        isLiquidityRisk = availableLiquidity
          .mul(riskThresholdBps)
          .div(BASIS_POINTS_DIVISOR)
          .lt(sizeTokens);
      } else {
        availableLiquidity = token.availableAmount;
        isLiquidityRisk = availableLiquidity
          .mul(riskThresholdBps)
          .div(BASIS_POINTS_DIVISOR)
          .lt(toAmount);
      }
    }

    if (!availableLiquidity) {
      return null;
    }

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="mt-10"
      >
        <div className="color7">Available Liquidity</div>
        <div className="">
          <Tooltips
            position="right-bottom"
            handleClassName=""
            renderContent={
              <TooltipText
                text={
                  isLiquidityRisk ? (
                    <div className="">
                      There may not be sufficient liquidity to execute your
                      order when the price conditions are met
                    </div>
                  ) : (
                    <div className="">
                      The order will only execute if the price conditions are
                      met and there is sufficient liquidity
                    </div>
                  )
                }
              />
            }
          >
            <span className="toolBottom">
              {formatAmount(
                availableLiquidity,
                token.decimals,
                token.isStable ? 0 : 2,
                true
              )}{" "}
              {token.symbol}
            </span>
          </Tooltips>
        </div>
      </Box>
    );
  }, [
    toTokenInfo,
    shortCollateralToken,
    isShort,
    isLong,
    isSwap,
    toAmount,
    toUsdMax,
  ]);

  const renderMarginSection = useCallback(() => {
    return (
      <>
        <div>
          {renderMain()}
          {renderFeeWarning()}
          {renderMinProfitWarning()}
          {/* {renderExistingOrderWarning()} */}
          {renderExistingTriggerErrors()}
          {renderExistingTriggerWarning()}
        </div>
      </>
    );
  }, [
    renderMain,
    renderMinProfitWarning,
    shortCollateralAddress,
    isShort,
    isLong,
    toTokenInfo,
    nextAveragePrice,
    toAmount,
    hasExistingPosition,
    existingPosition,
    isMarketOrder,
    triggerPriceUsd,
    showSpread,
    spread,
    displayLiquidationPrice,
    existingLiquidationPrice,
    feesUsd,
    leverage,
    renderExecutionFee,
    shortCollateralToken,
    chainID,
    renderFeeWarning,
    hasPendingProfit,
    isProfitWarningAccepted,
    renderAvailableLiquidity,
    orderOption,
    fromUsdMin,
    collateralAfterFees,
    renderExistingOrderWarning,
    renderExistingTriggerWarning,
    renderExistingTriggerErrors,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    allowedSlippage,
    isTriggerWarningAccepted,
    decreaseOrdersThatWillBeExecuted,
    minExecutionFee,
    nativeTokenSymbol,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
  ]);

  const renderSwapSection = useCallback(() => {
    return (
      <>
        <div>
          {renderMain()}
          {renderFeeWarning()}
          {renderSpreadWarning()}
        </div>
      </>
    );
  }, [
    renderMain,
    renderSpreadWarning,
    fromTokenInfo,
    toTokenInfo,
    orderOption,
    showSpread,
    spread,
    feesUsd,
    feeBps,
    renderExecutionFee,
    fromTokenUsd,
    toTokenUsd,
    triggerRatio,
    fees,
    isMarketOrder,
    minOut,
    renderFeeWarning,
    renderAvailableLiquidity,
  ]);

  return (
    <DialogBox
      open={true}
      width={isSwap ? "500px" : "700px"}
      handleClose={handleClose}
      title="Confirm"
    >
      <Box>
        <div className={cx("dialogBg w-700")}>
          <div className="dialogContent">
            {!isSwap && leverage / 10000 > 30 && (
              <div className="warnBox">
                <BgBox display="flex" alignItems="center" className="warnInfo">
                  <img src={Warn} />
                  <div className="ml-16 font700 font-14 font-weight-6 color28">
                    Attention! You are attempting to leverage more than 30x. Be
                    advised that you have an increased risk of liquidations
                    using high leverage.{" "}
                  </div>
                </BgBox>
              </div>
            )}
            {isSwap && renderSwapSection()}
            {!isSwap && renderMarginSection()}
          </div>
        </div>
        <Klines />
        <Box p={"16px"}>
          <Box display="flex">
            <Box width={isVerySmallScreen ? "112px" : "200px"}>
              <CancelBtn onClick={handleClose}>Cancel</CancelBtn>
            </Box>
            <Box ml="8px" className="flex-1">
              <ConfirmBtn
                width
                onClick={onConfirmationClick}
                disabled={!isPrimaryEnabled()}
              >
                {getPrimaryText()}
              </ConfirmBtn>
            </Box>
          </Box>
        </Box>
      </Box>
    </DialogBox>
  );
}
