import { ReactComponent as NoData } from "../../assets/images/noData.svg";
import { AutoColumn } from "../Column";
import Row from "../Row";

type NoDataPros = {
  title?: string;
  height?: number | string;
};

export default function NoDataItem({ title }: NoDataPros) {
  return (
    <AutoColumn justify="center" className="noData">
      <NoData className="" />
      <div className="mt-12 color9 font-weight-b">{title}</div>
    </AutoColumn>
  );
}

export const DefaultNoData = ({ title, height }: NoDataPros) => {
  return (
    <Row justify="center" height={height ?? 240}>
      <NoDataItem title={title} />
    </Row>
  );
};
