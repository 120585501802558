import { Trans } from "@lingui/macro";
import { Box } from "@mui/material";
import { ThemedText } from "src/theme/text";
import styled from "styled-components/macro";

const TabBox = styled(Box)`
  display: flex;
  .tabItem {
    height: 60px;
    line-height: 60px;
    position: relative;
    &:not(:first-child) {
      margin-left: 64px;
    }
  }
  .activeTabItem {
    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 50%;
      height: 4px;
      width: 20px;
      transform: translateX(-50%);
      background: #fffd02;
    }
  }
`;

type dataPros = {
  curr?: number;
  setCurr?: any;
  tabList?: any;
};

export default function Tabs({ curr, setCurr, tabList }: dataPros) {
  return (
    <TabBox className="tabs">
      {tabList?.map((tab, index) => (
        <div
          className={`tabItem pointer ${curr == index && "activeTabItem"}`}
          onClick={() => setCurr(index)}
          key={index}
        >
          {curr == index ? (
            <ThemedText.Text1Bold fontSize={16}>
              <Trans>{tab}</Trans>
            </ThemedText.Text1Bold>
          ) : (
            <ThemedText.Text4Bold fontSize={16}>
              <Trans>{tab}</Trans>
            </ThemedText.Text4Bold>
          )}
        </div>
      ))}
    </TabBox>
  );
}
