import { Box, useMediaQuery } from "@mui/material";
import styled from "styled-components/macro";
import { ThemedText } from "src/theme/text";
import { Trans } from "@lingui/macro";
import { MenuBtn } from "../Button/MenuBtn";
import Boost from "src/views/earn/Boost";

const TabBox = styled(Box)`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1280px) {
    flex-direction: row;
    align-items: space-between;
  }
`;

type dataPros = {
  curr?: number;
  setCurr?: any;
  tabList?: any;
  showBoost?: boolean;
};

const LeftTab = ({ curr, tabList, setCurr, showBoost }: dataPros) => {
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 768px)");
  return (
    <TabBox className="tabBox">
      {tabList.map((tab: any, index: number) => (
        <MenuBtn key={index}>
          <Box
            display="flex"
            alignItems="center"
            className={`tabItem pointer ${curr == index && "activeTabItem"}`}
            onClick={() => setCurr(index)}
          >
            <Box display="flex" alignItems="center">
              {tab.icon}
              <div style={{ whiteSpace: "nowrap" }}>
                {curr == index ? (
                  <ThemedText.Text1Medium
                    fontSize={isSmallScreen ? 14 : 16}
                    ml={isSmallScreen ? "6px" : "10px"}
                    className="text"
                  >
                    <Trans>{tab.title}</Trans>
                  </ThemedText.Text1Medium>
                ) : (
                  <ThemedText.Text4Medium
                    fontSize={isSmallScreen ? 14 : 16}
                    ml={isSmallScreen ? "6px" : "10px"}
                    className="text"
                  >
                    <Trans>{tab.title}</Trans>
                  </ThemedText.Text4Medium>
                )}
              </div>
            </Box>
            {tab.showBoost && (
              <Box
                ml={isVerySmallScreen ? 0 : "8px"}
                mb={isVerySmallScreen ? "8px" : 0}
              >
                <Boost />
              </Box>
            )}
          </Box>
        </MenuBtn>
      ))}
    </TabBox>
  );
};

export default LeftTab;
