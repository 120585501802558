import { AddressEnvMap, SupportedChainId } from "./chains.base";
export const SUPPORTED_CHAIN_IDS_MAP: AddressEnvMap = {
  // [SupportedChainId.ARB_TEST]: SupportedChainId.ARB_TEST,
  [SupportedChainId.BLAST_TEST]: SupportedChainId.BLAST_TEST,
};

export const SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  // SupportedChainId.ARB_TEST,
  SupportedChainId.BLAST_TEST,
  // SupportedChainId.BSCTEST,
  // SupportedChainId.BSCMAINNET,
];
