import { Trans } from "@lingui/macro";
import { Box, useMediaQuery } from "@mui/material";
import { ThemedText } from "src/theme/text";
import styled from "styled-components/macro";
import { TableBox } from "../TableBox";
import { shorten } from "src/helpers";
import moment from "moment";
import { DefaultNoData } from "src/components/NoData";

const InvitedBox = styled(TableBox)``;
type dataPros = {
  inviteList?: any;
};

export default function InvitedList({ inviteList }: dataPros) {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <InvitedBox className="invite">
      {isMobile ? (
        <div className="table-m">
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
            className="tableHeader-m"
          >
            <div className="">
              <ThemedText.Text4Bold>
                <Trans>Address</Trans>
              </ThemedText.Text4Bold>
            </div>
            <div className="text-right">
              <ThemedText.Text4Bold>
                <Trans>Time</Trans>
              </ThemedText.Text4Bold>
            </div>
          </Box>
          {inviteList.length > 0 ? (
            inviteList.map((item, index) => (
              <Box
                display="flex"
                justifyContent={"space-between"}
                className="tableItem-m"
                key={index}
              >
                <div className="" style={{ textDecoration: "underline" }}>
                  <ThemedText.Text1Medium>
                    {shorten(item.id)}
                  </ThemedText.Text1Medium>
                </div>
                <ThemedText.Text1Medium>
                  {moment(item.invitedTimestamp * 1000).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </ThemedText.Text1Medium>
              </Box>
            ))
          ) : (
            <DefaultNoData title="No items to display" />
          )}
        </div>
      ) : (
        <>
          <Box display="flex" alignItems="center" className="tableHeader">
            <div className="flex-1 td">
              <ThemedText.Text4Bold>
                <Trans>Address</Trans>
              </ThemedText.Text4Bold>
            </div>
            <div className="flex-1 td">
              <ThemedText.Text4Bold>
                <Trans>Time</Trans>
              </ThemedText.Text4Bold>
            </div>
          </Box>
          {inviteList.length > 0 ? (
            inviteList.map((item, index) => (
              <Box
                display="flex"
                alignItems="center"
                className="tableTr"
                key={index}
              >
                <div className="flex-1 td">
                  <ThemedText.Text1Medium>
                    {shorten(item.id)}
                  </ThemedText.Text1Medium>
                </div>
                <div className="flex-1 td">
                  <ThemedText.Text1Medium>
                    {moment(item.invitedTimestamp * 1000).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </ThemedText.Text1Medium>
                </div>
              </Box>
            ))
          ) : (
            <DefaultNoData title="No items to display" />
          )}
        </>
      )}
    </InvitedBox>
  );
}
