import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { BN } from "src/utils/bn";

const MaxBox = styled(Box)`
  width: 100%;
  .show_max {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    .max_item {
      padding: 2px 0;
      width: 100%;
      border: 1px solid rgba(255, 255, 255, 0.16);
      border-radius: 4px;
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
      &:hover {
        color: rgba(255, 255, 255, 0.5);
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
`;

type InputProps = {
  balance?: number;
  setInputValue?: any;
};

export const TakeProfit = ({ balance, setInputValue }: InputProps) => {
  const setAmount = (rate: number) => {
    const totalAmount = balance || 0;
    const amount = BN(totalAmount).times(rate).toFixed();
    setInputValue && setInputValue(amount);
  };

  return (
    <MaxBox className={``}>
      <div className="show_max font700 mt-10">
        <div className="max_item" onClick={() => setAmount(0.25)}>
          20%
        </div>
        <div className="max_item" onClick={() => setAmount(0.5)}>
          50%
        </div>
        <div className="max_item" onClick={() => setAmount(0.5)}>
          100%
        </div>
        <div className="max_item" onClick={() => setAmount(0.5)}>
          300%
        </div>
        <div className="max_item" onClick={() => setAmount(0.75)}>
          900%
        </div>
        <div className="max_item" onClick={() => setAmount(1)}>
          Price
        </div>
      </div>
    </MaxBox>
  );
};
