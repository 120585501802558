import styled from "styled-components";
import { Box, useMediaQuery } from "@mui/material";
import { ReactComponent as Notex } from "src/assets/images/notex.svg";
import { useEffect, useState } from "react";
import { ThemedText } from "src/theme/text";
import { Trans } from "@lingui/macro";
import { useTradeInfo } from "src/views/leaderboard/hooks";
import { formatAmount2 } from "src/helpers/Helpers";
import CountDown from "src/views/leaderboard/TradingCompetition/CountDown2";
import useCountDown from "src/hooks/useCountDown";
import moment from "moment";
import { useWeb3Context } from "src/hooks";

const MyNote = styled(Box)`
  background: #9500ff;
  color: #ffffff;
  font-size: 16px;
  min-height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 6px 12px 6px 6px;
  position: sticky;
  top: 0;
  .shows {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }
  .close {
    position: absolute;
    right: 16px;
  }
  @media screen and (max-width: 960px) {
    font-size: 14px;
    .close {
      right: 14px;
    }
  }
`;

const PrizePool = styled(Box)`
  display: flex;
  align-items: center;
  width: auto;
  gap: 12px;
  background: #5b00aa;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 14px;
  .trade {
    color: #44ffb0;
    font-size: 24px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    gap: 8px;
    .trade {
      font-size: 20px;
    }
  }
`;

const Note = () => {
  const { address, chainID } = useWeb3Context();
  const locgse = window.localStorage.getItem("MyNote");
  const [showNote, setNote] = useState(!locgse);
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 768px)");
  const [currCount, setCurrCount] = useState<any>(0);
  const [currRoundId, setCurrRoundId] = useState<any>(0);

  const tradeInfo = useTradeInfo(0);

  const roundId1 = Math.floor(Date.now() / 1000 / 86400);
  const isDisabled = Math.floor(roundId1) == Math.floor(currRoundId);
  const coundown = useCountDown(
    isDisabled
      ? moment()
          .utcOffset("+00")
          .startOf("day")
          .add(1, "d")
          .subtract(1, "s")
          .unix()
      : 0
  );

  useEffect(() => {
    const currTime = new Date().getTime() / 1000;
    const sec = Math.floor(currTime / (24 * 60 * 60));
    setCurrRoundId(sec + currCount);
  }, [currCount, address, chainID]);

  const Hinde = () => {
    window.localStorage.setItem("MyNote", "1");
    setNote(false);
  };

  return (
    <>
      {showNote && (
        <MyNote>
          <Box display="flex" alignItems="center" gap="16px" width="100%">
            <Box
              display="flex"
              flexDirection={isSmallScreen ? "column" : "row"}
              alignItems={isSmallScreen ? "flex-start" : "center"}
              width="100%"
            >
              <PrizePool className="">
                <div className="trade font-weight-900">TRADE TO EARN!</div>
                <Box display="flex" alignItems="center">
                  <ThemedText.Text9Bold fontSize={isVerySmallScreen ? 14 : 16}>
                    {formatAmount2(tradeInfo?.roundReward, 2, true)} esDYNA
                  </ThemedText.Text9Bold>
                  <ThemedText.Text9
                    fontSize={isVerySmallScreen ? 14 : 16}
                    ml="8px"
                  >
                    <Trans>Prize Pool</Trans>
                  </ThemedText.Text9>
                </Box>
              </PrizePool>
              <Box
                display="flex"
                flexDirection={isVerySmallScreen ? "column" : "row"}
                alignItems={isVerySmallScreen ? "flex-start" : "center"}
                gap={isVerySmallScreen ? "4px" : isSmallScreen ? "8px" : "16px"}
                ml={isVerySmallScreen ? 0 : isSmallScreen ? "16px" : "40px"}
                mt={isVerySmallScreen ? "4px" : isSmallScreen ? "16px" : 0}
              >
                <Box display="flex" alignItems="center">
                  <ThemedText.Text9 fontSize={15}>
                    <Trans>Total Points</Trans>
                  </ThemedText.Text9>
                  <ThemedText.Text9Bold
                    fontSize={isVerySmallScreen ? 14 : 18}
                    ml="4px"
                    className="font-weight-900"
                  >
                    {formatAmount2(tradeInfo?.totalPoints, 2, true)} Pts
                  </ThemedText.Text9Bold>
                </Box>
                {!isVerySmallScreen && (
                  <ThemedText.Text4 fontSize={16}>/</ThemedText.Text4>
                )}
                <Box display="flex" alignItems="center">
                  <ThemedText.Text9 fontSize={15}>
                    <Trans>Your Points</Trans>
                  </ThemedText.Text9>
                  <ThemedText.Text9Bold
                    fontSize={isVerySmallScreen ? 14 : 18}
                    ml="4px"
                    className="font-weight-900"
                  >
                    {formatAmount2(tradeInfo?.userPoints, 2, true)} Pts
                  </ThemedText.Text9Bold>
                </Box>
                {!isVerySmallScreen && (
                  <ThemedText.Text4 fontSize={16}>/</ThemedText.Text4>
                )}
                <Box display="flex" alignItems="center">
                  <ThemedText.Text9 fontSize={15}>
                    <Trans>Your Rewards</Trans>
                  </ThemedText.Text9>
                  <ThemedText.Text9Bold
                    fontSize={isVerySmallScreen ? 14 : 18}
                    ml="4px"
                    className="font-weight-900"
                  >
                    {formatAmount2(tradeInfo?.claimable, 2, true)} esDYNA
                  </ThemedText.Text9Bold>
                </Box>
                {!isVerySmallScreen && (
                  <ThemedText.Text4 fontSize={16}>/</ThemedText.Text4>
                )}
                <Box display="flex" alignItems="center">
                  <ThemedText.Text9 fontSize={15} mr="4px">
                    <Trans>End in</Trans>
                  </ThemedText.Text9>
                  <CountDown coundown={coundown} />
                </Box>
              </Box>
            </Box>
            <div className="close">
              {/* <div className="font-weight-b shows" onClick={Hinde}>
                  Don&apos;t show this message again
                </div> */}
              <Notex className="pointer" onClick={() => setNote(false)} />
            </div>
          </Box>
        </MyNote>
      )}
    </>
  );
};

export default Note;
