import { Trans } from "@lingui/macro";
import { Box, useMediaQuery } from "@mui/material";
import { ThemedText } from "src/theme/text";
import styled from "styled-components";
import { ReactComponent as RebatesIcon } from "src/assets/images/pid/rebates.svg";
import { ReactComponent as BoostIcon } from "src/assets/images/pid/boost.svg";
import PendingIcon from "src/assets/images/loading/pending.svg";
import { ReactComponent as EsDYNA } from "src/assets/images/esDYNA.svg";
import { ClaimBtn } from "./ClaimBtn";
import { useBasicInfo, useRebatesAmount } from "src/hooks/useQueryDID";
import { formatAmount2 } from "src/helpers/Helpers";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { useSelector } from "react-redux";
import { useUserBoostV2 } from "src/hooks/useQueryEarn";
import usePid from "src/hooks/usePid";
import { RANK_LIST_REBATES } from "src/helpers/rankConstants";

const RebatesBox = styled(Box)`
  display: flex;
  align-items: stretch;
  margin-top: 48px;
  .rebatesItem {
    min-height: 60px;
    padding: 18px 16px 16px 14px;
    border: 1px solid ${({ theme }) => theme.bg4};
    border-radius: 8px;
  }
  .rebates {
    border: 1px solid rgba(255, 253, 2, 0.2);
    border-radius: 8px;
    padding: 28px 0 14px 24px;
    position: relative;
    &:hover {
      border: 1px solid #fffd02;
      .claim {
        button {
          background: #fffd02;
          color: #000000;
        }
      }
    }
    .claim {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  @media only screen and (max-width: 1280px) {
    margin-top: 32px;
    flex-direction: column;
  }
`;

export default function RebatesInfo() {
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 768px)");
  const baseInfo = useBasicInfo();
  const boostLevel = useUserBoostV2();
  const rebatesAmount = useRebatesAmount();
  const { doClaimRebates } = usePid();
  const pendingTransactions = useSelector((state: any) => {
    return state.pendingTransactions;
  });
  const handlerClaim = async () => {
    await doClaimRebates();
  };

  return (
    <>
      <RebatesBox className="rebates-view">
        <Box
          display="flex"
          alignItems="stretch"
          width={isSmallScreen ? "100%" : "360px"}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="20px"
            justifyContent="space-between"
            width="100%"
            className="rebatesItem"
          >
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <BoostIcon />
              <ThemedText.Text4Bold fontSize={14} ml="12px">
                <Trans>Boost MULT.</Trans>
              </ThemedText.Text4Bold>
            </Box>
            <Box display="flex" gap="6px" alignItems="flex-end">
              <ThemedText.Text9Bold fontSize={48}>
                {formatAmount2(boostLevel, 2, true)} x
              </ThemedText.Text9Bold>
              {/* <ThemedText.Text5 fontSize={12} mb="10px">
                100 Points to Next Level
              </ThemedText.Text5> */}
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="stretch"
          width={isSmallScreen ? "100%" : "360px"}
          ml={isSmallScreen ? 0 : "12px"}
          mt={isSmallScreen ? "16px" : 0}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="20px"
            justifyContent="space-between"
            width="100%"
            className="rebatesItem"
          >
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <RebatesIcon />
              <ThemedText.Text1Bold fontSize={14} ml="12px">
                <Trans>Rebates</Trans>
              </ThemedText.Text1Bold>
            </Box>
            <ThemedText.Text9Bold fontSize={48}>
              {RANK_LIST_REBATES[baseInfo?.rank]}%
            </ThemedText.Text9Bold>
          </Box>
        </Box>
        <Box
          className="flex-1 rebates pointer"
          ml={isSmallScreen ? 0 : "16px"}
          mt={isSmallScreen ? "16px" : 0}
          pb={isSmallScreen ? "36px" : "auto"}
        >
          <ThemedText.Text4Bold>
            <Trans>Rebates</Trans>
          </ThemedText.Text4Bold>
          <Box display="flex" alignItems="center" className="" mt="20px">
            <EsDYNA width={32} height={32} />
            <ThemedText.Text9Bold fontSize={48} ml="12px">
              {formatAmount2(rebatesAmount, 2, true)}
            </ThemedText.Text9Bold>
          </Box>
          <div className="claim" onClick={handlerClaim}>
            <ClaimBtn
              disabled={isPendingTxn(pendingTransactions, "claim_rebates")}
            >
              {isPendingTxn(pendingTransactions, "claim_rebates") ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <div className="">Pending</div>
                  <img src={PendingIcon} height={20} className="ml-12" />
                </Box>
              ) : (
                txnButtonText(pendingTransactions, "claim_rebates", "Claim")
              )}
            </ClaimBtn>
          </div>
        </Box>
      </RebatesBox>
    </>
  );
}
