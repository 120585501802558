import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const ClaimBtn = styled(Button)<{
  borderRadius?: string;
  height?: string;
}>`
  height: ${({ height }) => (height ? height : "40px")};
  line-height: ${({ height }) => (height ? height : "40px")};
  max-height: ${({ height }) => (height ? height : "40px")};
  width: 90px;
  color: #fffd02;
  font-size: 14px;
  font-family: "Gilroy-Bold";
  text-transform: uppercase;
  background: rgba(255, 253, 2, 0.2);
  border: none;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "8px 0"};
  &:hover {
    background: #fffd02;
    color: #000000;
  }
  &:disabled {
    &:hover {
      color: #000000 !important;
    }
  }
`;
