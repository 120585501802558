import styled from "styled-components/macro";
import { ReactComponent as UP } from "src/assets/images/earn/up2.svg";
import { ThemedText } from "src/theme/text";
import { Trans } from "@lingui/macro";
import { useUserBoostV2 } from "src/hooks/useQueryEarn";
import { formatAmount2 } from "src/helpers/Helpers";

const BoostBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  background: #ff9a02;
  height: 16px;
  padding: 0 8px;
  white-space: nowrap;
  @media only screen and (max-width: 1500px) {
    padding: 0 4px;
  }
  @media only screen and (max-width: 1280px) {
    padding: 0 8px;
  }
`;

const StyledUpIcon = styled(UP)`
  path {
    fill: #0a0a0a !important;
  }
`;

export default function Boost() {
  const boostLevel = useUserBoostV2();
  return (
    <BoostBox>
      <ThemedText.Text4Bold fontSize={12} style={{ color: "#0A0A0A" }}>
        {formatAmount2(boostLevel, 2, true)} x
      </ThemedText.Text4Bold>
      <ThemedText.Text4Bold fontSize={12} style={{ color: "#0A0A0A" }}>
        <Trans>Boost</Trans>
      </ThemedText.Text4Bold>
      <StyledUpIcon />
    </BoostBox>
  );
}
