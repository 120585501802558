import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const SwapBtn = styled(Button)({
  minHeight: "50px",
  lineHeight: "1.5",
  maxHeight: "100px",
  width: "100%",
  color: "#FFFFFF",
  fontSize: 18,
  fontFamily: "Gilroy-Bold",
  textTransform: "uppercase",
  background: "#FFFD02",
  border: "none",
  borderRadius: "8px",
  "&:hover": {
    background: "#FCA149",
    color: "#ffffff",
  },
});
