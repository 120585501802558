import { useSingleCallResult } from "src/lib/hooks/multicall";
import {
  useRedeemTreasuryContract,
  useARSEContract,
  useARSEStakingContract,
} from "./useContract";
import { useWeb3Context } from "./web3Context";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
import { useMemo } from "react";
import { BN, toFromBN, toWei } from "src/utils/bn";
import { useAccountTokenBalance } from "./useTokenBalance";
import {
  useDYNA_USDB_LiquidityInfo,
  useDlpTotalSupply,
  useDlpTotalValueUSD,
} from "./useQueryEarn";
import { addresses } from "../configs/constants";
import { getUpToken } from "src/configs/Tokens";
import { useGetDynaPrice } from "./get_price_dyna";

export const useBalance_ARSE = () => {
  const { address: account } = useWeb3Context();
  const treContract = useARSEContract();
  const { result } = useSingleCallResult(treContract, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useStaked_ARSE = () => {
  const { address: account } = useWeb3Context();
  const stakingContract = useARSEStakingContract();
  const { result } = useSingleCallResult(stakingContract, "userDepositToken", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useTotalLocked_ARSE = () => {
  const stakingContract = useARSEStakingContract();
  const { result } = useSingleCallResult(stakingContract, "totalDepositToken");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useEstimatedSupply_ARSE = () => {
  const redeemTreasuryContract = useRedeemTreasuryContract();
  const { result } = useSingleCallResult(
    redeemTreasuryContract,
    "tTokenCirculation"
  );
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useRewards = () => {
  const { address: account } = useWeb3Context();
  const stakingContract = useARSEStakingContract();
  const { result } = useSingleCallResult(stakingContract, "claimable", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[1][0];
    return toFromBN(data);
  }, [result]);
};

export const useRedeemTime = () => {
  const redeemTreasuryContract = useRedeemTreasuryContract();
  const { result } = useSingleCallResult(
    redeemTreasuryContract,
    "nextOpenRedeemTime"
  );
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return data;
  }, [result]);
};

export const useReceive = () => {
  const redeemTreasuryContract = useRedeemTreasuryContract();
  const { result, error } = useSingleCallResult(
    redeemTreasuryContract,
    "estimatedReward",
    [toWei("1").toString()]
  );
  // redeemTreasuryContract
  //   ?.estimatedReward(toWei("1").toString())
  //   .catch((e) => console.log("error", e));
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    // console.log("estimatedReward", result, data);
    return {
      dlpReward: toFromBN(data[0]),
      dynaLpReward: toFromBN(data[0]),
    };
  }, [result]);
};

const useTreasuryDlpValueUSD = () => {
  const { chainID } = useWeb3Context();
  const TreasuryDlpBal = useAccountTokenBalance(
    addresses[chainID].PLP_β,
    addresses[chainID].Treasury
  );
  const dlpTotalSupply = useDlpTotalSupply();
  const dlpTotalUsd = useDlpTotalValueUSD();
  return useMemo(() => {
    if (!dlpTotalUsd || !dlpTotalSupply || !TreasuryDlpBal) return;
    return BN(TreasuryDlpBal.toString())
      .dividedBy(dlpTotalSupply.toString())
      .multipliedBy(dlpTotalUsd.toString())
      .toString();
  }, [TreasuryDlpBal, dlpTotalSupply, dlpTotalUsd]);
};

export const useTreasuryValueUSD = () => {
  const { chainID } = useWeb3Context();
  const TreasuryLpBal = useAccountTokenBalance(
    addresses[chainID].DYNA_USDB_LP,
    addresses[chainID].Treasury
  );
  const lpInfo = useDYNA_USDB_LiquidityInfo();
  const treasuryDlpValueUSD = useTreasuryDlpValueUSD();
  return useMemo(() => {
    if (!TreasuryLpBal || !lpInfo || !treasuryDlpValueUSD) return;
    return BN(TreasuryLpBal.toString())
      .dividedBy(lpInfo.lpTotalSupply)
      .multipliedBy(lpInfo.liqudityUSD)
      .plus(treasuryDlpValueUSD);
  }, [TreasuryLpBal, treasuryDlpValueUSD, lpInfo]);
};

export const useTreasuryTokensList = () => {
  const { chainID } = useWeb3Context();
  const dynaAddr = addresses[chainID]?.DYNA;
  const usdcAddr = addresses[chainID]?.USDB;
  const usdcToken = getUpToken(chainID, usdcAddr);
  const dynaToken = getUpToken(chainID, dynaAddr);
  const dynaPrice = useGetDynaPrice();
  const TreasuryLpBal = useAccountTokenBalance(
    addresses[chainID].DYNA_USDB_LP,
    addresses[chainID].Treasury
  );
  const lpInfo = useDYNA_USDB_LiquidityInfo();
  const treasuryDlpValueUSD = useTreasuryDlpValueUSD();
  return useMemo(() => {
    if (!lpInfo || !treasuryDlpValueUSD || !TreasuryLpBal) return;
    return [
      {
        ...usdcToken,
        balance: BN(treasuryDlpValueUSD).plus(
          BN(TreasuryLpBal.toString())
            .dividedBy(lpInfo.lpTotalSupply)
            .multipliedBy(lpInfo.uAmount)
        ),
        price: BN(1),
      },
      {
        ...dynaToken,
        balance: BN(TreasuryLpBal.toString())
          .dividedBy(lpInfo.lpTotalSupply)
          .multipliedBy(lpInfo.dynaAmount),
        price: dynaPrice,
      },
    ];
  }, [
    TreasuryLpBal,
    dynaPrice,
    dynaToken,
    lpInfo,
    treasuryDlpValueUSD,
    usdcToken,
  ]);
};

export const useARSEPriceUSD = () => {
  const asreContract = useARSEContract();
  const { result: totalSupply } = useSingleCallResult(
    asreContract,
    "totalSupply",
    []
  );
  const treasuryValueUSD = useTreasuryValueUSD();
  return useMemo(() => {
    if (!totalSupply || !treasuryValueUSD) return;
    if (totalSupply[0].eq(0)) return;
    return BN(treasuryValueUSD.toString())
      .div(toFromBN(totalSupply[0]))
      .toString();
  }, [totalSupply, treasuryValueUSD]);
};

// 每秒奖励u,年化收益  / （质押总量asre* asrePrice ）
export const useASREStakingAPR = () => {
  const { chainID } = useWeb3Context();
  const stakingContrct = useARSEStakingContract();
  const { result: rewardInfo } = useSingleCallResult(
    stakingContrct,
    "aprRecord",
    [addresses[chainID].USDB]
  );
  const arsePrice = useARSEPriceUSD();
  return useMemo(() => {
    if (!rewardInfo || !arsePrice) return;
    const yearRewardAmount = toFromBN(rewardInfo[0], 6).multipliedBy(365);
    return BN(yearRewardAmount.toString())
      .div(toFromBN(rewardInfo[1].toString()))
      .div(arsePrice);
  }, [arsePrice, rewardInfo]);
};
