import { useWeb3Context } from "./web3Context";
import { useCallback, useState } from "react";

export default function useAddTokenToMetamask(): {
  addToken: (
    address: string,
    symbol: string,
    decimals: number,
    url: string
  ) => void;
  success: boolean | undefined;
} {
  const { provider, chainID } = useWeb3Context();

  const [success, setSuccess] = useState<boolean | undefined>();

  const addToken = useCallback(
    (address: string, symbol: string, decimals: number, url: string) => {
      if (provider && address && window.ethereum) {
        window.ethereum
          .request({
            method: "wallet_watchAsset",
            params: {
              type: "ERC20",
              options: {
                address,
                symbol,
                decimals,
                image: url,
              },
            },
          })
          .then((success) => {
            setSuccess(success);
          })
          .catch(() => {
            setSuccess(false);
          });
      } else {
        setSuccess(false);
      }
    },
    [provider]
  );

  return { addToken, success };
}
