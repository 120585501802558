import { SupportedChainId } from "src/constants/chainENV/chains.base";

export const DLP_STATS_API_URL = "https://api.dyna.finance/api";

const BACKEND_URLS = {
  default: "https://api.dyna.finance",
  [SupportedChainId.BLAST_TEST]: "https://api.dyna.finance",
  [SupportedChainId.ARB_TEST]: "https://api.dyna.finance",
};

export function getServerBaseUrl(chainId: number) {
  if (!chainId) {
    throw new Error("chainId is not provided");
  }

  if (document.location.hostname.includes("deploy-preview")) {
    const fromLocalStorage = localStorage.getItem("SERVER_BASE_URL");
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
  }

  return BACKEND_URLS[chainId] || BACKEND_URLS.default;
}

export function getServerUrl(chainId: number, path: string) {
  return `${getServerBaseUrl(chainId)}${path}`;
}
