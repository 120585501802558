import { DLP_LIST } from "src/constants/tokens";
import {
  useMultipleContractSingleData,
  useSingleCallResult,
} from "src/lib/hooks/multicall";
import { useWeb3Context } from "./web3Context";
import { Interface } from "@ethersproject/abi";
import { abi as DlpABI } from "../abis/DLP.json";
import { abi as DlpManagerAbi } from "../abis/DlpManager.json";
import {
  WHITE_GNS_TOKEN_ADDRESS,
  getNativeToken,
  getTokens,
} from "src/configs/Tokens";
import { useGetDynaPrice } from "./get_price_dyna";
import { useEffect, useMemo, useState } from "react";
import { BN, toFromBN } from "src/utils/bn";
import { useDYNAContract } from "./useContract";
import { useMultipleContractMultipleData } from "src/lib/hooks/useMultipleContractMultipleData";
import { compareAddress } from "src/utils/address";
import axios from "axios";
import { abi as StakingPoolAbi } from "src/abis/StakingPool.json";
import { AddressZero } from "src/constants/address";
import { addresses as ADDRESS } from "src/configs/constants";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
import FEEROUTERABI from "src/abis/FeeRouter.json";
const DLP_INTERFACE = new Interface(DlpABI);
const FEEROUTER_INTERFACE = new Interface(FEEROUTERABI.abi);
const DLP_MANAGER_INTERFACE = new Interface(DlpManagerAbi);
const STAKE_POOL = new Interface(StakingPoolAbi);
const getFeeAmount = async () => {
  const dalyFeeUrl_bsc = "";
  const baseUrl = dalyFeeUrl_bsc;
  return axios.get(baseUrl);
};

export const useBalance_DYNA_BNB_LP = () => {
  const { chainID } = useWeb3Context();
  const DYNA_BNB_LP_addr = ADDRESS[chainID].DYNA_BNB_LP;
  const DYNAContract = useDYNAContract();
  const { result } = useSingleCallResult(DYNAContract, "balanceOf", [
    DYNA_BNB_LP_addr,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

export const useBalance_DYNA_esDYNA_LP = () => {
  const { chainID } = useWeb3Context();
  const DYNA_esDYNA_LP_addr = ADDRESS[chainID].DYNA_esDYNA_LP;
  const DYNAContract = useDYNAContract();
  const { result } = useSingleCallResult(DYNAContract, "balanceOf", [
    DYNA_esDYNA_LP_addr,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

export const useBalance_DYNA_staking = () => {
  const { chainID } = useWeb3Context();
  const DYNAStaking_addr = ADDRESS[chainID].DYNAStaking;
  const DYNAContract = useDYNAContract();
  const { result } = useSingleCallResult(DYNAContract, "balanceOf", [
    DYNAStaking_addr,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

export const useDataList = () => {
  const tokensArr = useQueryListData();
  const DYNAPrice = useGetDynaPrice();
  // const balance_DYNA_BNB_LP = useBalance_DYNA_BNB_LP();
  // const balance_DYNA_esDYNA_LP = useBalance_DYNA_esDYNA_LP();
  const balance_DYNA_BNB_LP = BN(0);
  const balance_DYNA_esDYNA_LP = BN(0);
  const balance_DYNA_staking = BN(0); //useBalance_DYNA_staking();

  // const collateralList = useQeuryUSDBCollateral();

  return useMemo(() => {
    if (
      !tokensArr ||
      // !totalStaked_DYNA ||
      // !totalSupply_veDYNA ||
      // !avgLockTime_DYNA ||
      // !avgLockTime_esDYNA ||
      // !circLocked_esDYNA ||
      // !circLocked_DYNA ||
      // !circulatingSupply ||
      !DYNAPrice
    )
      return;
    return {
      ...tokensArr,
      USDBPrice: 1,
      DYNAPrice: DYNAPrice,

      collateralList: [],
      balance_DYNA_DYNA_BUSD: 0,
      balance_DYNA_BNB_LP: balance_DYNA_BNB_LP
        ? balance_DYNA_BNB_LP.toNumber()
        : 0,
      balance_DYNA_esDYNA_LP: balance_DYNA_esDYNA_LP
        ? balance_DYNA_esDYNA_LP.toNumber()
        : 0,
      balance_DYNA_staking: balance_DYNA_staking
        ? balance_DYNA_staking.toNumber()
        : 0,
      rank: 1,
    };
  }, [
    tokensArr,
    DYNAPrice,
    balance_DYNA_BNB_LP,
    balance_DYNA_esDYNA_LP,
    balance_DYNA_staking,
  ]);
};

export const useQueryListData = () => {
  const { chainID, address } = useWeb3Context();
  const dlpList = DLP_LIST[chainID];
  const tokens = getTokens(chainID);

  const dlp_addr = dlpList?.map((dlp) => dlp.Dlp_address);
  const dlp_manager_addr = dlpList?.map((dlp) => dlp.DlpManager_address);
  const feeRouter_addr = dlpList?.map((x) => x.fee_router).filter((x) => !!x);
  const NATIVE_TOKEN = getNativeToken(chainID);
  const DYNAPrice = useGetDynaPrice();

  const poolInfos = useMultipleContractSingleData(
    dlp_manager_addr,
    DLP_MANAGER_INTERFACE,
    "getPoolInfo"
  );
  const tokenLists = useMultipleContractSingleData(
    dlp_manager_addr,
    DLP_MANAGER_INTERFACE,
    "getPoolTokenList"
  );
  const dlpBalanceAll = useMultipleContractSingleData(
    dlp_addr,
    DLP_INTERFACE,
    "balanceOf",
    [address ? address : PLACEHOLDER_ACCOUNT]
  );

  const { managerAddr, tokenAddr } = useMemo(() => {
    const managerAddr: string[] = [];
    const tokenAddr: any = [];
    dlp_manager_addr.map((manager, index) => {
      const { result } = tokenLists[index];
      if (!result) return;
      const data = result[0];
      data.map((x: any) => {
        managerAddr.push(manager);
        tokenAddr.push([[x]]);
      });
    });
    return {
      managerAddr,
      tokenAddr,
    };
  }, [dlp_manager_addr, tokenLists]);
  const getPoolTokenInfos = useMultipleContractMultipleData(
    !!managerAddr.length ? managerAddr : [undefined],
    DLP_MANAGER_INTERFACE,
    "getPoolTokenInfo",
    !!tokenAddr.length ? tokenAddr : undefined
  );

  const chainInfoArr: any = useMemo(() => {
    return managerAddr?.reduce((pre: any, curr, index) => {
      if (!getPoolTokenInfos[index][0]) return;
      const { result } = getPoolTokenInfos[index][0];
      if (!result) return;
      const tokenInfo = result[0];
      const addr = tokenAddr[index][0][0];
      const currTokenArr = tokens.filter((token: any) =>
        compareAddress(addr, token.address)
      );
      const currToken = currTokenArr[0];
      const tokenWeight = toFromBN(tokenInfo[0], 6).toNumber();
      const utilization = toFromBN(tokenInfo[1], 6).toNumber();
      const price = toFromBN(tokenInfo[3], 30).toNumber();
      let poolNum = BN(0);
      let poolAmount = 0;
      if (tokenInfo[2].gt(0)) {
        poolNum = toFromBN(tokenInfo[2], currToken.decimals);
        poolAmount = poolNum.times(price).toNumber();
      } else {
        poolAmount = 0;
      }
      const data = {
        tokenWeight,
        price,
        utilization,
        poolNum: poolNum.toNumber(),
        poolAmount,
        ...currToken,
      };
      const cur = curr.toLocaleLowerCase();
      if (pre[cur]) {
        pre[cur].totalAmount += poolAmount;
        pre[cur].chainInfoArr.push(data);
      } else {
        pre[cur] = { chainInfoArr: [data], totalAmount: poolAmount };
      }
      return pre;
    }, {});
  }, [getPoolTokenInfos, managerAddr, tokenAddr, tokens]);
  const poolData = useMemo(() => {
    return dlpList.reduce((pre: any, curr, index) => {
      if (!poolInfos[index]) return;
      const {
        result: poolInfo,
        loading: poolInfoLoading,
        valid: poolInfoValid,
      } = poolInfos[index];
      if (!tokenLists[index]) return;

      const {
        result: tokenList,
        loading: tokenListLoading,
        valid: tokenListValid,
      } = tokenLists[index];

      if (poolInfoLoading || tokenListLoading) return;

      if (!poolInfoValid || !tokenListValid) return;
      if (!poolInfo || !tokenList) return;
      const pool = poolInfo[0];
      const totalSupply = toFromBN(pool[2]).toFixed();
      const marketCap = toFromBN(pool[0], 30).toFixed();
      // 测试环境 没有价格默认显示为1
      let LP_Price: any = 1;
      if (pool[0].gt(0)) {
        LP_Price = BN(pool[0].div(pool[2])?.toString())
          .div(BN(10).pow(12))
          .toFixed();
      }
      const usdxSupply = pool[3];
      const token = tokenList[0];
      const cur = curr.DlpManager_address.toLocaleLowerCase();
      pre[cur] = {
        totalSupply,
        marketCap,
        price: LP_Price,
        usdxSupply,
        tokenList: token,
      };
      return pre;
    }, {});
  }, [dlpList, poolInfos, tokenLists]);

  const dlpBalanceAndSake = useMemo(() => {
    return dlp_addr.reduce<{
      [key: string]: {
        dlpBalance: string;
        dlpBalanceStaked: string;
      };
    }>((pre: any, curr, index) => {
      const dlp = curr?.toLocaleLowerCase();
      const { result } = dlpBalanceAll[index];
      if (!result) return;
      pre[dlp] = {
        dlpBalance: toFromBN(result[0]).toFixed(),
        dlpBalanceStaked: toFromBN(0).toFixed(),
      };
      return pre;
    }, {});
  }, [dlpBalanceAll, dlp_addr]);
  const AllPoolsInfo = useMemo(() => {
    return dlp_addr.reduce<{
      [key: string]: {
        totalStaked: string;
        stakingPool: string;
        totalShareStaked: string;
      };
    }>((pre: any, curr, indexs: number) => {
      const cur = curr.toLocaleLowerCase();
      pre[cur] = {
        totalStaked: BN(0),
        totalShareStaked: BN(0),
        stakingPool: BN(0),
      };
      return pre;
    }, {});
  }, [dlp_addr]);
  const ALLData = useMemo(() => {
    if (
      !chainInfoArr ||
      !poolData ||
      !AllPoolsInfo ||
      !DYNAPrice ||
      !dlpBalanceAndSake
      // !claimableUSDBList ||
      // !claimablesDYNAList ||
      // !dlpReword
    )
      return;
    return dlpList.reduce(
      (pre: any, curr) => {
        const dlpManager = curr.DlpManager_address.toLocaleLowerCase();
        const dlp = curr.Dlp_address.toLocaleLowerCase();
        const infoArr = chainInfoArr[dlpManager];
        const poolDatas = poolData[dlpManager];
        const { totalStaked, stakingPool, totalShareStaked } =
          AllPoolsInfo[dlp] ?? {};
        const fees = 0;
        const { dlpBalance, dlpBalanceStaked } = dlpBalanceAndSake[dlp];
        // const { claimableUSDB, claimablesDYNA } = dlpReword[dlp];
        const queryFees = 33;
        const tokenIfo = infoArr.chainInfoArr.map((item: any) => {
          const poolAmount = item.poolAmount;
          const poolsum = infoArr.totalAmount;
          const tokenAddr = item.address.toLocaleLowerCase();
          if (item.isWrapped) {
            const zero = AddressZero.toLocaleLowerCase();
            if (!pre.tokensMap[zero]) {
              pre.allToken.push({
                dlp_type: curr.name,
                ...NATIVE_TOKEN,
              });
              pre.tokensMap[zero] = {
                dlp_type: curr.name,
                ...NATIVE_TOKEN,
              };
            }
          }
          if (curr.isGNS) {
            const gnsTokens = WHITE_GNS_TOKEN_ADDRESS[chainID];
            gnsTokens?.map((xitem: any) => {
              if (!xitem.address) return;
              const GNStokenAddr = xitem.address.toLocaleLowerCase();
              if (!pre.tokensMap[GNStokenAddr]) {
                pre.allToken.push({
                  dlp_type: curr.name,
                  ...xitem,
                  isGNS: true,
                });
                pre.tokensMap[GNStokenAddr] = {
                  dlp_type: curr.name,
                  ...xitem,
                  isGNS: true,
                };
              }
            });
          }

          if (!pre.tokensMap[tokenAddr]) {
            pre.tokensMap[tokenAddr] = {
              dlp_type: curr.name,
              ...item,
            };
            pre.allToken.push({
              dlp_type: curr.name,
              ...item,
            });
          }

          const tokenWeight2 = BN(poolAmount).div(poolsum).toNumber();
          return {
            ...item,
            tokenWeight2,
          };
        });

        const apr_DYNA_dlp = BN(stakingPool)
          .times(3600)
          .times(24)
          .times(365)
          .times(DYNAPrice)
          .div(BN(totalShareStaked).times(poolDatas.price));

        let apr_USDB_dlp = 0;

        apr_USDB_dlp = (fees * 365) / (poolDatas.totalSupply * poolDatas.price);
        const apr = apr_DYNA_dlp.plus(apr_USDB_dlp).toNumber();
        const data = {
          ...curr,
          ...poolDatas,
          chainInfoArr: tokenIfo,
          address: curr.Dlp_address,
          claimableUSDB: "0",
          claimablesDYNA: "0",
          totalStaked_dlp: totalStaked,
          apr_DYNA_dlp: apr_DYNA_dlp.toFixed(),
          apr_USDB_dlp: apr_USDB_dlp,
          apr,
          dlpBalance,
          dlpBalanceStaked,
        };
        pre.tokensArr.push(data);
        pre.DLPMAP[curr.name] = data;
        return pre;
      },
      { tokensMap: {}, allToken: [], tokensArr: [], DLPMAP: {} }
    );
  }, [
    AllPoolsInfo,
    NATIVE_TOKEN,
    chainID,
    chainInfoArr,
    DYNAPrice,
    dlpBalanceAndSake,
    dlpList,
    poolData,
  ]);
  return ALLData;
};

export const usePromise = () => {
  const [state, setState] = useState<undefined | any>();

  useEffect(() => {
    getFeeAmount().then((res) => {
      setState(res.data || {});
    });
  }, []);

  return {
    data: state,
  };
};
