import styled from "styled-components";
import DialogBox from "src/components/DialogBox";
import { Box } from "@mui/material";
import { ThemedText } from "src/theme/text";
import { CancelBtn } from "src/components/Button/CancelBtn";
import { useEffect, useState } from "react";
import Dots from "src/components/Dots";
import { Trans } from "@lingui/macro";
import { useSelector } from "react-redux";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import PendingIcon from "src/assets/images/loading/pending.svg";
import usePid from "src/hooks/usePid";
import { useDefaultRefCode, useRefCodeOwner } from "src/hooks/useQueryDID";
import { RANK_LIST } from "src/helpers/rankConstants";
import { ConfirmBtn } from "src/components/Button/ConfirmBtn";
import { getCookie } from "src/utils/cookie";
import { useWeb3Context } from "src/hooks";

const MintDiaolgBox = styled(Box)`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
  .inputAmount {
    padding: 16px 16px 24px 16px;
    background: ${({ theme }) => theme.bg12};
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    input {
      padding: 0;
    }
  }
`;

type dialogPros = {
  open: boolean;
  handleClose?: any;
};
export default function MintDiaolg({ open, handleClose }: dialogPros) {
  const [name, setName] = useState<any>("");
  const { chainID } = useWeb3Context();
  const [referral, setReferral] = useState<any>(getCookie("refCode"));
  const onInputValueChange = (e) => {
    setName(e.target.value);
  };
  const onInputValueChange2 = (e) => {
    setReferral(e.target.value);
  };
  const pendingTransactions = useSelector((state: any) => {
    return state?.pendingTransactions;
  });
  const [isEnter, setIsEnter] = useState(true);

  const defaultRefCode = useDefaultRefCode();
  // console.log("defaultRefCode", defaultRefCode);
  const { mintWithName } = usePid();
  useEffect(() => {
    if (name.length < 1) {
      setIsEnter(true);
    } else {
      setIsEnter(false);
    }
  }, [name]);

  const refCodeOwner = useRefCodeOwner(referral);
  // console.log(
  //   "refCodeOwner",
  //   refCodeOwner,
  //   refCodeOwner?.rank,
  //   refCodeOwner?.nickName
  // );
  const refCode_arb = "DYNA23ds";
  const refCode_mantle = "mtDYNA23ds";
  const refCode_zk = "zk101000";
  const refCode = defaultRefCode;
  const onClickMint = async () => {
    if (name) {
      await mintWithName(referral ? referral : refCode, name);
      handleClose();
    }
  };
  return (
    <DialogBox
      title="Mint D.ARMY"
      width="480px"
      open={open}
      handleClose={handleClose}
    >
      <>
        <MintDiaolgBox>
          <div className="inputAmount">
            <ThemedText.Text4Bold>Name</ThemedText.Text4Bold>
            <Box mt="22px" className="">
              <input
                type="text"
                min="0"
                placeholder="Name"
                className="amount-input"
                value={name}
                onChange={onInputValueChange}
              />
            </Box>
          </div>
          <Box mt="16px" className="inputAmount">
            <ThemedText.Text4Bold>Referral Code</ThemedText.Text4Bold>
            <Box mt="22px" className="">
              <input
                type="text"
                min="0"
                placeholder="Referral Code"
                className="amount-input"
                value={referral}
                onChange={onInputValueChange2}
              />
            </Box>
          </Box>
          <Box mt="16px" className="infoBox">
            <Box display="grid" alignItems="center" className="infoTitle">
              <Box display="flex" alignItems="center" className="" pl="16px">
                <Dots bgColor="#FFFD02" />
                <ThemedText.Text4Bold ml="8px">
                  <Trans>Invited By</Trans>
                </ThemedText.Text4Bold>
              </Box>
              <Box display="flex" alignItems="center" className="" pl="16px">
                <Dots bgColor="#FFFD02" />
                <ThemedText.Text4Bold ml="8px">
                  <Trans>Inviter‘s Level</Trans>
                </ThemedText.Text4Bold>
              </Box>
            </Box>
            <Box display="grid" alignItems="center" className="infoVal">
              <Box display="flex" alignItems="center" className="" pl="16px">
                <ThemedText.Text9Bold fontSize={14}>
                  {refCodeOwner?.nickName ? refCodeOwner?.nickName : "--"}
                </ThemedText.Text9Bold>
              </Box>
              <Box display="flex" alignItems="center" className="" pl="16px">
                <ThemedText.Text9Bold fontSize={14}>
                  {refCodeOwner?.rank ? RANK_LIST[refCodeOwner?.rank] : "--"}
                </ThemedText.Text9Bold>
              </Box>
            </Box>
          </Box>
        </MintDiaolgBox>
        <Box display="flex" p="16px">
          <Box className="flex-1">
            <CancelBtn onClick={handleClose}>Cancel</CancelBtn>
          </Box>
          <Box ml="8px" className="flex-1">
            <ConfirmBtn
              onClick={onClickMint}
              disabled={
                isEnter ? true : isPendingTxn(pendingTransactions, "mint")
              }
            >
              {isPendingTxn(pendingTransactions, "mint") ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <div className="">Pending</div>
                  <img src={PendingIcon} height={20} className="ml-12" />
                </Box>
              ) : isEnter ? (
                "Enter a name"
              ) : (
                txnButtonText(pendingTransactions, "mint", "Mint")
              )}
            </ConfirmBtn>
          </Box>
        </Box>
      </>
    </DialogBox>
  );
}
