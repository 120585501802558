import { Box, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Tooltip2 from "src/components/Tooltip/Tooltip";

export const HtmlTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ width, padding }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "14px",
    color: "rgba(238, 242, 249, 0.5)",
    width: width ? width : "380px",
    background: "#1A191E",
    border: "1px solid rgba(255, 255, 255, 0.08)",
    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.4)",
    borderRadius: "4px",
    padding: padding ? padding : "8px 16px",
    "&.MuiTooltip-tooltip": {
      maxWidth: "100%",
    },

    ".tooltipsBorder": {
      padding: "16px 12px",
      borderRadius: "4px",
      border: "1px dashed rgba(255, 255, 255, 0.16)",
      background: "#1a191e",
      boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.4)",
    },
  },
}));

export default function Tooltips({
  renderContent,
  children,
  className = "",
  position = "bottom",
  width,
  padding,
}: any) {
  const isVerySmallScreen = useMediaQuery("(max-width: 768px)");
  return (
    <>
      {isVerySmallScreen ? (
        <Tooltip2
          className={className}
          handle={children}
          position={position}
          renderContent={renderContent}
        ></Tooltip2>
      ) : (
        <HtmlTooltip
          className={className}
          title={renderContent}
          width={width}
          padding={padding}
        >
          <Box width={"fit-content"} className={className}>
            {children}
          </Box>
        </HtmlTooltip>
      )}
    </>
  );
}
