import { Box } from "@mui/material";
import { ThemedText } from "src/theme/text";

type dataPros = {
  label?: string;
  val?: number | string;
  m?: string;
  isRegularText?: boolean;
};
type dataPros2 = {
  text?: any;
};

function TooltipItem({ label, val, m, isRegularText }: dataPros) {
  return (
    <>
      {isRegularText ? (
        <Box
          display="flex"
          justifyContent="space-between"
          className="font-14"
          m={m ? m : "8px 0"}
        >
          <ThemedText.Text5>{label}</ThemedText.Text5>
          <ThemedText.Text9 fontSize={14} ml="4px">
            {val}
          </ThemedText.Text9>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          className="font-14"
          m={m ? m : "8px 0"}
        >
          <ThemedText.Text5Bold>{label}</ThemedText.Text5Bold>
          <ThemedText.Text9Bold fontSize={14} ml="4px">
            {val}
          </ThemedText.Text9Bold>
        </Box>
      )}
    </>
  );
}
export function TooltipText({ text }: dataPros2) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      className="font-14"
      p="8px 0"
    >
      <ThemedText.Text4 fontSize={14}>{text}</ThemedText.Text4>
    </Box>
  );
}

export default TooltipItem;
