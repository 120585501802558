import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";

const ContainerDiv = styled.div<{ rpt?: string; showBorder?: boolean }>`
  display: flex;
  min-height: 100%;
  overflow-y: auto;
  padding-bottom: 44px;

  .leftTab {
    width: 272px;
    padding: 16px;
    background: #0d0d0d;
  }
  .rightContainer {
    flex: 1;
    padding-top: ${({ rpt }) => (rpt ? rpt : "40px")};
    margin-bottom: 48px;
  }
  @media only screen and (max-width: 1501px) {
    .rightContainer {
      padding-left: 16px;
    }
  }
  @media only screen and (max-width: 1500px) {
    .leftTab {
      width: 252px;
      padding: 10px;
    }
  }
  @media only screen and (max-width: 1280px) {
    flex-direction: column;
    .leftTab {
      width: 100%;
    }
    .rightContainer {
      width: 100%;
      padding-top: 24px;
      padding-left: 0;
      margin-bottom: 96px;
    }
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: 104px;
    .rightContainer {
      margin-bottom: 256px;
    }
  }
`;
type dataPros = {
  leftContainer?: React.ReactNode;
  rightContainer?: React.ReactNode;
  rpt?: string;
  showBorder?: boolean;
};

export default function ContainerBox({
  leftContainer,
  rightContainer,
  rpt,
  showBorder,
}: dataPros) {
  return (
    <ContainerDiv rpt={rpt} showBorder={showBorder}>
      <div className="leftTab">{leftContainer}</div>
      <Box
        display="flex"
        justifyContent="center"
        className="rightContainer flex-1"
      >
        {rightContainer}
      </Box>
    </ContainerDiv>
  );
}
