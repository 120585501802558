import { Box, Dialog, useMediaQuery } from "@material-ui/core";
import { useState } from "react";

import "../../views/dashboard/dialog.scss";
import Close from "../../assets/images/close.svg";
import Checkbox from "../Checkbox/Checkbox";
import PendingIcon from "../../assets/images/loading/pending.svg";
import { ConfirmBtn } from "../../components/Button/ConfirmBtn";
import DialogBox from "../DialogBox";
export default function OrdersToa(props) {
  const isVerySmallScreen = useMediaQuery("(max-width: 768px)");
  const { setIsVisible, isPluginApproving, approveOrderBook } = props;

  const [isChecked, setIsChecked] = useState(false);

  const onConfirmationClick = () => {
    approveOrderBook().then(() => {
      setIsVisible(false);
    });
  };

  const getPrimaryText = () => {
    if (isPluginApproving) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <div className="">Enabling Orders</div>
          <img src={PendingIcon} height={20} className="ml-12" />
        </Box>
      );
    }
    if (!isChecked) {
      return "Accept terms to enable orders";
    }
    return "Enable Orders";
  };

  const isPrimaryEnabled = () => {
    if (isPluginApproving) {
      return false;
    }
    return isChecked;
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <DialogBox
      open={true}
      width="400px"
      handleClose={handleClose}
      title="Enable Orders"
    >
      <div className="font-16 mt-16  color28 eid_order">
        Note that orders are not guaranteed to be executed.
        <br />
        This can occur in a few situations including but not exclusive to:
        <br />
        <ul>
          <li>Insufficient liquidity to execute the order</li>
          <li>
            The mark price which is an aggregate of exchange prices did not
            reach the specified price
          </li>
          <li>
            The specified price was reached but not long enough for it to be
            executed
          </li>
          <li>No keeper picked up the order for execution</li>
        </ul>
        <Box p={"24px 0"} className="color28">
          Additionally, trigger orders are market orders and are not guaranteed
          to settle at the trigger price.
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems={"flex-start"}
          gridGap={"8px"}
          className="Orders-toa-accept-rules dosec"
        >
          <Checkbox
            className=""
            isChecked={isChecked}
            setIsChecked={setIsChecked}
          />
          <span
            className="muted flex-1 pointer"
            style={{ lineHeight: "1.2" }}
            onClick={() => setIsChecked(!isChecked)}
          >
            Accept that orders are not guaranteed to execute and trigger orders
            may not settle at the trigger price
          </span>
        </Box>
      </div>
      <Box className="mt-16" p={"0 16px"}>
        <ConfirmBtn
          onClick={onConfirmationClick}
          disabled={!isPrimaryEnabled()}
        >
          {getPrimaryText()}
        </ConfirmBtn>
      </Box>
    </DialogBox>
  );
}
