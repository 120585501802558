import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const CopyBtn = styled(Button)({
  height: "28px",
  lineHeight: "28px",
  maxHeight: "28px",
  width: "80px",
  color: "#FFFD02",
  fontSize: 14,
  fontFamily: "Gilroy-Bold",
  textTransform: "uppercase",
  background: "rgba(255, 255, 255, 0.1)",
  borderRadius: "8px",
  border: "none",
  "&:hover": {
    border: "none",
    background: "rgba(255, 253, 2, 0.5)",
    color: "#FFFD02",
  },
});
