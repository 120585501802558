import { USD_DECIMALS, formatAmount } from "src/helpers/Helpers";
import TooltipItem, { TooltipText } from "../TooltipItem";
import Tooltips from "../Tooltips/totalTips";
import { useMemo } from "react";
import { BN } from "src/utils/bn";

export default function PositionNetValueTooltip(props) {
  const { position } = props;

  const Fee = useMemo(() => {
    let fees = BN(0);
    if (!position) return fees;
    if (!position?.fundingFee || !position?.termTax || !position?.closingFee)
      return fees;
    if (position.fundingFee.gt(0)) {
      fees = fees.minus(position.fundingFee.toString());
    } else {
      fees = fees.plus(position.fundingFee.toString());
    }

    if (position.termTax.gt(0)) {
      fees = fees.minus(position.termTax.toString());
    } else {
      fees = fees.plus(position.termTax.toString());
    }
    if (position.closingFee.gt(0)) {
      fees = fees.minus(position.closingFee.toString());
    } else {
      fees = fees.plus(position.closingFee.toString());
    }

    return fees;
  }, [position]);
  return (
    <div>
      <Tooltips
        position="left"
        handleClassName=""
        renderContent={
          <>
            <div className="toolBottom2">
              <TooltipItem
                label="Initial Collateral"
                val={`$${formatAmount(
                  position.collateral,
                  USD_DECIMALS,
                  2,
                  true
                )}`}
              />
            </div>
            <div className="toolBottom2">
              <TooltipItem label="PnL" val={position.deltaBeforeFeesStr} />
            </div>
            <div className="toolBottom2">
              <TooltipItem
                label="Fee"
                val={
                  Fee.gt(0) ? (
                    <>
                      {" "}
                      +$ {formatAmount(Fee.toFixed(), USD_DECIMALS, 2, true)}
                    </>
                  ) : (
                    <>
                      -$
                      {Math.abs(
                        formatAmount(Fee.toFixed(), USD_DECIMALS, 2, true)
                      )}
                    </>
                  )
                }
              />
              <TooltipItem
                label="- Funding Fee"
                val={
                  position?.fundingFee?.gt(0) ? (
                    <>
                      -$
                      {formatAmount(
                        position?.fundingFee,
                        USD_DECIMALS,
                        2,
                        true
                      )}
                    </>
                  ) : (
                    <>
                      +$
                      {Math.abs(
                        formatAmount(
                          position?.fundingFee,
                          USD_DECIMALS,
                          2,
                          true
                        )
                      )}
                    </>
                  )
                }
                m="8px 0 0 0"
                isRegularText={true}
              />
              {/* <TooltipItem
                label="- Premium"
                val={
                  position?.pendingPremiumFee?.eq(0) ? (
                    <span className="">-</span>
                  ) : position?.pendingPremiumFee?.gt(0) ? (
                    <span>
                      -$
                      {formatAmount(
                        position?.pendingPremiumFee,
                        USD_DECIMALS,
                        2,
                        true
                      )}
                    </span>
                  ) : (
                    <span>
                      +$
                      {Math.abs(
                        formatAmount(
                          position?.pendingPremiumFee,
                          USD_DECIMALS,
                          2,
                          true
                        )
                      )}
                    </span>
                  )
                }
                m="8px 0 0 0"
                isRegularText={true}
              /> */}
              <TooltipItem
                label="- Short-term Tax"
                val={
                  position?.termTax?.eq(0) ? (
                    <span className="">-</span>
                  ) : position?.termTax?.gt(0) ? (
                    <span>
                      -$
                      {formatAmount(position?.termTax, USD_DECIMALS, 2, true)}
                    </span>
                  ) : (
                    <span>
                      +$
                      {Math.abs(
                        formatAmount(position?.termTax, USD_DECIMALS, 2, true)
                      )}
                    </span>
                  )
                }
                m="8px 0 0 0"
                isRegularText={true}
              />
              <TooltipItem
                label="- Close fee"
                val={
                  position?.closingFee?.gt(0) ? (
                    <span>
                      -$
                      {formatAmount(
                        position?.closingFee,
                        USD_DECIMALS,
                        2,
                        true
                      )}
                    </span>
                  ) : (
                    <span>
                      +$
                      {Math.abs(
                        formatAmount(
                          position?.closingFee,
                          USD_DECIMALS,
                          2,
                          true
                        )
                      )}
                    </span>
                  )
                }
                isRegularText={true}
              />
            </div>

            <div className="toolBottom2">
              <TooltipItem
                label="PnL After Fees"
                val={`${position.deltaAfterFeesStr} (${position.deltaAfterFeesPercentageStr})`}
              />
            </div>
            <TooltipText
              text={
                <div className="">
                  *Net Value: Initial Collateral + PnL After Fees
                </div>
              }
            />
          </>
        }
      >
        <span className="toolBottom">
          ${formatAmount(position.netValue, USD_DECIMALS, 2, true)}
        </span>
      </Tooltips>
    </div>
  );
}
