import { LinearProgress, linearProgressClasses } from "@mui/material";
import styled from "styled-components";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: 200,
  height: 4,
  borderRadius: 3,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#333534",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 3,
    backgroundColor: "#FFFD02",
  },
}));
type dataPros = {
  value: any;
};

export default function ProgressBar({ value }: dataPros) {
  return <BorderLinearProgress variant="determinate" value={value} />;
}
