import { Box } from "@mui/material";
import styled from "styled-components/macro";
import { ReactComponent as DYNA } from "src/assets/images/DYNA.svg";
import { ThemedText } from "src/theme/text";
import { useGetDynaPrice } from "src/hooks/get_price_dyna";
import { formatAmount2 } from "src/helpers/Helpers";
import { BUY_DYNA_URL } from "src/constants/misc";
import { useWeb3Context } from "src/hooks";

const PriceBox = styled(Box)`
  display: flex;
  align-items: center;
  height: 36px;
  background: radial-gradient(
      99.03% 9902.78% at 0.97% 50%,
      #002421 0%,
      #00524a 46.94%,
      #282828 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  .logo {
    padding-left: 16px;
    padding-right: 28px;
    border-right: 1px solid rgba(255, 255, 255, 0.16);
  }
  .price {
    margin-left: 12px;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.54) 0%,
      rgba(255, 255, 255, 0.54) 50%,
      transparent 50%
    );
    background-size: 8px 1px;
    background-repeat: repeat-x;
    background-position: left bottom;
    cursor: pointer;
  }
`;

export default function PriceM() {
  const DYNAPrice = useGetDynaPrice();
  const { chainID } = useWeb3Context();
  const openChart = () => {
    // window.open(CHART_URL);
    chainID == 324 && window.open(BUY_DYNA_URL[chainID]);
  };
  return (
    <PriceBox className="price">
      <Box display="flex" alignItems="center" className="logo">
        <DYNA width={16} height={16} />
        <ThemedText.Text1Bold fontSize={14} ml="8px">
          DYNA
        </ThemedText.Text1Bold>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        className="price"
        onClick={openChart}
      >
        <ThemedText.Text9 fontSize={14}>Price:</ThemedText.Text9>
        <ThemedText.Text1Bold fontSize={14} ml="8px">
          ${chainID == 324 ? formatAmount2(DYNAPrice, 2, true) : "--"}
        </ThemedText.Text1Bold>
      </Box>
    </PriceBox>
  );
}
