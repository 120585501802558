import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const CloseBtn = styled(Button)({
  height: "40px",
  lineHeight: "40px",
  maxHeight: "40px",
  width: "60px",
  color: "#FA5341",
  fontSize: 14,
  fontFamily: "Gilroy-Bold",
  background: "transparent",
  border: "none",
  borderRadius: "8px",
  textTransform: "capitalize",
  textAlign: "center",
  ".closeIcon": {
    path: {
      stroke: "#ffffff",
    },
  },
  "&.edots": {
    color: "rgba(126, 159, 255, 0.87)",
  },
  "&:hover": {
    background: "rgba(255, 255, 255, 0.05)",
    color: "#FA5341",
    ".closeIcon": {
      path: {
        stroke: "#ffffff",
      },
    },
  },
});
