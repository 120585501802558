import { Box } from "@mui/material";
import Column from "../Column";
import styled from "styled-components";
import Row, { RowBetween } from "../Row";

export const OpenBox = styled(Column)`
  .stakeInfo {
    border: 1px solid ${({ theme }) => theme.bg3};
    border-radius: 8px;
    align-self: stretch;
    .infoItem {
      :first-child {
        .infoTitle {
          border-radius: 8px 8px 0 0;
        }
      }
      :last-child {
        border-radius: 0 0 8px 8px;
      }
      .infoTitle,
      .infoVal {
        padding-left: 16px;
      }
      .infoTitle {
        height: 40px;
        background: ${({ theme }) => theme.bg1};
      }
      .infoVal {
        height: 40px;
      }
    }
  }
`;

export const OpenCardBox = styled(Box)`
  background: #101010;
  mix-blend-mode: normal;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 16px 16px 23px;
  margin-top: 16px;
  .openLine {
    border: 1px solid rgba(255, 255, 255, 0.08);
    height: 1px;
    width: 100%;
  }
`;

export const H5RowBetween = styled(RowBetween)`
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const H5Row = styled(Row)`
  @media (max-width: 700px) {
    flex-direction: column;
    gap: 14px;
  }
`;

export const FeeBox = styled(Box)`
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  padding-left: 16px;
  padding-top: 16px;
  display: flex;
  width: 254px;
  flex-direction: column;
  min-height: 0;
  width: 100%;
  align-self: stretch;
`;

export const Klines = styled(Box)`
  height: 1px;
  position: relative;
  background: rgba(255, 255, 255, 0.08);
  width: 100%;
`;

export const CloseBox = styled(Box)`
  background: #101010;
  mix-blend-mode: normal;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;
