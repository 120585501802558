import { debounce } from "lodash";
import { useRef } from "react";
const useDebounce = (fun: any, wait: any, options?: any) => {
  const myRef: any = useRef();
  if (!myRef.current) {
    myRef.current = debounce(fun, wait, options);
  }
  return myRef.current;
};
export default useDebounce;
