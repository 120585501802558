import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const RankBtn = styled(Button)({
  height: "40px",
  lineHeight: "40px",
  maxHeight: "40px",
  width: "auto",
  padding: "0 12px",
  color: "#9500FF",
  fontSize: 14,
  fontFamily: "Gilroy-Bold",
  textTransform: "initial",
  background: "rgba(149, 0, 255, 0.20)",
  border: "1px solid",
  borderColor: "#9500FF",
  borderRadius: "4px",
  "&:hover": {
    border: "1px solid",
    borderColor: "#9500FF",
    color: "#FFFFFF",
    background: "#9500FF",
  },
});
