/**
 * Preset styles of the Rebass Text component
 */

import { Text, TextProps as TextPropsOriginal } from "rebass";
import styled from "styled-components/macro";

const TextWrapper = styled(Text).withConfig({
  shouldForwardProp: (prop) => prop !== "color",
})<{ color: keyof string }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`;

type TextProps = Omit<TextPropsOriginal, "css">;

// todo: export each component individually
export const ThemedText = {
  // todo: there should be just one `Body` with default color, no need to make all variations
  BodyPrimary(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={400}
        fontSize={16}
        color="textPrimary"
        {...props}
      />
    );
  },
  BodySecondary(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={400}
        fontSize={16}
        color="textSecondary"
        {...props}
      />
    );
  },
  BodySmall(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={400}
        fontSize={14}
        color="textPrimary"
        {...props}
      />
    );
  },
  Caption(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={400}
        fontSize={12}
        color="textPrimary"
        {...props}
      />
    );
  },
  HeadlineSmall(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={600}
        fontSize={20}
        lineHeight="28px"
        color="textPrimary"
        {...props}
      />
    );
  },
  HeadlineMedium(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={500}
        fontSize={28}
        color="textPrimary"
        {...props}
      />
    );
  },
  HeadlineLarge(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={600}
        fontSize={36}
        lineHeight="44px"
        color="textPrimary"
        {...props}
      />
    );
  },
  LargeHeader(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={400}
        fontSize={36}
        color="textPrimary"
        {...props}
      />
    );
  },
  Hero(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={500}
        fontSize={48}
        color="textPrimary"
        {...props}
      />
    );
  },
  LabelSmall(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={600}
        fontSize={14}
        color="textSecondary"
        {...props}
      />
    );
  },
  Link(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={600}
        fontSize={14}
        color="accentAction"
        {...props}
      />
    );
  },
  MediumHeader(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={400}
        fontSize={20}
        color="textPrimary"
        {...props}
      />
    );
  },
  SubHeader(props: TextProps) {
    // @todo designs use `fontWeight: 500` and we currently have a mix of 600 and 500
    return (
      <TextWrapper
        fontWeight={600}
        fontSize={16}
        color="textPrimary"
        {...props}
      />
    );
  },
  SubHeaderSmall(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={500}
        fontSize={14}
        color="textSecondary"
        {...props}
      />
    );
  },
  UtilityBadge(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={600}
        fontSize="8px"
        lineHeight="12px"
        {...props}
      />
    );
  },
  DeprecatedMain(props: TextProps) {
    return <TextWrapper fontWeight={500} color="textSecondary" {...props} />;
  },
  DeprecatedLink(props: TextProps) {
    return <TextWrapper fontWeight={500} color="accentAction" {...props} />;
  },
  DeprecatedLabel(props: TextProps) {
    return <TextWrapper fontWeight={600} color="textPrimary" {...props} />;
  },
  DeprecatedBlack(props: TextProps) {
    return <TextWrapper fontWeight={500} color="textPrimary" {...props} />;
  },
  DeprecatedWhite(props: TextProps) {
    return <TextWrapper fontWeight={500} color="white" {...props} />;
  },
  DeprecatedBody(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={400}
        fontSize={16}
        color="textPrimary"
        {...props}
      />
    );
  },
  DeprecatedLargeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />;
  },
  DeprecatedMediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />;
  },
  DeprecatedSubHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />;
  },
  DeprecatedSmall(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />;
  },
  DeprecatedBlue(props: TextProps) {
    return <TextWrapper fontWeight={500} color="accentAction" {...props} />;
  },
  DeprecatedYellow(props: TextProps) {
    return (
      <TextWrapper fontWeight={500} color="deprecated_yellow3" {...props} />
    );
  },
  DeprecatedDarkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color="textTertiary" {...props} />;
  },
  DeprecatedGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color="deprecated_bg3" {...props} />;
  },
  DeprecatedItalic(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={500}
        fontSize={12}
        fontStyle="italic"
        color="textSecondary"
        {...props}
      />
    );
  },
  DeprecatedError({ error, ...props }: { error: boolean } & TextProps) {
    return (
      <TextWrapper
        fontWeight={500}
        color={error ? "accentFailure" : "textSecondary"}
        {...props}
      />
    );
  },
  Title(props: TextProps) {
    return (
      <TextWrapper fontFamily="Gilroy-ExtraBold" fontSize={48} {...props} />
    );
  },
  Text1(props: TextProps) {
    return <TextWrapper fontSize={24} color="text1" {...props} />;
  },
  Text1Medium(props: TextProps) {
    return (
      <TextWrapper
        fontFamily="Gilroy-Medium"
        fontSize={14}
        color="text1"
        {...props}
      />
    );
  },
  Text1Bold(props: TextProps) {
    return (
      <TextWrapper
        fontFamily="Gilroy-Bold"
        fontSize={24}
        color="text1"
        {...props}
      />
    );
  },
  Text2(props: TextProps) {
    return <TextWrapper fontSize={16} color="text2" {...props} />;
  },
  Text2Bold(props: TextProps) {
    return (
      <TextWrapper
        fontFamily="Gilroy-Bold"
        fontSize={16}
        color="text2"
        {...props}
      />
    );
  },
  Text3(props: TextProps) {
    return <TextWrapper fontSize={14} color="text3" {...props} />;
  },
  Text4(props: TextProps) {
    return <TextWrapper fontSize={12} color="text4" {...props} />;
  },
  Text4Medium(props: TextProps) {
    return (
      <TextWrapper
        fontSize={14}
        fontFamily="Gilroy-Medium"
        color="text4"
        {...props}
      />
    );
  },
  Text4Bold(props: TextProps) {
    return (
      <TextWrapper
        fontSize={14}
        fontFamily="Gilroy-Bold"
        color="text4"
        {...props}
      />
    );
  },
  Text5(props: TextProps) {
    return <TextWrapper fontSize={14} color="text5" {...props} />;
  },
  Text5Medium(props: TextProps) {
    return (
      <TextWrapper
        fontSize={14}
        fontFamily="Gilroy-Medium"
        color="text5"
        {...props}
      />
    );
  },
  Text5Bold(props: TextProps) {
    return (
      <TextWrapper
        fontSize={14}
        fontFamily="Gilroy-Bold"
        color="text5"
        {...props}
      />
    );
  },
  Text7(props: TextProps) {
    return <TextWrapper fontSize={24} color="text7" {...props} />;
  },
  Text7Bold(props: TextProps) {
    return (
      <TextWrapper
        fontSize={20}
        fontFamily="Gilroy-Bold"
        color="text7"
        {...props}
      />
    );
  },
  Text8(props: TextProps) {
    return <TextWrapper fontSize={12} color="text8" {...props} />;
  },
  Text8Medium(props: TextProps) {
    return (
      <TextWrapper
        fontSize={12}
        fontFamily="Gilroy-Medium"
        color="text8"
        {...props}
      />
    );
  },
  Text9(props: TextProps) {
    return <TextWrapper fontSize={24} color="text9" {...props} />;
  },
  Text9Medium(props: TextProps) {
    return (
      <TextWrapper
        fontSize={14}
        fontFamily="Gilroy-Medium"
        color="text9"
        {...props}
      />
    );
  },
  Text9Bold(props: TextProps) {
    return (
      <TextWrapper
        fontSize={24}
        fontFamily="Gilroy-Bold"
        color="text9"
        {...props}
      />
    );
  },
  Text10(props: TextProps) {
    return <TextWrapper fontSize={14} color="text10" {...props} />;
  },
  Text10Medium(props: TextProps) {
    return (
      <TextWrapper
        fontSize={14}
        fontFamily="Gilroy-Medium"
        color="text10"
        {...props}
      />
    );
  },
  Text10Bold(props: TextProps) {
    return (
      <TextWrapper
        fontSize={14}
        fontFamily="Gilroy-Bold"
        color="text10"
        {...props}
      />
    );
  },
  Text11(props: TextProps) {
    return <TextWrapper fontSize={14} color="text11" {...props} />;
  },
  Balance(props: TextProps) {
    return (
      <TextWrapper
        fontSize={18}
        fontFamily="Gilroy-Bold"
        color="text3"
        {...props}
      />
    );
  },
  PrimaryText1(props: TextProps) {
    return (
      <TextWrapper
        fontSize={14}
        fontFamily="Gilroy"
        color="primaryText1"
        {...props}
      />
    );
  },
  PrimaryText1Medium(props: TextProps) {
    return (
      <TextWrapper
        fontSize={16}
        fontFamily="Gilroy-Medium"
        color="primaryText1"
        {...props}
      />
    );
  },
  PrimaryText1Bold(props: TextProps) {
    return (
      <TextWrapper
        fontSize={14}
        fontFamily="Gilroy-Bold"
        color="primaryText1"
        {...props}
      />
    );
  },
  PrimaryText2(props: TextProps) {
    return (
      <TextWrapper
        fontSize={18}
        fontFamily="Gilroy-Bold"
        color="primaryText2"
        {...props}
      />
    );
  },
  PrimaryText3Bold(props: TextProps) {
    return (
      <TextWrapper
        fontSize={24}
        fontFamily="Gilroy-Bold"
        color="primaryText3"
        {...props}
      />
    );
  },
  PrimaryText4Bold(props: TextProps) {
    return (
      <TextWrapper
        fontSize={20}
        fontFamily="Gilroy-Bold"
        color="primaryText4"
        {...props}
      />
    );
  },
  PrimaryText5(props: TextProps) {
    return (
      <TextWrapper
        fontSize={14}
        fontFamily="Gilroy"
        color="primaryText5"
        {...props}
      />
    );
  },
  ClaimText(props: TextProps) {
    return (
      <TextWrapper
        fontSize={14}
        fontFamily="Gilroy"
        color="claimText"
        {...props}
      />
    );
  },
  ClaimTextBold(props: TextProps) {
    return (
      <TextWrapper
        fontSize={14}
        fontFamily="Gilroy-Bold"
        color="claimText"
        {...props}
      />
    );
  },
};
