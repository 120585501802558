import {
  RANK_LIST_BRIDGE,
  RANK_LIST_HOLYGRAIL,
} from "src/helpers/leaderboardConstants";
import Browser from "src/assets/images/links/browser.svg";
// import BridgeBanner from "src/assets/images/leaderboard/banner_boardingBridge.svg";
import { SupportedChainId } from "./chainENV/chains.base";

export const VaultUtilsblacklist = [
  "0x276A0cb4973A1527Bc2E9cbD9b278eF8061A4F46",
  "0x3dfC44Ca476527Eb03D7f4B334ac958b201c8019",
  "0x03Fb73dFA117AA4B44C1A624d48FeD2076bf6382",
  "0x08633Ff2b893563a12f53e4a23aaf617318a46B4",
];

export const DOC_LINK = {
  documentation: "",
  discord: "",
  twitter: "",
  telegram: "",
  medium: "",
  github: "",
  stats: "",
};

export const DOC_LINK_arr = [
  {
    link: DOC_LINK.stats,
    name: "Stats",
    target: "_blank",
    icon: Browser,
  },
  {
    link: DOC_LINK.documentation,
    name: "Docs",
    target: "_blank",
    icon: Browser,
  },
  // {
  //   link: DOC_LINK.discord,
  //   name: "Discord",
  //   target: "_blank",
  // },
  // {
  //   link: DOC_LINK.twitter,
  //   name: "Twitter",
  //   target: "_blank",
  // },
  // {
  //   link: DOC_LINK.telegram,
  //   name: "Telegram",
  //   target: "_blank",
  // },
  // {
  //   link: DOC_LINK.medium,
  //   name: "Medium",
  //   target: "_blank",
  // },
  // {
  //   link: DOC_LINK.github,
  //   name: "Github",
  //   target: "_blank",
  // },
];

export const PRICE_CHART_URL: { [key: number]: string } = {
  [SupportedChainId.ZKTEST]: "",
  [SupportedChainId.ZKMAINNET]: "",
};
export const AddLiquidityUrl: { [key: number]: string } = {
  [SupportedChainId.ZKMAINNET]: "",
  [SupportedChainId.ZKTEST]: "",
};
export const RemoveLiquidityUrl: { [key: number]: string } = {
  [SupportedChainId.ZKMAINNET]: "",
  [SupportedChainId.ZKTEST]: "",
};
export const COMMUNITY_ROUTER_OBJ = {
  "/Leaderboard/HolyGrail": {
    bannerImg: "",
    rankList: RANK_LIST_HOLYGRAIL,
    name: "holygrail",
  },
  "/Leaderboard/BoardingBridge": {
    bannerImg: "",
    rankList: RANK_LIST_BRIDGE,
    name: "boardingbridge",
  },
};

export const DEFAULT_URL = "https://api.dyna.finance";

// 网络请求轮询间隔
export const HTTP_POLL_DELAY = 2000;
// 网络轮询队列元素请求间隔
export const HTTP_QUEUEQUERY_DELAY = 500;

export const PRICE_API = {
  [SupportedChainId.ARBITRUM]:
    "https://xc-mainnet.pyth.network/api/latest_vaas",
  [SupportedChainId.ARB_TEST]: "https://hermes.pyth.network/api/latest_vaas",
  [SupportedChainId.BLAST_TEST]: "https://hermes.pyth.network/api/latest_vaas",
  [SupportedChainId.ZKMAINNET]:
    "https://xc-mainnet.pyth.network/api/latest_vaas",
  [SupportedChainId.ZKTEST]: "https://xc-testnet.pyth.network/api/latest_vaas",
  [SupportedChainId.MANTLE_TEST]:
    "https://xc-testnet.pyth.network/api/latest_vaas",
  [SupportedChainId.MANTLE]: "https://xc-mainnet.pyth.network/api/latest_vaas",
};

export const BUY_DYNA_URL = {
  [SupportedChainId.ARBITRUM]: "",
  [SupportedChainId.MANTLE]: "",
  [SupportedChainId.ZKMAINNET]:
    "https://syncswap.xyz/?outputCurrency=0xf8c6da1bbdc31ea5f968ace76e931685ca7f9962",
};

export const CHART_URL =
  "https://dexscreener.com/zksync/0x85a60656b85ebbac1b7567476f2a6fa0f229e52e";

export const LP_FARM_URL = "https://zksync.izumi.finance/farm/dynamic";

export const SHARE_URL = "https://app.dyna.finance/#/DARMY?ref=";
