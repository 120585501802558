import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IPendingTxn {
  readonly txnHash: string;
  readonly text: string;
  readonly type: string;
}

const initialState: Array<IPendingTxn> = [];

const pendingTxnsSlice = createSlice({
  name: "pendingTransactions",
  initialState,
  reducers: {
    fetchPendingTxns(state, action: PayloadAction<IPendingTxn>) {
      state.push(action.payload);
    },
    clearPendingTxn(state, action: PayloadAction<string>) {
      const target = state.find((x) => x.txnHash === action.payload);
      if (target) {
        state.splice(state.indexOf(target), 1);
      }
    },
  },
});

export const isPendingTxn = (
  pendingTransactions: IPendingTxn[],
  type: string
) => {
  return pendingTransactions?.map((x) => x.type).includes(type);
};

export const txnButtonText = (
  pendingTransactions: IPendingTxn[],
  type: string,
  defaultText: string
) => {
  return isPendingTxn(pendingTransactions, type) ? "Pending..." : defaultText;
  // let html = ""
  // html += "<div>Pending</div>"
  //   + "<img src = { " + PendingIcon + " } height = { 20} className ='ml-12' />"
  // console.log("html", html, isPendingTxn(pendingTransactions, type));
  // html += "<Box display='flex' justifyContent='center' alignItems='center'>" +
  //   "<div className=''>Waiting for Approval</div>" +
  //   "<img src={" + PendingIcon + " } height={20} className='ml-12' />" +
  //   "</Box>"
  // return isPendingTxn(pendingTransactions, type)
  //   ?
  //   "<div>pending</div>"
  //   :
  //   defaultText;
};

export const txnButtonTextGeneralPending = (
  pendingTransactions: IPendingTxn[],
  type: string,
  defaultText: string
) => {
  return pendingTransactions.length >= 1 ? "Pending..." : defaultText;
};

export const { fetchPendingTxns, clearPendingTxn } = pendingTxnsSlice.actions;

export default pendingTxnsSlice.reducer;
