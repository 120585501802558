import { createMulticall, ListenerOptions } from "@uniswap/redux-multicall";
import { useInterfaceMulticall } from "src/hooks/useContract";
import useBlockNumber from "src/lib/hooks/useBlockNumber";
import { useEffect, useMemo } from "react";
import { useWeb3Context } from "src/hooks";
import { useDataList } from "src/hooks/useDataList";
import { batch, useDispatch } from "react-redux";
import { loadAppDetails } from "src/slices/AppSlice";
import { SupportedChainId } from "src/constants/chainENV/chains.base";
import useDebounce from "src/utils/useDebounce";

const multicall = createMulticall();

export default multicall;

export function getBlocksPerFetchForChainId(
  chainId: number | undefined
): number {
  switch (chainId) {
    case SupportedChainId.BLAST_TEST:
    case SupportedChainId.ARB_TEST:
      return 15;
    default:
      return 5;
  }
}

export function MulticallUpdater() {
  const { chainID: chainId } = useWeb3Context();
  const latestBlockNumber = useBlockNumber();
  const contract = useInterfaceMulticall();
  const DataList = useDataList();
  const dispatch = useDispatch();

  const handleDebounce = useDebounce((list) => {
    batch(() => {
      dispatch(loadAppDetails(list));
    });
  }, 0);
  useEffect(() => {
    if (!DataList) return;

    handleDebounce(DataList);
    return () => {
      handleDebounce.cancel();
    };
  }, [DataList, dispatch, handleDebounce]);

  const listenerOptions: ListenerOptions = useMemo(
    () => ({
      blocksPerFetch: getBlocksPerFetchForChainId(chainId),
    }),
    [chainId]
  );

  return (
    <multicall.Updater
      chainId={chainId}
      latestBlockNumber={latestBlockNumber}
      contract={contract}
      listenerOptions={listenerOptions}
    />
  );
}
