import { useWeb3Context } from "./web3Context";
import { useSingleCallResult } from "src/lib/hooks/multicall";
import { useLpBondContract, useUSDBContract } from "./useContract";
import { useMemo } from "react";
import { toFromBN, toWei } from "src/utils/bn";

export const useDecimals_USDB = () => {
  const contract_USDB = useUSDBContract();
  const { result } = useSingleCallResult(contract_USDB, "decimals");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return data;
  }, [result]);
};

export const useDYNAMarketPrice = () => {
  const { address: account, provider, chainID } = useWeb3Context();
  const lpContract = useLpBondContract();
  const { result, error } = useSingleCallResult(lpContract, "getPrice");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data, 8);
  }, [result]);
};

export const useBondDiscount = () => {
  const lpContract = useLpBondContract();
  const { result } = useSingleCallResult(lpContract, "DiscountRate");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data, 5);
  }, [result]);
};

export const useBonded = () => {
  const { address: account } = useWeb3Context();
  const lpContract = useLpBondContract();
  const decimals = useDecimals_USDB();
  const { result } = useSingleCallResult(lpContract, "terms");
  return useMemo(() => {
    if (!result) return;
    const data = result.saleDebt;
    return toFromBN(data, decimals);
  }, [result, decimals]);
};

export const useReceive = () => {
  const lpContract = useLpBondContract();
  const decimals = useDecimals_USDB();
  const { result } = useSingleCallResult(lpContract, "payout", [
    toWei("1", decimals).toString(),
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};
