import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const MintBtn = styled(Button)({
  height: "36px",
  lineHeight: "36px",
  maxHeight: "36px",
  width: "128px",
  color: "#000000",
  fontSize: 14,
  fontFamily: "Gilroy-Medium",
  textTransform: "uppercase",
  background: "#FFFD02",
  border: "none",
  borderRadius: "8px",
  "&:hover": {
    background: "#FFFE96",
    color: "#000000",
  },
});
