import { Trans } from "@lingui/macro";
import { Box, useMediaQuery } from "@mui/material";
import { ThemedText } from "src/theme/text";
import styled from "styled-components";
import { useBasicInfo } from "src/hooks/useQueryDID";
import { formatAmount, formatAmount2 } from "src/helpers/Helpers";
import Dots from "src/components/Dots";

const TradeBox = styled(Box)`
  margin-top: 16px;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(4, 1fr);
  padding: 26px 20px 24px 20px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 8px;
  @media only screen and (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export default function TeamInfo({ teamBond, teamStaked }: any) {
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 768px)");
  const baseInfo = useBasicInfo();

  return (
    <TradeBox>
      <div className="">
        <Box display="flex" alignItems="center" className="">
          <Dots bgColor="#FFFD02" />
          <ThemedText.Text4Bold ml="8px">
            <Trans>Friends</Trans>
          </ThemedText.Text4Bold>
        </Box>
        <ThemedText.Text1Bold fontSize={18} mt="8px">
          {/* {formatAmount2(friends, 0, true)} */}
          {formatAmount2(Number(baseInfo?.friends), 0, true)}
        </ThemedText.Text1Bold>
      </div>
      <div className="">
        <Box display="flex" alignItems="center" className="">
          <Dots bgColor="#FFFD02" />
          <ThemedText.Text4Bold ml="8px">
            <Trans>Team staked</Trans>
          </ThemedText.Text4Bold>
        </Box>
        <ThemedText.Text1Bold fontSize={18} mt="8px">
          ${formatAmount(teamStaked, 18, 0, true)}
        </ThemedText.Text1Bold>
      </div>
      <div className="">
        <Box display="flex" alignItems="center" className="">
          <Dots bgColor="#FFFD02" />
          <ThemedText.Text4Bold ml="8px">
            <Trans>Team Bond</Trans>
          </ThemedText.Text4Bold>
        </Box>
        <ThemedText.Text1Bold fontSize={18} mt="8px">
          ${formatAmount(teamBond, 18, 0, true)}
        </ThemedText.Text1Bold>
      </div>
    </TradeBox>
  );
}
