import { useEffect } from "react";
import { useWeb3Context } from "./web3Context";

export const useCheckVersion = () => {
  const { chainID } = useWeb3Context();
  useEffect(() => {
    const currentVersion = localStorage.getItem("DYNA_VERSION");
    const currentChainID = localStorage.getItem("SELECTED_NETWORK");
    const version = process.env.REACT_APP_VERSION;
    if (Number(currentVersion) != Number(version)) {
      localStorage.clear();
      localStorage.setItem("DYNA_VERSION", version ?? "1");
      if (currentChainID) {
        localStorage.setItem("SELECTED_NETWORK", chainID.toFixed());
      }
    }
  }, [chainID]);
};
