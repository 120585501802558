import { Trans } from "@lingui/macro";
import { Box, useMediaQuery } from "@mui/material";
import { ThemedText } from "src/theme/text";
import styled from "styled-components/macro";
import { DefaultNoData } from "src/components/NoData";
import { shorten } from "src/helpers";
import { TableBox } from "../../History/TableBox";

const TeamBox = styled(TableBox)`
  border-top: 1px solid ${({ theme }) => theme.bg3};
  margin-top: 16px;
`;

export default function TeamList({ teamList }: any) {
  const isMobile = useMediaQuery("(max-width: 1080px)");

  return (
    <TeamBox className="teamList">
      {isMobile ? (
        <div className="table-m">
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
            className="tableHeader-m"
          >
            <div className="">
              <ThemedText.Text4Bold>
                <Trans>Name</Trans>
              </ThemedText.Text4Bold>
            </div>
            <div className="text-right">
              <ThemedText.Text4Bold>
                <Trans>Staked</Trans>
              </ThemedText.Text4Bold>
            </div>
          </Box>
          {teamList.length > 0 ? (
            teamList.map((item, index) => (
              <Box
                display="flex"
                justifyContent={"space-between"}
                className="tableItem-m"
                key={index}
              >
                <div className="">
                  <div className="" style={{ textDecoration: "underline" }}>
                    <ThemedText.Text1Medium>
                      {shorten(item.address)}
                    </ThemedText.Text1Medium>
                  </div>
                  <Box display="flex" mt="8px" className="">
                    <ThemedText.Text1Medium>
                      {shorten(item.address)}
                    </ThemedText.Text1Medium>
                  </Box>
                  <ThemedText.Text1Medium mt="8px">
                    {item.teamStaked}
                  </ThemedText.Text1Medium>
                </div>
                <div className="">
                  <ThemedText.Text1Medium>
                    Total Bonded: {item.teamBond}
                  </ThemedText.Text1Medium>
                </div>
              </Box>
            ))
          ) : (
            <DefaultNoData title="No items to display" />
          )}
        </div>
      ) : (
        <>
          <Box
            display="flex"
            alignItems="center"
            className="tableHeader"
            style={{ padding: "16px 0" }}
          >
            <div className="flex-1 td">
              <ThemedText.Text4Bold>
                <Trans>Name</Trans>
              </ThemedText.Text4Bold>
            </div>
            <div className="flex-1 td">
              <ThemedText.Text4Bold>
                <Trans>Wallet</Trans>
              </ThemedText.Text4Bold>
            </div>
            <div className="flex-1 td">
              <ThemedText.Text4Bold>
                <Trans>Staked</Trans>
              </ThemedText.Text4Bold>
            </div>
            <div className="flex-1 td">
              <ThemedText.Text4Bold>
                <Trans>Total Bonded</Trans>
              </ThemedText.Text4Bold>
            </div>
          </Box>
          {teamList.length > 0 ? (
            teamList.map((item, index) => (
              <Box
                display="flex"
                alignItems="center"
                className="tableTr"
                key={index}
              >
                <div className="flex-1 td">
                  <ThemedText.Text1Medium>
                    {shorten(item.address)}
                  </ThemedText.Text1Medium>
                </div>
                <div className="flex-1 td">
                  <ThemedText.Text1Medium>
                    {shorten(item.address)}
                  </ThemedText.Text1Medium>
                </div>
                <div className="flex-1 td">
                  <ThemedText.Text1Medium>
                    {item.teamStaked}
                  </ThemedText.Text1Medium>
                </div>
                <div className="flex-1 td">
                  <ThemedText.Text1Medium>
                    {item.teamBond}
                  </ThemedText.Text1Medium>
                </div>
              </Box>
            ))
          ) : (
            <DefaultNoData title="No items to display" />
          )}
        </>
      )}
    </TeamBox>
  );
}
