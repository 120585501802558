import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useGetToken = (address?: string) => {
  const TOKEN_MAP = useSelector((state: any) => {
    return state.app.tokensMap || {};
  });

  return useMemo(() => {
    if (!address || !TOKEN_MAP[address.toLocaleLowerCase()]) return;
    return TOKEN_MAP[address.toLocaleLowerCase()];
  }, [TOKEN_MAP, address]);
};
