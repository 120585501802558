import { Box } from "@mui/material";
import styled from "styled-components/macro";

export const FaucetBoxM = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #ffffff1a;
  border-radius: 10px;
`;
