import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const CancelBtn = styled(Button)({
  height: "48px",
  lineHeight: "48px",
  maxHeight: "48px",
  width: "100%",
  color: "rgba(255, 255, 255, 0.54)",
  fontSize: 14,
  fontFamily: "Gilroy-Bold",
  textTransform: "uppercase",
  background: "rgba(255, 255, 255, 0.05)",
  boxShadow: "0px 24px 14px rgba(0, 0, 0, 0.16)",
  border: "none",
  borderRadius: "8px",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.2)",
    color: "rgba(255, 255, 255, 0.54)",
  },
});
