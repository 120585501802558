import { NEVER_RELOAD, useSingleCallResult } from "src/lib/hooks/multicall";
import { useOrderBookContract } from "./useContract";
import { useMemo } from "react";

export const useMinExecutionFee = (orderBookAddress?: string) => {
  const OrderBookContract = useOrderBookContract(orderBookAddress);
  const { result } = useSingleCallResult(
    OrderBookContract,
    "minExecutionFee",
    undefined,
    NEVER_RELOAD
  );
  return useMemo(() => {
    if (!result) return;

    return result[0];
  }, [result]);
};
