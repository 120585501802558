import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { formatAmount2 } from "src/helpers/Helpers";
import { ThemedText } from "src/theme/text";
import { BN } from "src/utils/bn";

const MaxBox = styled(Box)`
  width: 100%;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(4, 1fr);
  .max_item {
    padding: 3px 0;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.16);
    border-radius: 4px;
    &:hover {
      background: rgba(255, 255, 255, 0.2);
      color: rgba(255, 255, 255, 0.87);
    }
  }
`;

type InputProps = {
  balance?: number | string;
  setInputValue?: any;
};

export const MaxTab = ({ balance, setInputValue }: InputProps) => {
  const setAmount = (rate: number) => {
    if (Number.isNaN(Number(balance))) return;

    const amount = BN(balance || 0)
      .times(rate)
      .toFixed();
    if (Number.isNaN(Number(amount))) return;
    setInputValue &&
      setInputValue(formatAmount2(BN(amount).toString(), 18, true));
  };

  return (
    <MaxBox>
      <div className="max_item pointer" onClick={() => setAmount(0.25)}>
        <ThemedText.Text4Bold fontSize={12}>25%</ThemedText.Text4Bold>
      </div>
      <div className="max_item pointer" onClick={() => setAmount(0.5)}>
        <ThemedText.Text4Bold fontSize={12}>50%</ThemedText.Text4Bold>
      </div>
      <div className="max_item pointer" onClick={() => setAmount(0.75)}>
        <ThemedText.Text4Bold fontSize={12}>75%</ThemedText.Text4Bold>
      </div>
      <div className="max_item pointer" onClick={() => setAmount(1)}>
        <ThemedText.Text4Bold fontSize={12}>MAX</ThemedText.Text4Bold>
      </div>
    </MaxBox>
  );
};
