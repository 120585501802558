import { setAll } from "../helpers";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk } from "./interfaces";

export const loadAccountRewards = createAsyncThunk(
  "account/loadAccountRewards",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {
    return {};
  }
);

interface IAccountRewardsSlice {
  loading: boolean;
}

const initialState: IAccountRewardsSlice = {
  loading: false,
};

const accountRewardsSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    fetchAccountSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAccountRewards.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadAccountRewards.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAccountRewards.rejected, (state, {}) => {
        state.loading = false;
      });
  },
});

const baseInfo = (state: RootState) => state.reward;
export default accountRewardsSlice.reducer;
export const { fetchAccountSuccess } = accountRewardsSlice.actions;
export const getAccountState = createSelector(
  baseInfo,
  (accountRewards) => accountRewards
);
