import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import cx from "classnames";

import { createChart } from "krasulya-lightweight-charts";

import {
  USD_DECIMALS,
  SWAP,
  INCREASE,
  CHART_PERIODS,
  getTokenInfo,
  formatAmount,
  formatDateTime,
  usePrevious,
  getLiquidationPrice,
  getLiquidationPrice2,
  useLocalStorageSerializeKey,
  numberWithCommas,
  formatAmount2,
  SHORT,
  useLocalStorageByChainId,
} from "../../helpers/Helpers";
import { useChartPrices } from "../../Api";
import Tab from "../Tab/Tab";
import up from 'src/assets/images/trade/updown.svg'
import down from 'src/assets/images/trade/down.svg'
import { getTokens, getToken, getTokenBySymbol } from "../../configs/Tokens";
import ChartTokenSelector from "./ChartTokenSelector";
import Row from "../Row";
import Tooltips from "../Tooltips/totalTips";
import Column from "../Column";
import TooltipItem, { TooltipText } from "../TooltipItem";
import { useVolumeDataRequest } from "../recharts/hooks";
import { getConstant } from "src/configs/getConstant";
import { useSelector } from "react-redux";
import { computeNumUnit } from 'src/utils/formatNum'
import { Ether } from "@uniswap/sdk-core";
import { ethers } from "ethers";
import { InterestProgress } from "./InterestProgress";
import { compareAddress } from "src/utils/address";
import { useAppSelector } from "src/hooks";
import { availableNetworksForChart } from "src/views/trade/TVChartContainer/constants";
import { TVDataProvider } from "src/domain/tradingview/TVDataProvider";
import { ReactComponent as Default } from 'src/assets/images/trade/Default.svg';
import { ReactComponent as Expend } from 'src/assets/images/trade/expend.svg'
import TVChartContainer from "src/views/trade/TVChartContainer/TVChartContainer";
import { Box, useMediaQuery } from "@mui/material";
import { ThemedText } from "src/theme/text";
const PRICE_LINE_TEXT_WIDTH = 15;
const timezoneOffset = -new Date().getTimezoneOffset() * 60;

export function getChartToken(swapOption, fromToken, toToken, chainId) {
  if (!fromToken || !toToken) {
    return;
  }

  if (swapOption !== SWAP) {
    return toToken;
  }

  if (fromToken.isUsdx && toToken.isUsdx) {
    return getTokens(chainId).find((t) => t.isStable);
  }
  if (fromToken.isUsdx) {
    return toToken;
  }
  if (toToken.isUsdx) {
    return fromToken;
  }

  if (fromToken.isStable && toToken.isStable) {
    return toToken;
  }
  if (fromToken.isStable) {
    return toToken;
  }
  if (toToken.isStable) {
    return fromToken;
  }

  return toToken;
}

const DEFAULT_PERIOD = "15m";

const getSeriesOptions = () => ({
  // https://github.com/tradingview/lightweight-charts/blob/master/docs/area-series.md
  lineColor: "#5472cc",
  topColor: "rgba(49, 69, 131, 0.4)",
  bottomColor: "rgba(42, 64, 103, 0.0)",
  lineWidth: 2,
  priceLineColor: "#3a3e5e",
  downColor: "#fa3c58",
  wickDownColor: "#fa3c58",
  upColor: "#0ecc83",
  wickUpColor: "#0ecc83",
  borderVisible: false,

});

const getChartOptions = (width, height, tokenDecimals) => ({
  width,
  height,
  layout: {
    backgroundColor: "rgba(255, 255, 255, 0)",
    textColor: "#ccc",
    fontFamily: "Relative",
  },
  localization: {
    // https://github.com/tradingview/lightweight-charts/blob/master/docs/customization.md#time-format
    timeFormatter: (businessDayOrTimestamp) => {
      return formatDateTime(businessDayOrTimestamp - timezoneOffset);
    },
    priceFormatter: (v) => {
      return formatAmount2(v, tokenDecimals, false);
    }
  },
  handleScale: {  // 缩放
    axisPressedMouseMove: true,
    mouseWheel: true,
    pinch: true,
  },
  grid: {
    vertLines: {
      visible: true,
      color: "rgba(35, 38, 59, 1)",
      style: 2,
    },
    horzLines: {
      visible: true,
      color: "rgba(35, 38, 59, 1)",
      style: 2,
    },
  },
  // https://github.com/tradingview/lightweight-charts/blob/master/docs/time-scale.md#time-scale
  timeScale: {
    rightOffset: 5,
    borderVisible: false,
    barSpacing: 0,
    timeVisible: true,
    fixLeftEdge: true,
  },
  // https://github.com/tradingview/lightweight-charts/blob/master/docs/customization.md#price-axis
  priceScale: {
    borderVisible: false,

  },
  crosshair: {
    horzLine: {
      color: "#aaa",
    },
    vertLine: {
      color: "#aaa",
    },
    mode: 0,
  },
});

export default function ExchangeTVChart(props) {
  const {
    dlpName,
    swapOption,
    fromTokenAddress,
    toTokenAddress,
    tokenDecimals,
    infoTokens,
    chainId,
    positions,
    savedShouldShowPositionLines,
    orders,
    setToTokenAddress,
    setFromTokenAddress,
    currInfoTokens
  } = props;
  const ALL_TOKEN_MAP = useSelector((state) => {
    return state.app.tokensMap || {};
  });

  const dataProvider = useRef();

  const isVerySmallScreen = useMediaQuery("(max-width: 768px)");
  useEffect(() => {
    dataProvider.current = new TVDataProvider();
  }, []);

  let isShort = swapOption === SHORT;

  const [currentChart, setCurrentChart] = useState();
  const [currentSeries, setCurrentSeries] = useState();

  let [period, setPeriod] = useLocalStorageSerializeKey([chainId, "Chart-period"], DEFAULT_PERIOD);
  if (!(period in CHART_PERIODS)) {
    period = DEFAULT_PERIOD;
  }

  const [hoveredCandlestick, setHoveredCandlestick] = useState();

  const tokens = getTokens(chainId);

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress);

  const toToken = getTokenInfo(infoTokens, toTokenAddress);
  const [chartToken, setChartToken] = useState({
    maxPrice: null,
    minPrice: null,
  });
  const [currentPiceColor, setcurrentPiceColor] = useState(false);

  useEffect(() => {
    const tmp = getChartToken(swapOption, fromToken, toToken, chainId);
    const diff = chartToken?.maxPrice ? chartToken?.maxPrice.gt(tmp?.maxPrice || 0) : true
    setcurrentPiceColor(diff);
    setChartToken(tmp);

  }, [swapOption, fromToken, toToken, chainId]);


  const symbol = chartToken ? (chartToken.isWrapped ? chartToken.baseSymbol : chartToken.symbol) : undefined;
  const marketName = chartToken ? symbol + "_USD" : undefined;
  const previousMarketName = usePrevious(marketName);

  const currentOrders = useMemo(() => {
    if (swapOption === SWAP || !chartToken) {
      return [];
    }

    if (orders.length > 0) {
      return orders?.filter((order) => {
        if (order.type === SWAP) {
          // we can't show non-stable to non-stable swap orders with existing charts
          // so to avoid users confusion we'll show only long/short orders
          return false;
        }
        const indexToken = ALL_TOKEN_MAP[order.indexToken.toLocaleLowerCase()]
        return compareAddress(order.indexToken, chartToken.address) || (chartToken.isNative && indexToken.isWrapped);

      });
    } else {
      return [];
    }
  }, [orders, chartToken, swapOption, chainId]);

  const ref = useRef(null);
  const chartRef = useRef(null);
  const currentAveragePrice =
    chartToken?.maxPrice && chartToken?.minPrice ? chartToken.maxPrice.add(chartToken.minPrice).div(2) : null;
  const [priceData, updatePriceData] = useChartPrices(
    chainId,
    chartToken?.symbol,
    chartToken?.isStable,
    period,
    currentAveragePrice
  );

  useEffect(() => {
    const interval = setInterval(() => {
      updatePriceData(undefined, true);
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [updatePriceData]);



  useEffect(() => {
    const lines = [];
    if (currentSeries && savedShouldShowPositionLines) {
      if (currentOrders && currentOrders.length > 0) {
        currentOrders.forEach((order) => {
          const indexToken = getToken(chainId, order.indexToken);
          let tokenSymbol;
          if (indexToken && indexToken.symbol) {
            tokenSymbol = indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol;
          }
          const title = `${order.type === INCREASE ? "Inc." : "Dec."} ${tokenSymbol} ${order.isLong ? "Long" : "Short"
            }`;
          const color = "#3a3e5e";
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(order.triggerPrice, USD_DECIMALS, 2)),
              color,
              title: title.padEnd(PRICE_LINE_TEXT_WIDTH, " "),
            })
          );
        });
      }
      if (currentPositions && currentPositions.length > 0) {
        const color = "#3a3e5e";

        positions.forEach((position) => {
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(position.averagePrice, USD_DECIMALS, 2)),
              color,
              title: `Open ${position.indexToken.symbol} ${position.isLong ? "Long" : "Short"}`.padEnd(
                PRICE_LINE_TEXT_WIDTH,
                " "
              ),
            })
          );

          const liquidationPrice = getLiquidationPrice({
            size: position.size,
            collateral: position.collateral,
            averagePrice: position.averagePrice,
            isLong: position.isLong,
            fundingFee: position.fundingFee,
          });

          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(liquidationPrice, USD_DECIMALS, 2)),
              color,
              title: `Liq. ${position.indexToken.symbol} ${position.isLong ? "Long" : "Short"}`.padEnd(
                PRICE_LINE_TEXT_WIDTH,
                " "
              ),
            })
          );
        });
      }
    }
    return () => {
      lines.forEach((line) => currentSeries.removePriceLine(line));
    };
  }, [currentOrders, currentSeries, chainId, savedShouldShowPositionLines, currentPositions, positions]);
  let high;
  let low;
  let deltaPrice;
  let delta;
  let deltaPercentage;
  let deltaPercentageStr;

  const now = parseInt(Date.now() / 1000);
  const timeThreshold = now - 24 * 60 * 60;

  if (priceData) {
    for (let i = priceData.length - 1;i > 0;i--) {
      const price = priceData[i];
      if (price.time < timeThreshold) {
        break;
      }
      if (!low) {
        low = price.low;
      }
      if (!high) {
        high = price.high;
      }

      if (price.high > high) {
        high = price.high;
      }
      if (price.low < low) {
        low = price.low;
      }
      deltaPrice = price.open;
    }
  }
  if (deltaPrice && currentAveragePrice) {
    const average = parseFloat(formatAmount(currentAveragePrice, USD_DECIMALS, tokenDecimals));
    delta = average - deltaPrice;
    deltaPercentage = (delta * 100) / average;
    if (deltaPercentage > 0) {
      deltaPercentageStr = `+${deltaPercentage.toFixed(2)}%`;
    } else {
      deltaPercentageStr = `${deltaPercentage.toFixed(2)}%`;
    }
    if (deltaPercentage === 0) {
      deltaPercentageStr = "0.00";
    }
  }

  if (!chartToken) {
    return null;
  }

  const onSelectToken = useCallback((token) => {
    const tmp = getTokenInfo(infoTokens, token.address);
    setChartToken(tmp);
    setToTokenAddress(swapOption, token.address);
  }, [infoTokens]);

  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");

  const [shortCollateralAddress, setShortCollateralAddress] = useLocalStorageByChainId(
    chainId,
    "Short-Collateral-Address",
    getTokenBySymbol(chainId, defaultCollateralSymbol)?.address,
  );
  // const tokensArr = useSelector(state => {
  //   return state.app.tokensArr || [];
  // })

  const shortCollateralToken = getTokenInfo(infoTokens, shortCollateralAddress);
  const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);
  const [interestProgressVal, setInterestProgressVal] = useState(0);
  useEffect(() => {
    const val_long = toTokenInfo?.longSize?.gt(0) ? Number(ethers.utils.formatUnits(toTokenInfo?.longSize, USD_DECIMALS)) : 0
    const val_short = toTokenInfo?.shortSize?.gt(0) ? Number(ethers.utils.formatUnits(toTokenInfo?.shortSize, USD_DECIMALS)) : 0
    if (val_long == 0) {
      setInterestProgressVal(0)
    }
    if (val_short == 0) {
      setInterestProgressVal(100)
    }

    setInterestProgressVal(val_long / (val_short + val_long) * 100)
  }, [toTokenInfo]);

  let hasZeroBorrowFee = false;
  const [borrowFeeText_long, setBorrowFeeText_long] = useState(0);
  const [premiumText_long, setPremiumText_long] = useState(0);
  const [borrowFeeText_short, setBorrowFeeText_short] = useState(0);
  const [premiumText_short, setPremiumText_short] = useState(0);
  useEffect(() => {
    if (toTokenInfo) {
      setBorrowFeeText_long(formatAmount(toTokenInfo?.fundingRate, 4, 4) + "%");
      setPremiumText_long(formatAmount(toTokenInfo?.longRatePerHour, 4, 4) + "%");

    }
    if (toTokenInfo && shortCollateralToken) {
      setBorrowFeeText_short(formatAmount(shortCollateralToken?.fundingRate, 4, 4) + "%");
      setPremiumText_short(formatAmount(toTokenInfo?.shortRatePerHour, 4, 4) + "%");
      if (shortCollateralToken?.fundingRate?.eq(0)) {
        // hasZeroBorrowFee = true
      }
    }
  }, [shortCollateralToken, toTokenInfo]);
  useEffect(() => {
    const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);
    const isStable = fromTokenInfo?.isStable
    if (isStable) {
      setShortCollateralAddress(fromTokenInfo.address)
    }
  }, [fromTokenAddress, isShort]);

  const price_gns_arr = useAppSelector((state) => {
    return state.http.price_gns || [];
  });
  const price_gns_obj = price_gns_arr.reduce((pre, curr) => {
    pre[curr.symbol.toLocaleUpperCase()] = curr;
    return pre;
  }, {});

  const currentPositions = useMemo(() => {
    if (!positions || !chartToken) {
      return [];
    }
    return positions
      .filter((p) => compareAddress(p.indexToken.address, chartToken.address))
      .map((position) => {
        const longOrShortText = position.isLong ? t`Long` : t`Short`;
        return {
          open: {
            price: parseFloat(formatAmount(position.averagePrice, USD_DECIMALS, 2)),
            title: t`Open ${position.indexToken.symbol} ${longOrShortText}`,
          },
          liquidation: {
            price: parseFloat(formatAmount(getLiquidationPrice2(position), USD_DECIMALS, 2)),
            title: t`Liq. ${position.indexToken.symbol} ${longOrShortText}`,
          },
        };
      });
  }, [chartToken, positions]);
  const [showChart, setShowChart] = useState(true);
  useEffect(() => {
    setShowChart(isVerySmallScreen ? false : true)
  }, [isVerySmallScreen])
  const chartLines = useMemo(() => {
    const lines = [];
    if (currentOrders.length > 0) {
      lines.push(...currentOrders);
    }

    if (currentPositions.length > 0) {
      currentPositions.forEach((position) => {
        lines.push(position.open);
        lines.push(position.liquidation);
      });
    }

    return lines;
  }, [currentOrders, currentPositions]);
  return (
    <div className="ExchangeChart tv" ref={ref}>
      <div className="ExchangeChart-top App-box App-box-border">
        <div className="ExchangeChart-top-inner">
          <div className="tratad_top">
            <div className="ExchangeChart-title">
              <ChartTokenSelector
                dlpName={dlpName}
                fromTokenAddress={fromTokenAddress}
                chainId={chainId}
                selectedToken={chartToken}
                swapOption={swapOption}
                infoTokens={infoTokens}
                onSelectToken={onSelectToken}
                className="chart-token-selector"
              />
            </div>
            <div className="price_ad">
              <div className={cx({ positive: !currentPiceColor, negative: currentPiceColor }, 'ExchangeChart-main-price')}>
                {chartToken.maxPrice && formatAmount(chartToken.maxPrice, USD_DECIMALS, tokenDecimals, true)}
              </div>
              <div className={cx({ positive: price_gns_obj[chartToken.symbol]?.priceChangePercent > 0, negative: price_gns_obj[chartToken.symbol]?.priceChangePercent < 0 }, 'upliver ')}>
                {formatAmount2(price_gns_obj[chartToken.symbol]?.priceChangePercent, 2, true)}%
              </div>
            </div>
          </div>

          <div className="tratad_top tratad_toph5">
            <div className="ExchangeChart-additional-info h5info">
              <div className="ExchangeChart-info-label">24h High</div>
              <div className=" color4 mt-2">
                {!high && "-"}
                {high && numberWithCommas(high.toFixed(tokenDecimals))}
              </div>
            </div>
            <div className="ExchangeChart-additional-info h5info">
              <div className="ExchangeChart-info-label">24h Low</div>
              <div className="color4 mt-2">
                {!low && "-"}
                {low && numberWithCommas(low.toFixed(tokenDecimals))}
              </div>
            </div>
            <div className="ExchangeChart-additional-info h5info">
              <div className="ExchangeChart-info-label">Open Interest</div>
              <Column className=" color4">
                <Row justify="space-between" pt={'2px'} gap={'50px'}>
                  <div className="color18">${toTokenInfo?.longSize > 0 ? computeNumUnit(ethers.utils.formatUnits(toTokenInfo?.longSize, USD_DECIMALS), 1) : 0}</div>
                  <div className="color24">${toTokenInfo?.shortSize > 0 ? computeNumUnit(ethers.utils.formatUnits(toTokenInfo?.shortSize, USD_DECIMALS), 1) : 0}</div>
                </Row>
                {
                  toTokenInfo?.longSize?.eq(0) && toTokenInfo?.shortSize?.eq(0)
                    ?
                    <div className="mt-2" style={{
                      width: '118px',
                      height: '4px',
                      borderRadius: '4px',
                      background: '#FFF872',
                    }}></div>
                    :
                    <Box pt={'0px'}>
                      <InterestProgress style={{ display: "flex" }} disabled
                        aria-label="Always visible"
                        valueLabelDisplay="none"
                        value={interestProgressVal ?? 0} />
                    </Box>
                }

              </Column>
            </div>
            <div className="ExchangeChart-additional-info h5info">
              <div className="ExchangeChart-info-label">
                <Tooltips
                  position="center-bottom"
                  handleClassName=""
                  renderContent={
                    <>
                      {hasZeroBorrowFee ?
                        (
                          <TooltipText text={<div>
                            {isLong && "There are more shorts than longs, Funding Fees for longing is currently zero"}
                            {isShort && "There are more longs than shorts, Funding Fees for shorting is currently zero"}
                          </div>} />
                        )
                        :
                        (
                          <TooltipText text={
                            <>
                              <div className="">Funding fees hourly rate, charged per block.</div>
                              <div className="">
                                The funding fee is calculated as (assets borrowed) / (total assets in pool) * 0.01% per hour.
                              </div>
                              {isShort &&
                                <div className="">
                                  You can change the "Profits In" token above to find lower fees
                                </div>
                              }
                            </>
                          } />
                        )
                      }
                    </>
                  }
                >
                  <span className="toolBottom2 color14">Funding Rate</span>
                </Tooltips>
              </div>
              <Row align="center" className="mt-4">
                <Row className="">
                  <div className="color18 font-weight-b">L</div>
                  <div className="ml-4">{borrowFeeText_long}</div>
                </Row>
                <Row className="ml-16">
                  <div className="color24 font-weight-b">S</div>
                  <div className="ml-4">{borrowFeeText_short}</div>
                </Row>
              </Row>
            </div>


          </div>
        </div>
      </div>
      {
        isVerySmallScreen &&
        <Box display={'flex'} justifyContent={'center'} alignItems={"center"} textAlign={'center'} p={'16px'} gap={'8px'} borderBottom="1px solid rgba(255,255,255,0.16)" onClick={() => {
          setShowChart(!showChart);
        }} >
          {

            showChart ? <Expend /> : <Default />

          }

          <ThemedText.Text1Medium fontSize={14} style={{ color: 'rgba(255,255,255,0.54' }} >View Chart</ThemedText.Text1Medium>
        </Box>
      }


      {
        showChart ?
          <div className="ExchangeChart-bottom App-box App-box-border">
            <div>
              {availableNetworksForChart.includes(Number(chainId || 0)) && chartToken.symbol && chainId ? (
                <TVChartContainer
                  chartLines={chartLines}
                  savedShouldShowPositionLines={savedShouldShowPositionLines}
                  symbol={chartToken.symbol}
                  chainId={chainId}
                  onSelectToken={onSelectToken}
                  dataProvider={dataProvider.current}
                />
              ) : (
                <p className="ExchangeChart-error">Sorry, chart is not supported on this network yet.</p>
              )}
            </div>
          </div>
          :
          <></>
      }

    </div >
  );
}
