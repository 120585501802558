import { Box } from "@mui/material";
import styled from "styled-components/macro";
import { ReactComponent as DYNA } from "src/assets/images/DYNA.svg";
import { ReactComponent as EsDYNA } from "src/assets/images/esDYNA.svg";
import { ReactComponent as USDB } from "src/assets/images/tokens/USDB.svg";
import { ThemedText } from "src/theme/text";
import { Trans } from "@lingui/macro";
import { formatAmount2 } from "src/helpers/Helpers";
import { useGetDynaPrice } from "src/hooks/get_price_dyna";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { BN } from "src/utils/bn";
import {
  useBalance_DYNA,
  useBalance_esDYNA,
  useBalance_usdc,
} from "src/hooks/useQueryEarn";
import { ReactComponent as ADDtoken } from "src/assets/images/AddToken.svg";
import useAddTokenToMetamask from "src/hooks/useAddTokenToMetamask";
import { useWeb3Context } from "src/hooks";
import { addresses as ADDRESS } from "src/configs/constants";
const TokenListBox = styled(Box)`
  .balance {
    padding: 18px 8px 10px;
    border-bottom: 1px solid ${({ theme }) => theme.bg3};
  }
  .tokenItem {
    padding: 18px 8px 14px;
    :not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.bg3};
    }
  }
  .balance,
  .tokenItem {
    padding-left: 10px;
    padding-right: 6px;
  }
`;

export default function TokenList() {
  const DYNAPrice = useGetDynaPrice();
  const DYNABalance = useBalance_DYNA();
  const esDYNABalance = useBalance_esDYNA();
  const usdcBalance = useBalance_usdc();
  const { addToken } = useAddTokenToMetamask();
  const { chainID } = useWeb3Context();
  const address_DYNA = ADDRESS[chainID].DYNA;
  const address_esDYNA = ADDRESS[chainID].esDYNA;
  const address_USDB = ADDRESS[chainID].USDB;
  const Add = (
    address: string,
    symbol: string,
    decimals: number,
    url: string
  ) => {
    addToken(address, symbol, decimals, url);
  };
  const tokensArr = useSelector((state: any) => {
    return state?.app?.tokensArr || [];
  });

  const totalAmount = useMemo(() => {
    if (
      DYNABalance == undefined ||
      esDYNABalance == undefined ||
      DYNAPrice == undefined ||
      usdcBalance == undefined ||
      tokensArr.length == 0
    )
      return;
    const dynaAmount = DYNABalance.times(DYNAPrice);
    const esdynaAmount = esDYNABalance.times(DYNAPrice);
    const dlpAmount = tokensArr.reduce((pre, curr) => {
      const { dlpBalance, price } = curr;
      pre = pre.plus(BN(dlpBalance).times(price));
      return pre;
    }, BN(0));
    return dlpAmount
      .plus(dynaAmount)
      .plus(usdcBalance)
      .plus(esdynaAmount)
      .toFixed();
  }, [DYNABalance, esDYNABalance, DYNAPrice, usdcBalance, tokensArr]);

  return (
    <TokenListBox className="tokenList">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="balance"
      >
        <ThemedText.Text4Bold>
          <Trans>Wallet</Trans>
        </ThemedText.Text4Bold>
        <ThemedText.Text4Bold>
          ${formatAmount2(totalAmount, 2, true)}
        </ThemedText.Text4Bold>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="tokenItem"
      >
        <Box display="flex" alignItems="center">
          <DYNA width={48} height={48} />
          <ThemedText.Text9Bold ml="12px" mr="8px" fontSize={16}>
            DYNA
          </ThemedText.Text9Bold>
          <ADDtoken
            className="pointer"
            // onClick={() => {
            //   Add(
            //     address_DYNA,
            //     "DYNA",
            //     18,
            //     "https://app.pinnako.io/img/DYNA.svg"
            //   );
            // }}
          />
        </Box>
        <div className="text-right">
          <ThemedText.Text9Bold fontSize={16}>
            {formatAmount2(DYNABalance, 2, true)}
          </ThemedText.Text9Bold>
          <ThemedText.Text4 fontSize={14}>
            $
            {formatAmount2(
              DYNABalance?.times(DYNAPrice ? DYNAPrice : 1),
              2,
              true
            )}
          </ThemedText.Text4>
        </div>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="tokenItem"
      >
        <Box display="flex" alignItems="center">
          <EsDYNA width={48} height={48} />
          <ThemedText.Text9Bold ml="12px" mr="8px" fontSize={16}>
            esDYNA
          </ThemedText.Text9Bold>
          <ADDtoken
            className="pointer"
            // onClick={() => {
            //   Add(
            //     address_esDYNA,
            //     "esDYNA",
            //     18,
            //     "https://app.pinnako.io/img/esDYNA.svg"
            //   );
            // }}
          />
        </Box>
        <div className="text-right">
          <ThemedText.Text9Bold fontSize={16}>
            {formatAmount2(esDYNABalance, 2, true)}
          </ThemedText.Text9Bold>
          <ThemedText.Text4 fontSize={14}>
            $
            {formatAmount2(
              esDYNABalance?.times(DYNAPrice ? DYNAPrice : 1),
              2,
              true
            )}
          </ThemedText.Text4>
        </div>
      </Box>
      {/* {tokensArr.length > 0 ? (
        tokensArr.map((item, i) => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="tokenItem"
            key={i}
          >
            <Box display="flex" alignItems="center">
              <img src={item.icon} width={48} height={48} />
              <ThemedText.Text9Bold ml="12px" mr="8px" fontSize={16}>
                {item?.name}
              </ThemedText.Text9Bold>
              <ADDtoken
                className="pointer"
                // onClick={() => {
                //   Add(
                //     item.address,
                //     item.name,
                //     18,
                //     "https://app.pinnako.io/img/PLP.svg"
                //   );
                // }}
              />
            </Box>
            <div className="text-right">
              <ThemedText.Text9Bold fontSize={16}>
                {formatAmount2(item?.dlpBalance * 1, 2, true)}
              </ThemedText.Text9Bold>
              <ThemedText.Text4 fontSize={14}>
                ${formatAmount2(item?.dlpBalance * 1 * item?.price, 2, true)}
              </ThemedText.Text4>
            </div>
          </Box>
        ))
      ) : (
        <Skeleton width="100%" height={60} style={{ borderRadius: "10px" }} />
      )} */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="tokenItem"
      >
        <Box display="flex" alignItems="center">
          <USDB width={48} height={48} />
          <ThemedText.Text9Bold ml="12px" mr="8px" fontSize={16}>
            USDB
          </ThemedText.Text9Bold>
          <ADDtoken
            className="pointer"
            // onClick={() => {
            //   Add(
            //     address_USDB,
            //     "USDB",
            //     6,
            //     "https://app.pinnako.io/img/USDB.svg"
            //   );
            // }}
          />
        </Box>
        <div className="text-right">
          <ThemedText.Text9Bold fontSize={16}>
            {formatAmount2(usdcBalance, 2, true)}
          </ThemedText.Text9Bold>
          <ThemedText.Text4 fontSize={14}>
            ${formatAmount2(usdcBalance, 2, true)}
          </ThemedText.Text4>
        </div>
      </Box>
    </TokenListBox>
  );
}
