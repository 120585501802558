import { FunctionFragment, Interface } from "@ethersproject/abi";
import { BigNumber } from "@ethersproject/bignumber";
import {
  Call,
  CallResult,
  CallState,
  ListenerOptions,
} from "@uniswap/redux-multicall";
import { useEffect, useMemo } from "react";
import { useWeb3Context } from "src/hooks";
import { useAppDispatch, useAppSelector } from "src/state/hooks";
import useBlockNumber from "./useBlockNumber";
export type {
  CallStateResult,
  CallState,
  CallResult,
} from "@uniswap/redux-multicall";
import multicall, { getBlocksPerFetchForChainId } from "../state/multicall";

export function parseCallKey(callKey: string): Call {
  const pcs = callKey.split("-");
  if (![2, 3].includes(pcs.length)) {
    throw new Error(`Invalid call key: ${callKey}`);
  }
  return {
    address: pcs[0],
    callData: pcs[1],
    ...(pcs[2] ? { gasRequired: Number.parseInt(pcs[2]) } : {}),
  };
}

const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
const LOWER_HEX_REGEX = /^0x[a-f0-9]*$/;
export function toCallKey(call: Call): string {
  if (!ADDRESS_REGEX.test(call.address)) {
    throw new Error(`Invalid address: ${call.address}`);
  }
  if (!LOWER_HEX_REGEX.test(call.callData)) {
    throw new Error(`Invalid hex: ${call.callData}`);
  }
  let key = `${call.address}-${call.callData}`;
  if (call.gasRequired) {
    if (!Number.isSafeInteger(call.gasRequired)) {
      throw new Error(`Invalid number: ${call.gasRequired}`);
    }
    key += `-${call.gasRequired}`;
  }
  return key;
}
export interface Result extends ReadonlyArray<any> {
  readonly [key: string]: any;
}

type MethodArg = string | number | BigNumber;
type MethodArgs = Array<MethodArg | MethodArg[]>;

export type OptionalMethodInputs =
  | Array<MethodArg | MethodArg[] | undefined>
  | undefined;

function isMethodArg(x: unknown): x is MethodArg {
  return (
    BigNumber.isBigNumber(x) || ["string", "number"].indexOf(typeof x) !== -1
  );
}

function isValidMethodArgs(x: unknown): x is MethodArgs | undefined {
  return (
    x === undefined ||
    (Array.isArray(x) &&
      x.every(
        (xi) => isMethodArg(xi) || (Array.isArray(xi) && xi.every(isMethodArg))
      ))
  );
}

const INVALID_RESULT: CallResult = {
  valid: false,
  blockNumber: undefined,
  data: undefined,
};

// the lowest level call for subscribing to contract data
function useCallsData(
  calls: (Call | undefined)[],
  options: ListenerOptions
): CallResult[] {
  const { chainID: chainId } = useWeb3Context();
  const callResults = useAppSelector((state) => state.multicall.callResults);
  const dispatch = useAppDispatch();
  const serializedCallKeys: string = useMemo(
    () =>
      JSON.stringify(
        calls
          ?.filter((c): c is Call => Boolean(c))
          ?.map(toCallKey)
          ?.sort() ?? []
      ),
    [calls]
  );

  // update listeners when there is an actual change that persists for at least 100ms
  useEffect(() => {
    const callKeys: string[] = JSON.parse(serializedCallKeys);
    if (!chainId || callKeys.length === 0) return undefined;
    const calls = callKeys.map((key) => parseCallKey(key));
    dispatch(
      multicall.actions.addMulticallListeners({
        chainId,
        calls,
        options,
      })
    );

    return () => {
      dispatch(
        multicall.actions.removeMulticallListeners({
          chainId,
          calls,
          options,
        })
      );
    };
  }, [chainId, dispatch, options, serializedCallKeys]);

  return useMemo(
    () =>
      calls.map<CallResult>((call) => {
        if (!chainId || !call) return INVALID_RESULT;
        const result = callResults[chainId]?.[toCallKey(call)];
        let data;
        if (result?.data && result?.data !== "0x") {
          data = result.data;
        }
        return { valid: true, data, blockNumber: result?.blockNumber };
      }),
    [callResults, calls, chainId]
  );
}

const INVALID_CALL_STATE: CallState = {
  valid: false,
  result: undefined,
  loading: false,
  syncing: false,
  error: false,
};
const LOADING_CALL_STATE: CallState = {
  valid: true,
  result: undefined,
  loading: true,
  syncing: true,
  error: false,
};

function toCallState(
  callResult: CallResult | undefined,
  contractInterface: Interface | undefined,
  fragment: FunctionFragment | undefined,
  latestBlockNumber: number | undefined
): CallState {
  if (!callResult) return INVALID_CALL_STATE;
  const { valid, data, blockNumber } = callResult;
  if (!valid) return INVALID_CALL_STATE;
  if (valid && !blockNumber) return LOADING_CALL_STATE;
  if (!contractInterface || !fragment || !latestBlockNumber)
    return LOADING_CALL_STATE;
  const success = data && data.length > 2;
  const syncing = (blockNumber ?? 0) < latestBlockNumber;
  let result: Result | undefined = undefined;
  if (success && data) {
    try {
      result = contractInterface.decodeFunctionResult(fragment, data);
    } catch (error) {
      return {
        valid: true,
        loading: false,
        error: true,
        syncing,
        result,
      };
    }
  }
  return {
    valid: true,
    loading: false,
    syncing,
    result: result,
    error: !success,
  };
}
export function useMultipleContractMultipleData(
  addresses: (string | undefined)[],
  contractInterface: Interface,
  methodName: string,
  callInputs?: OptionalMethodInputs[][],
  options?: ListenerOptions,
  gasRequired?: number
): CallState[][] {
  const fragment = useMemo(
    () => contractInterface.getFunction(methodName),
    [contractInterface, methodName]
  );
  const { chainID } = useWeb3Context();
  const listenerOptions: ListenerOptions = useMemo(
    () => ({
      blocksPerFetch: getBlocksPerFetchForChainId(chainID),
      ...options,
    }),
    [chainID, options]
  );

  const calls = useMemo(() => {
    return addresses.map((address, i) => {
      const passesChecks = address && contractInterface && fragment;

      if (!passesChecks) {
        return [];
      }

      return callInputs
        ? callInputs[i].map<Call | undefined>((inputs) => {
            const callData: string | undefined = isValidMethodArgs(inputs)
              ? contractInterface.encodeFunctionData(fragment, inputs)
              : undefined;
            return address && callData
              ? {
                  address,
                  callData,
                  ...(gasRequired ? { gasRequired } : {}),
                }
              : undefined;
          })
        : [];
    });
  }, [addresses, callInputs, contractInterface, fragment, gasRequired]);
  const callResults = useCallsData(calls.flat(), listenerOptions);
  const latestBlockNumber = useBlockNumber();
  return useMemo(() => {
    const callInputLengths = callInputs
      ? callInputs.map((inputArray) => inputArray.length)
      : [];
    const unformatedResults = callResults.map((result) =>
      toCallState(result, contractInterface, fragment, latestBlockNumber)
    );

    return callInputLengths.map((length) => {
      let j = 0;
      const indexElements: any = [];
      while (j < length) {
        indexElements.push(unformatedResults.shift());
        j++;
      }
      return indexElements;
    });
  }, [callInputs, callResults, contractInterface, fragment, latestBlockNumber]);
}
