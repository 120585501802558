import { ApolloClient, InMemoryCache } from "@apollo/client";
import { useMemo } from "react";
import { SupportedChainId } from "src/constants/chainENV/chains.base";
import { useWeb3Context } from "src/hooks";
import { dynaGraphClient_arb_dev, dynaGraphClient_blast_test } from "../common";

export const ZKdlp1Url = `https://graph.pinnako.io/subgraphs/name/ekmanss/stats`;
export const ZKdlp2Url = `https://graph.pinnako.io/subgraphs/name/ekmanss/stats`;

export const blast_test_TVL = `https://api.studio.thegraph.com/query/42478/dyna_blast_sepolia_tvl/version/latest`;
export const arb_test_TVL = `https://api.thegraph.com/subgraphs/name/metaverseblock/pnk_sepolia_tvl`;

export function createClient(uri: string) {
  try {
    return new ApolloClient({
      uri,
      cache: new InMemoryCache(),
    });
  } catch (error) {}
}

export const graphChainIDUrl = {
  [SupportedChainId.BLAST_TEST]: dynaGraphClient_blast_test,
  [SupportedChainId.ARB_TEST]: dynaGraphClient_arb_dev,
};

export const graphTVLUrl = {
  [SupportedChainId.BLAST_TEST]: blast_test_TVL,
  [SupportedChainId.ARB_TEST]: arb_test_TVL,
};

export function useVolumeChartGraphUrl() {
  const { chainID }: { chainID: SupportedChainId } = useWeb3Context();
  return useMemo(() => {
    return createClient(graphChainIDUrl[chainID]);
  }, [chainID]);
}

export function useTVLGraphUrl() {
  const { chainID }: { chainID: SupportedChainId } = useWeb3Context();
  return useMemo(() => {
    return createClient(graphTVLUrl[chainID]);
  }, [chainID]);
}
