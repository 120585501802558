import Waiting from "../assets/images/loading/waitingForConfirmation.svg";
import Processing from "../assets/images/loading/processingTransaction.svg";
import Fail from "../assets/images/failMsg.svg";
import Success from "../assets/images/successMsg.svg";
import { ReactComponent as ViewIcon } from "../assets/images/view.svg";
import { ReactComponent as CloseIcon } from "../assets/images/x.svg";
import { getExplorerUrl, helperToast } from './Helpers'
import { toast } from "react-toastify";
import { Box } from '@material-ui/core'
import styled from "styled-components";
import { useWeb3Context } from "src/hooks";

const handleClose = () => {
  toast.dismiss();
}

const ViewContainer = styled.div`
  .text{
    margin-right: 6px;
    text-decoration-line: underline;
    color: #7E9FFF;
  }
`;

const ViewBox = (props) => {
  const { hash } = props;
  const { chainID } = useWeb3Context();
  const txUrl = getExplorerUrl(chainID) + "tx/" + hash;

  return <ViewContainer>
    <a href={txUrl} target="_blank">
      <Box display="flex" alignItems="center">
        <div className="text font-14 font-weight-b">View in Explorer </div>
        <ViewIcon />
      </Box>
    </a>
  </ViewContainer>
}

export const toastError = (message, hash) => {
  helperToast.error(
    <div className="toastBox">
      <Box display="flex" alignItems="center" justifyContent="space-between" className='toastInfo'>
        <Box display="flex" alignItems="center" className="flex-1">
          <img src={Fail} height={30} />
          <div className="ml-16">
            <div className='color28 font-16 font-weight-b'>Warning</div>
          </div>
        </Box>
        <Box ml="12px" mr="6px">
          <CloseIcon className="close pointer" onClick={handleClose} />
        </Box>
      </Box>
      <Box mt="12px" className="message font-14 color14">
        <div className=''>{message}</div>
      </Box>
      {
        hash && <Box mt="12px" >
          <ViewBox hash={hash} />
        </Box>
      }
    </div>
  );
}

export const toastSuccess = (message, hash) => {
  helperToast.success(
    <div className="toastBox">
      <Box display="flex" alignItems="center" justifyContent="space-between" className='toastInfo'>
        <Box display="flex" alignItems="center" className="flex-1">
          <img src={Success} height={30} />
          <div className="ml-16">
            <div className='color28 font-16 font-weight-b'>Well done!</div>
          </div>
        </Box>
        <Box ml="12px" mr="6px">
          <CloseIcon className="close pointer" onClick={handleClose} />
        </Box>
      </Box>
      <Box mt="12px" className="message font-14 color14">
        <div className=''>{message}</div>
      </Box>
      {
        hash && <Box mt="12px" >
          <ViewBox hash={hash} />
        </Box>
      }
    </div>
  );
}

export const toastWaiting = (message) => {
  helperToast.waiting(
    <div className="toastBox">
      <Box display="flex" alignItems="center" justifyContent="space-between" className='toastInfo'>
        <Box display="flex" alignItems="center" className="flex-1">
          <img src={Waiting} height={30} />
          <div className="ml-16">
            <div className='color28 font-16 font-weight-b'>Wait for Confirmation</div>
          </div>
        </Box>
        <Box ml="12px" mr="6px">
          <CloseIcon className="close pointer" onClick={handleClose} />
        </Box>
      </Box>
      <Box mt="12px" className="message font-14 color14">
        <div className=''>{message}</div>
      </Box>
    </div>
  )
}

export const toastProcessing = (message) => {
  helperToast.processing(
    <div className="toastBox">
      <Box display="flex" alignItems="center" justifyContent="space-between" className='toastInfo'>
        <Box display="flex" alignItems="center" className="flex-1">
          <img src={Processing} height={30} />
          <div className="ml-16">
            <div className='color28 font-16 font-weight-b'>Processing Transaction</div>
          </div>
        </Box>
        <Box ml="12px" mr="6px">
          <CloseIcon className="close pointer" onClick={handleClose} />
        </Box>
      </Box>
      <Box mt="12px" className="message font-14 color14">
        <div className=''>{message}</div>
      </Box>
    </div>
  )
}
