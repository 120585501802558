import { Box, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { ThemedText } from "src/theme/text";
import { Trans } from "@lingui/macro";
import Dots from "src/components/Dots";
import { useBasicInfo } from "src/hooks/useQueryDID";
import { formatAmount2 } from "src/helpers/Helpers";
import { useState } from "react";
import BoostDialog from "../../BoostDialog";

const PointsBox = styled.div`
  @media only screen and (max-width: 1080px) {
  }
  @media only screen and (max-width: 768px) {
  }
`;

export default function PointsInfo() {
  const isSmallScreen = useMediaQuery("(max-width: 1080px)");
  const baseInfo = useBasicInfo();
  const [showBoost, setShowBoost] = useState<boolean>(false);
  const handleClose = () => {
    setShowBoost(false);
  };

  return (
    <PointsBox className="points-view">
      <Box display="flex" alignItems="center" className="">
        <Dots bgColor="#FFFD02" />
        <ThemedText.Text4Bold ml="8px">
          <Trans>Points</Trans>
        </ThemedText.Text4Bold>
      </Box>
      <Box display="flex" alignItems="center" mt="12px" className="">
        <ThemedText.Text1Bold fontSize={18}>
          {formatAmount2(baseInfo?.score, 0, true)}
        </ThemedText.Text1Bold>
        {/* <Box display="inline-flex" ml="10px" className="pointer">
          <PointsTooltip />
        </Box> */}
      </Box>
      {/* <Box mt="4px">
          <BoostBtn
            onClick={() => {
              setShowBoost(true);
            }}
          >
            <BoostIcon />
            <Box ml="8px">BOOST</Box>
          </BoostBtn>
        </Box> */}
      <BoostDialog open={showBoost} handleClose={handleClose} />
    </PointsBox>
  );
}
