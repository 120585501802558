import {
  LAST_BAR_REFRESH_INTERVAL,
  SUPPORTED_RESOLUTIONS,
} from "src/configs/tradingview";
import {
  getLimitChartPricesFromStats,
  timezoneOffset,
} from "src/domain/prices";
import { CHART_PERIODS, USD_DECIMALS } from "src/lib/legacy";
import { formatAmount } from "src/lib/numbers";
import { Bar } from "./types";
import { formatTimeInBarToMs, getCurrentCandleTime } from "./utils";
import {
  fillBarGaps,
  getCurrentPriceOfToken,
  getStableCoinPrice,
  getTokenChartPrice,
} from "./requests";
import { BigNumberish } from "ethers";
import { PeriodParams } from "src/charting_library";
import { getTokenBySymbol } from "src/configs/Tokens";

const initialHistoryBarsInfo = {
  period: "",
  data: [],
  ticker: "",
};

export class TVDataProvider {
  lastBar: Bar | null;
  startTime: number;
  lastTicker: string;
  lastPeriod: string;
  barsInfo: {
    period: string;
    data: Bar[];
    ticker: string;
  };

  constructor() {
    this.lastBar = null;
    this.startTime = 0;
    this.lastTicker = "";
    this.lastPeriod = "";
    this.barsInfo = initialHistoryBarsInfo;
  }

  async getCurrentPriceOfToken(
    chainId: number,
    ticker: string
  ): Promise<BigNumberish> {
    return getCurrentPriceOfToken(chainId, ticker);
  }

  async getTokenLastBars(
    chainId: number,
    ticker: string,
    period: string,
    limit: number
  ): Promise<Bar[]> {
    return getLimitChartPricesFromStats(chainId, ticker, period, limit);
  }
  async getTokenChartPrice(
    chainId: number,
    ticker: string,
    period: string
  ): Promise<Bar[]> {
    return getTokenChartPrice(chainId, ticker, period);
  }

  async getTokenHistoryBars(
    chainId: number,
    ticker: string,
    period: string,
    periodParams: PeriodParams,
    shouldRefetchBars: boolean
  ): Promise<Bar[]> {
    const barsInfo = this.barsInfo;
    if (
      !barsInfo.data.length ||
      barsInfo.ticker !== ticker ||
      barsInfo.period !== period ||
      shouldRefetchBars
    ) {
      try {
        const bars = await this.getTokenChartPrice(chainId, ticker, period);
        const filledBars = fillBarGaps(bars, CHART_PERIODS[period]);
        const currentCandleTime = getCurrentCandleTime(period);
        const lastCandleTime = currentCandleTime - CHART_PERIODS[period];
        const lastBar = filledBars[filledBars.length - 1];
        if (
          lastBar.time === currentCandleTime ||
          lastBar.time === lastCandleTime
        ) {
          this.lastBar = { ...lastBar, ticker };
        }
        this.barsInfo.data = filledBars;
        this.barsInfo.ticker = ticker;
        this.barsInfo.period = period;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.barsInfo = initialHistoryBarsInfo;
      }
    }

    const { from, to, countBack } = periodParams;
    const toWithOffset = to + timezoneOffset;
    const fromWithOffset = from + timezoneOffset;
    const bars = barsInfo.data.filter(
      (bar) => bar.time > fromWithOffset && bar.time <= toWithOffset
    );

    // if no bars returned, return empty array
    if (!bars.length) {
      return [];
    }

    // if bars are fewer than countBack, return all of them
    if (bars.length < countBack) {
      return bars;
    }

    // if bars are more than countBack, return latest bars
    return bars.slice(bars.length - countBack, bars.length);
  }

  async getBars(
    chainId: number,
    ticker: string,
    resolution: string,
    isStable: boolean,
    periodParams: PeriodParams,
    shouldRefetchBars: boolean
  ) {
    const period = SUPPORTED_RESOLUTIONS[resolution];
    const { from, to } = periodParams;

    try {
      const bars = isStable
        ? getStableCoinPrice(period, from, to)
        : await this.getTokenHistoryBars(
            chainId,
            ticker,
            period,
            periodParams,
            shouldRefetchBars
          );

      return bars.map(formatTimeInBarToMs);
    } catch {
      throw new Error("Failed to get history bars");
    }
  }

  async getLastBar(chainId: number, ticker: string, period: string) {
    if (!ticker || !period || !chainId) {
      throw new Error(
        "Invalid input. Ticker, period, and chainId are required parameters."
      );
    }
    const currentTime = Date.now();

    if (
      currentTime - this.startTime > LAST_BAR_REFRESH_INTERVAL ||
      this.lastTicker !== ticker ||
      this.lastPeriod !== period
    ) {
      // TODO  getTokenLastBars
      const prices = await this.getTokenLastBars(chainId, ticker, period, 1);
      if (prices?.length) {
        // @ts-ignore
        const lastBar = prices[0];
        const currentCandleTime = getCurrentCandleTime(period);
        const lastCandleTime = currentCandleTime - CHART_PERIODS[period];
        if (
          lastBar.time === currentCandleTime ||
          lastBar.time === lastCandleTime
        ) {
          this.lastBar = { ...lastBar, ticker };
          this.startTime = currentTime;
          this.lastTicker = ticker;
          this.lastPeriod = period;
        }
      }
      this.startTime = currentTime;
      this.lastTicker = ticker;
      this.lastPeriod = period;
    }
    return this.lastBar;
  }

  async getLiveBar(chainId: number, ticker: string, resolution: string) {
    const period = SUPPORTED_RESOLUTIONS[resolution];
    if (!ticker || !period || !chainId) return;

    const currentCandleTime = getCurrentCandleTime(period);
    try {
      this.lastBar = await this.getLastBar(chainId, ticker, period);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    if (!this.lastBar) return;

    const currentPrice = await this.getCurrentPriceOfToken(chainId, ticker);
    const symbolInfo = getTokenBySymbol(chainId, ticker);
    const averagePriceValue = parseFloat(
      formatAmount(currentPrice, USD_DECIMALS, symbolInfo.tokenDecimals)
    );
    if (
      this.lastBar.time &&
      currentCandleTime === this.lastBar.time &&
      ticker === this.lastBar.ticker
    ) {
      return {
        ...this.lastBar,
        close: averagePriceValue,
        high: Math.max(this.lastBar.open, this.lastBar.high, averagePriceValue),
        low: Math.min(this.lastBar.open, this.lastBar.low, averagePriceValue),
        ticker,
      };
    } else {
      const newBar = {
        time: currentCandleTime,
        open: this.lastBar.close,
        close: averagePriceValue,
        high: Math.max(this.lastBar.close, averagePriceValue),
        low: Math.min(this.lastBar.close, averagePriceValue),
        ticker,
      };
      this.lastBar = newBar;
      return this.lastBar;
    }
  }
}
