import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const DisconnectBtn = styled(Button)({
  height: "36px",
  lineHeight: "36px",
  maxHeight: "36px",
  padding: "0px 8px",
  color: "rgba(255, 255, 255, 0.54)",
  fontSize: 14,
  fontFamily: "Gilroy-Medium",
  textTransform: "uppercase",
  background: "rgba(250, 83, 65, 0.1)",
  border: "none",
  borderRadius: "8px",
  "&:hover": {
    background: "#FA5341",
    color: "#ffffff",
    ".icon": {
      path: {
        fill: "#ffffff",
        stroke: "#ffffff",
      },
    },
  },
});
