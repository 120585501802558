import styled from "styled-components";
import DialogBox from "src/components/DialogBox";
import { BuyBtn } from "src/components/Button/BuyBtn";
import { CancelBtn } from "src/components/Button/CancelBtn";
import { Box } from "@mui/material";
import { ThemedText } from "src/theme/text";
import { useEffect, useState } from "react";
import { ReactComponent as EsDYNA } from "src/assets/images/esDYNA.svg";
import Dots from "src/components/Dots";
import { Trans } from "@lingui/macro";
import { useBalance_esDYNA } from "src/hooks/useQueryEarn";
import { formatAmount2 } from "src/helpers/Helpers";
import { useBasicInfo } from "src/hooks/useQueryDID";
import { ReactComponent as Arrow } from "src/assets/images/to.svg";
import { RANK_LIST } from "src/helpers/rankConstants";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { useSelector } from "react-redux";
import usePid from "src/hooks/usePid";
import PendingIcon from "src/assets/images/loading/pending.svg";
import { toWei } from "src/utils/bn";
import { useQueryApproved } from "src/hooks/useTokenApprove";
import { addresses } from "src/configs/constants";
import { useWeb3Context } from "src/hooks";

export const BoostDialogBox = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
  .boost {
    padding: 16px;
    background: ${({ theme }) => theme.bg12};
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    .boostAmount {
      .balance {
        padding: 2px 8px;
        background: ${({ theme }) => theme.bg1};
        border-radius: 4px;
      }
      .amount-input {
        text-align: right;
      }
    }
  }
`;
type dialogPros = {
  open: boolean;
  handleClose?: any;
};

export default function BoostDialog({ open, handleClose }: dialogPros) {
  const { chainID } = useWeb3Context();
  const balance = useBalance_esDYNA();
  const baseInfo = useBasicInfo();
  const [boostAmount, setBoostAmount] = useState<any>("");
  const [isEnter, setIsEnter] = useState<boolean>(true);
  const [insufficient, setInsufficient] = useState<boolean>(false);
  const [scoreAfter, setScoreAfter] = useState<any>(0);
  const { doBoost } = usePid();
  const pendingTransactions = useSelector((state: any) => {
    return state?.pendingTransactions;
  });

  const { needApprove, approveToken: DoApprove_esDYNA } = useQueryApproved(
    addresses[chainID].esDYNA,
    addresses[chainID].BurnToScore,
    boostAmount
  );
  const isApproved_esDYNA = !needApprove;
  useEffect(() => {
    if (boostAmount * 1 <= 0) {
      setIsEnter(true);
    } else {
      setIsEnter(false);
      setInsufficient(boostAmount * 1 > Number(balance));
      setScoreAfter(Number(baseInfo?.score) + boostAmount * 100);
    }
  }, [balance, baseInfo?.score, boostAmount]);
  const onInputValueChange = (e) => {
    setBoostAmount(e.target.value);
  };
  const onClickBoost = async () => {
    if (!isApproved_esDYNA) {
      DoApprove_esDYNA();
    } else {
      if (boostAmount > 0) {
        await doBoost(toWei(boostAmount).toFixed(0));
        handleClose();
      }
    }
  };

  return (
    <DialogBox
      width="480px"
      title="Boost D.ARMY Level"
      open={open}
      handleClose={handleClose}
    >
      <>
        <BoostDialogBox>
          <div className="boost">
            <div className="boostAmount">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className=""
              >
                <ThemedText.Text4Bold>Burn</ThemedText.Text4Bold>
                <div className="balance">
                  <ThemedText.Text4>
                    Balance: {formatAmount2(balance, 4, true)}
                  </ThemedText.Text4>
                </div>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt="20px"
                className=""
              >
                <Box display="flex" alignItems="center">
                  <EsDYNA width="30px" height="30px" />
                  <ThemedText.Text9Bold ml="8px" fontSize={16}>
                    esDYNA
                  </ThemedText.Text9Bold>
                </Box>
                <Box ml="8px" className="text-right">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="amount-input flex-1"
                    value={boostAmount}
                    onChange={onInputValueChange}
                  />
                </Box>
              </Box>
            </div>
          </div>
          <Box mt="16px" className="infoBox">
            <Box
              display="grid"
              alignItems="center"
              className="infoTitle"
              style={{ paddingLeft: 0 }}
            >
              <Box display="flex" alignItems="center" className="" pl="16px">
                <Dots bgColor="#FFFD02" />
                <ThemedText.Text4Bold ml="8px">
                  <Trans>Points</Trans>
                </ThemedText.Text4Bold>
              </Box>
              <Box display="flex" alignItems="center" className="" pl="16px">
                <Dots bgColor="#FFFD02" />
                <ThemedText.Text4Bold ml="8px">
                  <Trans>D.ARMY RANK</Trans>
                </ThemedText.Text4Bold>
              </Box>
            </Box>
            <Box display="grid" alignItems="center" className="infoVal">
              <Box display="flex" alignItems="center" className="" pl="16px">
                <ThemedText.Text9Bold fontSize={14}>
                  {boostAmount > 0 ? (
                    <Box display="flex" alignItems="center" className="">
                      <div className="">
                        {formatAmount2(baseInfo?.score, 0, true)}
                      </div>
                      <Arrow className="arrow ml-8" />
                      <div className="ml-6">
                        {formatAmount2(scoreAfter, 0, true)}
                      </div>
                    </Box>
                  ) : (
                    0
                  )}
                </ThemedText.Text9Bold>
              </Box>
              <Box display="flex" alignItems="center" className="" pl="16px">
                <ThemedText.Text9Bold fontSize={14}>
                  {RANK_LIST[baseInfo?.rank]}
                </ThemedText.Text9Bold>
              </Box>
            </Box>
          </Box>
        </BoostDialogBox>
        <Box display="flex" p="16px">
          <Box className="flex-1">
            <CancelBtn onClick={handleClose}>Cancel</CancelBtn>
          </Box>
          <Box ml="8px" className="flex-1">
            <BuyBtn
              onClick={onClickBoost}
              disabled={
                isEnter
                  ? true
                  : insufficient
                  ? true
                  : isApproved_esDYNA
                  ? isPendingTxn(pendingTransactions, "boost")
                  : isPendingTxn(pendingTransactions, "approveToken")
              }
            >
              {isPendingTxn(pendingTransactions, "boost") ||
              isPendingTxn(pendingTransactions, "approveToken") ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <div className="">Pending</div>
                  <img src={PendingIcon} height={20} className="ml-12" />
                </Box>
              ) : isEnter ? (
                "Enter an amount"
              ) : insufficient ? (
                "Insufficient"
              ) : isApproved_esDYNA ? (
                txnButtonText(pendingTransactions, "boost", "Boost")
              ) : (
                txnButtonText(pendingTransactions, "approveToken", "Approve")
              )}
            </BuyBtn>
          </Box>
        </Box>
      </>
    </DialogBox>
  );
}
