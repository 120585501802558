import { Trans } from "@lingui/macro";
import { Box, useMediaQuery } from "@mui/material";
import { ThemedText } from "src/theme/text";
import styled from "styled-components/macro";
import { TableBox } from "../TableBox";
import { DefaultNoData } from "src/components/NoData";
import { formatAmount } from "src/helpers/Helpers";
import moment from "moment";
import { TYPE_CODE } from "src/helpers/rankConstants";
import { BigNumber } from "ethers";
import { shorten } from "src/helpers";

const EPointBox = styled(TableBox)``;
type dataPros = {
  userInfoList?: any;
};

export default function EPointList({ userInfoList }: dataPros) {
  const isMobile = useMediaQuery("(max-width: 1080px)");
  return (
    <EPointBox className="Points">
      {isMobile ? (
        <div className="table-m">
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
            className="tableHeader-m"
          >
            <div className="">
              <ThemedText.Text4Bold>
                <Trans>Address</Trans>
              </ThemedText.Text4Bold>
            </div>
            <div className="text-right">
              <ThemedText.Text4Bold>
                <Trans>Points</Trans>
              </ThemedText.Text4Bold>
            </div>
          </Box>
          {userInfoList.length > 0 ? (
            userInfoList.map((item, index) => (
              <Box
                display="flex"
                justifyContent={"space-between"}
                className="tableItem-m"
                key={index}
              >
                <div className="">
                  <div className="" style={{ textDecoration: "underline" }}>
                    <ThemedText.Text1Medium>
                      {shorten(item.account.id)}
                    </ThemedText.Text1Medium>
                  </div>
                  <Box display="flex" mt="8px" className="">
                    <ThemedText.Text1Medium>
                      Type: {TYPE_CODE[item?.typeCode]}
                    </ThemedText.Text1Medium>
                  </Box>
                  <ThemedText.Text1Medium mt="8px">
                    {moment(item?.timestamp * 1000).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </ThemedText.Text1Medium>
                </div>
                <div className="">
                  <ThemedText.Text1Medium>
                    {formatAmount(BigNumber.from(item?.point), 18, 2)}
                  </ThemedText.Text1Medium>
                </div>
              </Box>
            ))
          ) : (
            <DefaultNoData title="No items to display" />
          )}
        </div>
      ) : (
        <>
          <Box display="flex" alignItems="center" className="tableHeader">
            <div className="flex-1 td">
              <ThemedText.Text4Bold>
                <Trans>Address</Trans>
              </ThemedText.Text4Bold>
            </div>
            <div className="flex-1 td">
              <ThemedText.Text4Bold>
                <Trans>Type</Trans>
              </ThemedText.Text4Bold>
            </div>
            <div className="flex-1 td">
              <ThemedText.Text4Bold>
                <Trans>Points</Trans>
              </ThemedText.Text4Bold>
            </div>
            <div className="flex-1 td">
              <ThemedText.Text4Bold>
                <Trans>Time</Trans>
              </ThemedText.Text4Bold>
            </div>
          </Box>
          {userInfoList.length > 0 ? (
            userInfoList.map((item, index) => (
              <Box
                display="flex"
                alignItems="center"
                className="tableTr"
                key={index}
              >
                <div className="flex-1 td">
                  <ThemedText.Text1Medium>
                    {shorten(item.account.id)}
                  </ThemedText.Text1Medium>
                </div>
                <div className="flex-1 td">
                  <ThemedText.Text1Medium>
                    {TYPE_CODE[item?.typeCode]}
                  </ThemedText.Text1Medium>
                </div>
                <div className="flex-1 td">
                  <ThemedText.Text1Medium>
                    {formatAmount(BigNumber.from(item?.point), 18, 2)}
                  </ThemedText.Text1Medium>
                </div>
                <div className="flex-1 td">
                  <ThemedText.Text1Medium>
                    {moment(item?.timestamp * 1000).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </ThemedText.Text1Medium>
                </div>
              </Box>
            ))
          ) : (
            <DefaultNoData title="No items to display" />
          )}
        </>
      )}
    </EPointBox>
  );
}
