import { Contract } from "@ethersproject/contracts";
import { useMemo } from "react";
import { isAddress } from "src/utils";
import { useEthersSigner, useWeb3Context } from "./web3Context";
import { addresses as ADDRESSMAP } from "../configs/constants";
import { abi as PositionReaderABI } from "../abis/PositionReader.json";
import { abi as VaultReaderABI } from "../abis/VaultReader.json";
import { abi as VaultPriceFeedV21FastABi } from "../abis/VaultPriceFeedV21Fast.json";
import { abi as VaultUtilsABI } from "../abis/VaultUtils.json";
import { abi as DlpTransferHelperABI } from "../abis/DlpTransferHelper.json";
import { abi as TokenABI } from "../abis/Token.json";
import { abi as PairABI } from "../abis/Pair.json";
import { abi as RouterABI } from "../abis/Router.json";

import { abi as RouterSignAbi } from "../abis/RouterSign.json";
import { abi as OrderBookABI } from "../abis/OrderBook.json";
import { abi as OrderBookReaderABI } from "../abis/OrderBookReader.json";
import { UniswapInterfaceMulticall } from "@uniswap/redux-multicall/dist/abi/types";
import UniswapInterfaceMulticallJson from "@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json";
import { abi as TradeStorageABI } from "src/abis/TradeStorage.json";
import { abi as StakingPoolABI } from "src/abis/StakingPool.json";
import { abi as ARSESTAKINGABI } from "src/abis/ARSESTAKING.json";
import { abi as RedeemTreasuryABI } from "src/abis/RedeemTreasury.json";
import { abi as ESDYNAV2ABI } from "src/abis/ESDYNAV2.json";
import {
  AddressMap,
  AddressZero,
  MULTICALL_ADDRESS,
} from "../constants/address";
import {
  PositionReader,
  VaultV2,
  VaultReader,
  VaultUtils,
  VaultPriceFeedV21Fast,
  Token,
  RouterSign,
  Pair,
  DlpTransferHelper,
  DYNA,
  DLP,
  RewardTracker,
  InfoHelper,
  DID,
  Reader,
  OrderBook,
  OrderBookReader,
  Router,
  StakingPool,
  TokenVesting,
  Treasury,
  DYNAStaking,
  RedeemDYNAT,
  PUSD,
  VaultPriceFeed,
  Bond,
  InstVesting,
  TwapPrice,
  InviteToEarn,
  TradeRebate,
  TradeStorage,
  ARSESTAKING,
  ESDYNAV2,
  LpBond,
  RedeemTreasury,
  DlpManager,
  DIDHelper,
} from "src/abis/types";
import { abi as DlpManagerABI } from "../abis/DlpManager.json";
import { abi as TokenVestingABI } from "../abis/TokenVesting.json";
import { abi as TreasuryABI } from "../abis/Treasury.json";
import { abi as DYNAStakingABI } from "../abis/DYNAStaking.json";
import { abi as RedeemDYNATABI } from "../abis/RedeemDYNAT.json";
import { abi as PUSDABI } from "src/abis/PUSD.json";
import { abi as LpBondABI } from "src/abis/lpBond.json";
import { abi as DYNAABI } from "src/abis/DYNA.json";
import { abi as ReaderABI } from "../abis/ReaderV2.json";
import { abi as VaultV2ABI } from "../abis/VaultV2.json";
import { abi as DlpABI } from "../abis/DLP.json";
import { abi as RewardTrackerABI } from "../abis/RewardTracker.json";
import { addresses as ADDRESS } from "../configs/constants";
import { abi as InfoHelperABI } from "../abis/InfoHelper.json";
import { abi as DIDABI } from "../abis/DID.json";
import { abi as VaultPriceFeedABI } from "../abis/VaultPriceFeed.json";
import { abi as BondABI } from "../abis/Bond.json";
import { abi as InstVestingABI } from "src/abis/InstVesting.json";
import { abi as TwapPriceABI } from "src/abis/TwapPrice.json";
import { abi as InviteToEarnAbi } from "src/abis/InviteToEarn.json";
import { abi as TradeRebateAbi } from "src/abis/TradeRebate.json";
import { abi as DIDHelperAbi } from "src/abis/DIDHelper.json";

const { abi: MulticallABI } = UniswapInterfaceMulticallJson;
export function useContract<T extends Contract = Contract>(
  _address: AddressMap | string | undefined,
  ABI: any[]
): T | undefined {
  const { chainID: chainId, provider, address: account } = useWeb3Context();
  const signer = useEthersSigner();
  const address = useMemo(() => {
    if (!chainId) return;
    return typeof _address == "string"
      ? _address
      : _address
      ? _address[chainId]
      : undefined;
  }, [_address, chainId]);

  return useMemo(() => {
    if (!provider || !address) return;

    if (!isAddress(address) || address === AddressZero) {
      throw Error(`Invalid 'address' parameter '${address}'.`);
    }

    let contract;
    if (!account) {
      contract = new Contract(address, ABI, provider);
    } else {
      contract = new Contract(address, ABI, signer ? signer : provider);
    }
    return contract;
  }, [provider, address, ABI, account, signer]) as T;
}

export const usePositionReaderContract = () => {
  const { chainID } = useWeb3Context();
  return useContract<PositionReader>(
    ADDRESSMAP[chainID]?.PositionReader,
    PositionReaderABI
  );
};

export const useOrderBookContract = (orderBookAddress?: string) => {
  return useContract<OrderBook>(orderBookAddress, OrderBookABI);
};

export const useOrderBookReaderContract = () => {
  const { chainID } = useWeb3Context();
  return useContract<OrderBookReader>(
    ADDRESSMAP[chainID]?.OrderBookReader,
    OrderBookReaderABI
  );
};

export const useVaultV2Contract = (vaultAddress: string) => {
  return useContract<VaultV2>(vaultAddress, VaultV2ABI);
};

export const useVaultReaderContract = () => {
  const { chainID } = useWeb3Context();
  return useContract<VaultReader>(
    ADDRESSMAP[chainID]?.VaultReader,
    VaultReaderABI
  );
};

export function useInterfaceMulticall() {
  return useContract<UniswapInterfaceMulticall>(
    MULTICALL_ADDRESS,
    MulticallABI
  ) as UniswapInterfaceMulticall;
}

export function useVaultUtilsContract(vaultUtilsAddress: string) {
  return useContract<VaultUtils>(vaultUtilsAddress, VaultUtilsABI);
}

export function useDlpTransferHelperContract() {
  const { chainID } = useWeb3Context();
  return useContract<DlpTransferHelper>(
    ADDRESSMAP[chainID]?.DlpTransferHelper,
    DlpTransferHelperABI
  );
}

export function useVaultPriceFeedV2Fast() {
  const { chainID } = useWeb3Context();
  return useContract<VaultPriceFeedV21Fast>(
    ADDRESSMAP[chainID]?.VaultPriceFeedV2Fast,
    VaultPriceFeedV21FastABi
  );
}

export function useTokenContract(address?: string) {
  return useContract<Token>(address, TokenABI);
}

export function useRouterSignContract(address?: string) {
  return useContract<RouterSign>(address, RouterSignAbi);
}

export function usePairContract(address?: string) {
  return useContract<Pair>(address, PairABI);
}

export function usePUSDBontract() {
  const { chainID } = useWeb3Context();
  return useContract<PUSD>(ADDRESS[chainID].PUSD, PUSDABI);
}

export function useLpBondContract() {
  const { chainID } = useWeb3Context();
  return useContract<LpBond>(ADDRESS[chainID].LpBond, LpBondABI);
}

export function useDYNAStakingContract() {
  const { chainID } = useWeb3Context();
  return useContract<DYNAStaking>(ADDRESS[chainID].DYNAStaking, DYNAStakingABI);
}

export function useRedeemDYNATContract() {
  const { chainID } = useWeb3Context();
  return useContract<RedeemDYNAT>(ADDRESS[chainID].RedeemDYNAT, RedeemDYNATABI);
}

export function useTreasuryContract() {
  const { chainID } = useWeb3Context();
  return useContract<Treasury>(ADDRESS[chainID].Treasury, TreasuryABI);
}

export function useDYNAContract() {
  const { chainID } = useWeb3Context();
  return useContract<DYNA>(ADDRESS[chainID].DYNA, DYNAABI);
}

export function useEsDYNAContract() {
  const { chainID } = useWeb3Context();
  return useContract<DYNA>(ADDRESS[chainID].esDYNA, DYNAABI);
}
export function useARSEContract() {
  const { chainID } = useWeb3Context();
  return useContract<DYNA>(ADDRESS[chainID].ARSE, DYNAABI);
}

export function useUSDBContract() {
  const { chainID } = useWeb3Context();
  return useContract<DYNA>(ADDRESS[chainID].USDB, DYNAABI);
}

export function useDYNATContract() {
  const { chainID } = useWeb3Context();
  return useTokenContract(ADDRESS[chainID].DYNAT);
}

export function useDLPContract(address?: string) {
  return useContract<DLP>(address, DlpABI);
}

export function useTokenVestingContract_old() {
  const { chainID } = useWeb3Context();
  return useContract<TokenVesting>(
    ADDRESS[chainID].TokenVesting_old,
    TokenVestingABI
  );
}

export function useTokenVestingContract() {
  const { chainID } = useWeb3Context();
  return useContract<TokenVesting>(
    ADDRESS[chainID].TokenVesting,
    TokenVestingABI
  );
}

export function useRewardTrackerContract(address?: string) {
  return useContract<RewardTracker>(address, RewardTrackerABI);
}

export function useDIDContract() {
  const { chainID } = useWeb3Context();
  return useContract<DID>(ADDRESS[chainID].DID, DIDABI);
}

export function useInfoHelperContract() {
  const { chainID } = useWeb3Context();
  return useContract<InfoHelper>(ADDRESS[chainID].InfoHelper, InfoHelperABI);
}

export function useReaderContract() {
  const { chainID } = useWeb3Context();
  return useContract<Reader>(ADDRESS[chainID].Reader, ReaderABI);
}

export function useRouterContract(address?: string) {
  return useContract<Router>(address, RouterABI);
}

export function useStakePoolContract(address?: string) {
  return useContract<StakingPool>(address, StakingPoolABI);
}

export function useDynaDAOContract() {
  const { chainID } = useWeb3Context();
  return useContract<Token>(ADDRESS[chainID].DYNA_DAO, TokenABI);
}

export function useVaultPriceFeedContract() {
  const { chainID } = useWeb3Context();
  return useContract<VaultPriceFeed>(
    ADDRESS[chainID].VaultPriceFeed,
    VaultPriceFeedABI
  );
}

export function useBondContract() {
  const { chainID } = useWeb3Context();
  return useContract<Bond>(ADDRESS[chainID].Bond, BondABI);
}

export function useInsetVestingContract() {
  const { chainID } = useWeb3Context();
  return useContract<InstVesting>(ADDRESS[chainID].InstVesting, InstVestingABI);
}

export function useTwapPriceContract() {
  const { chainID } = useWeb3Context();
  return useContract<TwapPrice>(ADDRESS[chainID].TwapPrice, TwapPriceABI);
}

export function useInviteToEarnContract() {
  const { chainID } = useWeb3Context();
  return useContract<InviteToEarn>(
    ADDRESS[chainID].InviteToEarn,
    InviteToEarnAbi
  );
}

export function useTradeRebateContract() {
  const { chainID } = useWeb3Context();
  return useContract<TradeRebate>(ADDRESS[chainID].TradeRebate, TradeRebateAbi);
}

export function useTradeStorageContract() {
  const { chainID } = useWeb3Context();
  return useContract<TradeStorage>(
    ADDRESS[chainID].TradeStorage_PLP_β,
    TradeStorageABI
  );
}
export function useARSEStakingContract() {
  const { chainID } = useWeb3Context();
  return useContract<ARSESTAKING>(ADDRESS[chainID].ARSEStaking, ARSESTAKINGABI);
}

export function useRedeemTreasuryContract() {
  const { chainID } = useWeb3Context();
  return useContract<RedeemTreasury>(
    ADDRESS[chainID].RedeemTreasury,
    RedeemTreasuryABI
  );
}

export function useESDYNAStaingV2() {
  const { chainID } = useWeb3Context();
  return useContract<ESDYNAV2>(ADDRESS[chainID].esDYNAStaking, ESDYNAV2ABI);
}

export function useDLPManagerContract() {
  const { chainID } = useWeb3Context();
  return useContract<DlpManager>(
    ADDRESS[chainID].DlpManager_PLP_β,
    DlpManagerABI
  );
}

export function useDIDHelperContract() {
  const { chainID } = useWeb3Context();
  return useContract<DIDHelper>(ADDRESS[chainID].DIDHelper, DIDHelperAbi);
}
