import { Trans } from "@lingui/macro";
import { Box, Link } from "@mui/material";
import { ThemedText } from "src/theme/text";
import styled from "styled-components/macro";
import { ReactComponent as DYNA } from "src/assets/images/DYNA.svg";
import { ReactComponent as EsDYNA } from "src/assets/images/esDYNA.svg";
import { ReactComponent as USDB } from "src/assets/images/tokens/USDB.svg";
import { formatAmount2 } from "src/helpers/Helpers";
import { useGetDynaPrice } from "src/hooks/get_price_dyna";
import { useSelector } from "react-redux";
import { BuyBtn } from "src/components/Button/BuyBtn";
import { ClaimBtn } from "src/components/Button/ClaimBtn";
import {
  useBalance_DYNA,
  useBalance_esDYNA,
  useBalance_usdc,
  useESTokenStakedV2Balance,
} from "src/hooks/useQueryEarn";
import { NavLink } from "react-router-dom";
import { BUY_DYNA_URL } from "src/constants/misc";
import { useWeb3Context } from "src/hooks";

export const AssetBox = styled(Box)`
  margin-top: 32px;
  .assetHeader,
  .assetItem {
    .td {
      margin-left: 12px;
      :last-child {
        margin-right: 12px;
      }
    }
  }
  .assetHeader,
  .assetHeader-m {
    height: 40px;
  }
  .assetItem {
    padding: 18px 0;
  }
  /* .assetHeader,
  .assetHeader-m, */
  .assetItem,
  .assetItem-m {
    border-top: 1px solid ${({ theme }) => theme.bg4};
  }
`;

export default function AssetList() {
  const DYNAPrice = useGetDynaPrice();
  const usdcBalance = useBalance_usdc();
  const DYNABalance = useBalance_DYNA();
  const DYNAStaked = 0;
  const esDYNABalance = useBalance_esDYNA();
  const esDYNAStaked = useESTokenStakedV2Balance();
  const tokensArr = useSelector((state: any) => {
    return state?.app?.tokensArr || [];
  });
  const { chainID } = useWeb3Context();
  const handlerBuy = () => {
    chainID == 421614 && window.open(BUY_DYNA_URL[chainID]);
  };
  return (
    <AssetBox className="assetList">
      <Box display="flex" alignItems="center" className="assetHeader">
        <ThemedText.Text4Bold className="flex-1 td">
          <Trans>Asset</Trans>
        </ThemedText.Text4Bold>
        <Box width="200px" className="text-right td">
          <ThemedText.Text4Bold>
            <Trans>Total</Trans>
          </ThemedText.Text4Bold>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text4Bold>
            <Trans>Wallet</Trans>
          </ThemedText.Text4Bold>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text4Bold>
            <Trans>Staked</Trans>
          </ThemedText.Text4Bold>
        </Box>
        <div className="flex-1 td"></div>
      </Box>
      <Box display="flex" alignItems="center" className="assetItem">
        <Box display="flex" alignItems="center" className="flex-1 td">
          <DYNA width={48} height={48} />
          <ThemedText.Text1Bold fontSize={24} ml="16px">
            <Trans>DYNA</Trans>
          </ThemedText.Text1Bold>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(
              DYNABalance?.plus(DYNAStaked ? DYNAStaked : 0),
              2,
              true
            )}
          </ThemedText.Text1Medium>
          <ThemedText.Text5 fontSize={12} mt="8px">
            $
            {formatAmount2(
              DYNABalance?.plus(DYNAStaked ? DYNAStaked : 0).times(
                DYNAPrice ? DYNAPrice : 1
              ),
              2,
              true
            )}
          </ThemedText.Text5>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(DYNABalance, 2, true)}
          </ThemedText.Text1Medium>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(DYNAStaked, 2, true)}
          </ThemedText.Text1Medium>
        </Box>
        <Box display="flex" justifyContent="flex-end" className="flex-1 td">
          <Link component={NavLink} to={`/Earn?curr=${0}`}>
            <Box width="120px">
              <BuyBtn>Earn</BuyBtn>
            </Box>
          </Link>
          <Box width="120px" ml="16px">
            <ClaimBtn onClick={handlerBuy}>Buy / Sell</ClaimBtn>
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" className="assetItem">
        <Box display="flex" alignItems="center" className="flex-1 td">
          <EsDYNA width={48} height={48} />
          <ThemedText.Text1Bold fontSize={24} ml="16px">
            <Trans>esDYNA</Trans>
          </ThemedText.Text1Bold>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(
              esDYNABalance?.plus(esDYNAStaked ? esDYNAStaked : 0),
              2,
              true
            )}
          </ThemedText.Text1Medium>
          <ThemedText.Text5 fontSize={12} mt="8px">
            $
            {formatAmount2(
              esDYNABalance
                ?.plus(esDYNAStaked ? esDYNAStaked : 0)
                .times(DYNAPrice ? DYNAPrice : 1),
              2,
              true
            )}
          </ThemedText.Text5>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(esDYNABalance, 2, true)}
          </ThemedText.Text1Medium>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(esDYNAStaked, 2, true)}
          </ThemedText.Text1Medium>
        </Box>
        <Box display="flex" justifyContent="flex-end" className="flex-1 td">
          <Link component={NavLink} to={`/Earn?curr=${0}`}>
            <Box width="120px">
              <BuyBtn>Earn</BuyBtn>
            </Box>
          </Link>
          <Box width="120px" ml="16px">
            <ClaimBtn disabled={true}>Buy / Sell</ClaimBtn>
          </Box>
        </Box>
      </Box>
      {/* {tokensArr?.length > 0 ? (
        tokensArr?.map((item, i) => (
          <Box display="flex" alignItems="center" className="assetItem" key={i}>
            <Box display="flex" alignItems="center" className="flex-1 td">
              <img src={item?.icon} width={48} height={48} />
              <ThemedText.Text1Bold fontSize={24} ml="16px">
                {item?.name}
              </ThemedText.Text1Bold>
            </Box>
            <Box width="200px" className="text-right td">
              <ThemedText.Text1Medium fontSize={16}>
                {formatAmount2(
                  item?.dlpBalance * 1 + item?.dlpBalanceStaked * 1,
                  2,
                  true
                )}
              </ThemedText.Text1Medium>
              <ThemedText.Text5 fontSize={12} mt="8px">
                $
                {formatAmount2(
                  (item?.dlpBalance * 1 + item?.dlpBalanceStaked * 1) *
                    item?.price,
                  2,
                  true
                )}
              </ThemedText.Text5>
            </Box>
            <Box width="200px" className="text-right td">
              <ThemedText.Text1Medium fontSize={16}>
                {formatAmount2(item?.dlpBalance, 2, true)}
              </ThemedText.Text1Medium>
            </Box>
            <Box width="200px" className="text-right td">
              <ThemedText.Text1Medium fontSize={16}>
                {formatAmount2(item?.dlpBalanceStaked, 2, true)}
              </ThemedText.Text1Medium>
            </Box>
            <Box display="flex" justifyContent="flex-end" className="flex-1 td">
              <Link component={NavLink} to={`/Earn?curr=${1}`}>
                <Box width="120px">
                  <BuyBtn>Earn</BuyBtn>
                </Box>
              </Link>
              <Box width="120px" ml="16px">
                <Link component={NavLink} to={`/Earn?curr=${1}`}>
                  <ClaimBtn disabled={true}>Buy / Sell</ClaimBtn>
                </Link>
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <div className="flex-1">
          <Skeleton width="100%" height={60} style={{ borderRadius: "10px" }} />
        </div>
      )} */}
      <Box display="flex" alignItems="center" className="assetItem">
        <Box display="flex" alignItems="center" className="flex-1 td">
          <USDB width={48} height={48} />
          <ThemedText.Text1Bold fontSize={24} ml="16px">
            <Trans>USDB</Trans>
          </ThemedText.Text1Bold>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(usdcBalance, 2, true)}
          </ThemedText.Text1Medium>
          <ThemedText.Text5 fontSize={12} mt="8px">
            ${formatAmount2(usdcBalance, 2, true)}
          </ThemedText.Text5>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(usdcBalance, 2, true)}
          </ThemedText.Text1Medium>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(0, 2, true)}
          </ThemedText.Text1Medium>
        </Box>
        <Box display="flex" justifyContent="flex-end" className="flex-1 td">
          <></>
        </Box>
      </Box>
    </AssetBox>
  );
}
