import { useMemo, useState } from "react";

import { addresses } from "../configs/constants";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
import { useSingleCallResult } from "src/lib/hooks/multicall";
import { useDIDContract, useDIDHelperContract } from "./useContract";
import { useWeb3Context } from "./web3Context";
import { BN } from "src/utils/bn";

export const useBasicInfo = (account?: string) => {
  const { address, chainID } = useWeb3Context();
  const DIDContract = useDIDContract();
  const { result } = useSingleCallResult(DIDContract, "getBasicInfo", [
    account ? account : address ? address : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!result) return;
    const userInfoArr1 = result[0];
    const id = userInfoArr1.id;
    const nickName = userInfoArr1.nickName;
    const refCode = userInfoArr1.refCode;
    const rank = userInfoArr1.rank;
    const idvPoints = userInfoArr1.idvPoints;
    const rebPoints = userInfoArr1.rebPoints;
    const score = BN(Number(idvPoints)).plus(BN(Number(rebPoints)));
    const friends = userInfoArr1.nomNumber;
    const inviter = userInfoArr1.inviter;
    // const userSizeSum = userInfoArr2[2];
    // const feeRebate = userInfoArr2[9];
    //  const rankUpdateTime = userInfoArr2[15];
    return {
      id,
      nickName,
      refCode,
      rank,
      score,
      feeRebate: 0,
      friends,
      inviter,
      // const feeDiscount = userInfoArr2[0];
      // feeDiscount: ethers.utils.formatUnits(feeDiscount, 6),
      // userSizeSum: ethers.utils.formatUnits(userSizeSum, 30),
      // score: ethers.utils.formatEther(score),
      // rankUpdateTime,
    };
  }, [result]);
};

export const useInviterRank = () => {
  const res = useBasicInfo();
  const DIDContract = useDIDContract();
  const { result } = useSingleCallResult(DIDContract, "getBasicInfo", [
    res ? res.inviter : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const rank = result[0].rank;
    return rank;
  }, [result]);
};

export const useRefCodeOwner = (refCode: string) => {
  const DIDContract = useDIDContract();
  const { result } = useSingleCallResult(DIDContract, "getRefCodeOwner", [
    refCode,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return data;
  }, [result]);
};

export const useDefaultRefCode = () => {
  const { chainID } = useWeb3Context();
  const DIDContract = useDIDContract();
  const [refCode, setrefCode] = useState<any>();
  DIDContract?.getRefCode(addresses[chainID].DID)
    .then((res) => setrefCode(res))
    .catch((e) => console.log("Error", e));
  return refCode;
  // const { result, error } = useSingleCallResult(
  //   DIDContract,
  //   "getRefCode",
  //   addresses[chainID].DID
  // );
  // console.log("defaultRefCode", result, error, addresses[chainID].DID);
  // return useMemo(() => {
  //   if (!result) return;
  //   const data = result[0];
  //   return data;
  // }, [result]);
};

export const useDefaultRefCode2 = () => {
  const { chainID } = useWeb3Context();
  const DIDContract = useDIDContract();
  const [refCode, setrefCode] = useState<any>();
  DIDContract?.getRefCode(addresses[chainID].DID)
    .then((res) => setrefCode(res))
    .catch((e) => console.log("Error", e));
  return refCode;
  // const { result, error } = useSingleCallResult(
  //   DIDContract,
  //   "getRefCode",
  //   addresses[chainID].DID
  // );
  // console.log("defaultRefCode", result, error, addresses[chainID].DID);
  // return useMemo(() => {
  //   if (!result) return;
  //   const data = result[0];
  //   return data;
  // }, [result]);
};

export const useRebatesAmount = () => {
  const DIDHelperContract = useDIDHelperContract();
  const { address } = useWeb3Context();
  const { result } = useSingleCallResult(DIDHelperContract, "getBasicInfo", [
    address ? address : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const userInfoArr1 = result[0];
    const bondRebateClaimable = userInfoArr1.bondRebateClaimable;
    const tradeRebateClaimable = userInfoArr1.tradeRebateClaimable;
    const total = BN(bondRebateClaimable.toString())
      .plus(tradeRebateClaimable.toString())
      .dividedBy(BN(10).pow(18));
    return total;
  }, [result]);
};
