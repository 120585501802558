import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ConnectBtn = styled(Button)({
  height: "40px",
  lineHeight: "40px",
  maxHeight: "40px",
  color: "#FFFD02",
  fontSize: 14,
  fontFamily: "Gilroy-Bold",
  textTransform: "uppercase",
  padding: "0 12px",
  background: "rgba(255, 253, 2, 0.20)",
  border: "none",
  borderRadius: "4px",
  ".downIcon": {
    marginLeft: "10px",
  },
  "&:hover": {
    background: "#FFFD02",
    color: "#000000",
    ".downIcon": {
      path: {
        fill: "#000000",
        stroke: "#000000",
      },
    },
  },
});
