import { createReducer } from "@reduxjs/toolkit";
import { priceList, priceList_gns, pythPrice } from "./actions";

type HttpState = {
  price?: any;
  price_gns?: any;
  pythPrice?: any;
};

const initialState: HttpState = {
  price: undefined,
  price_gns: undefined,
  pythPrice: {},
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(priceList, (state, { payload: data }) => {
      state.price = data;
    })
    .addCase(priceList_gns, (state, { payload: data }) => {
      state.price_gns = data;
    })
    .addCase(pythPrice, (state, { payload: data }) => {
      state.pythPrice = data;
    })
);
