import { Trans } from "@lingui/macro";
import { Box } from "@mui/material";
import { ThemedText } from "src/theme/text";
import styled from "styled-components";
import Tabs from "./Tabs";
import { useEffect, useState } from "react";
import InvitedList from "./InvitedList";
import EPointList from "./EPointsList";
import { getPidGraphClient } from "src/Api/common";
import { gql } from "@apollo/client";
import { useWeb3Context } from "src/hooks";

const HistoryBox = styled(Box)`
  width: 100%;
  .titleBox {
    padding-bottom: 52px;
  }
  .tabBox {
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.bg3};
  }
`;

export default function History() {
  const [curr, setCurr] = useState<number>(0);
  const tabList = ["Invited", "Points"];
  const { address, chainID } = useWeb3Context();
  const [inviteList, setInviteList] = useState<any>([]);
  const [userInfoList, setUserInfoList] = useState<any>([]);
  useEffect(() => {
    if (address) {
      const query = gql(`{
        accounts
        (where:{id_in:["${address.toLowerCase()}"]}) 
        {
          id
          sons(orderBy: invitedTimestamp, orderDirection: desc) {
            id
            invitedTimestamp
          }
          pointHistory(orderBy: timestamp, orderDirection: desc) {
            typeCode
            point
            timestamp
            account{
              id
            }
          }
        }
        
      } `);
      getPidGraphClient(chainID)
        ?.query({ query })
        .then((res) => {
          const data = res.data.accounts;
          if (data.length > 0) {
            // console.log("data", data);
            if (data[0].sons.length > 0) setInviteList(data[0].sons);
            if (data[0].pointHistory.length > 0)
              setUserInfoList(data[0].pointHistory);
          }
        })
        .catch(console.warn);
    }
  }, [chainID, address]);

  return (
    <HistoryBox className="portfolio-view">
      <Box display="flex" justifyContent="center" className="titleBox">
        <div className="container">
          <ThemedText.Title>
            <Trans>History</Trans>
          </ThemedText.Title>
        </div>
      </Box>
      <Box display="flex" justifyContent="center" className="tabBox">
        <div className="container">
          <Tabs curr={curr} setCurr={setCurr} tabList={tabList} />
        </div>
      </Box>
      <Box display="flex" justifyContent="center" className="">
        <div className="container">
          {curr == 0 ? (
            <InvitedList inviteList={inviteList} />
          ) : (
            <EPointList userInfoList={userInfoList} />
          )}
        </div>
      </Box>
    </HistoryBox>
  );
}
