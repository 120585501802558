import { configureChains, createConfig } from "wagmi";
import {
  zkSyncTestnet,
  zkSync,
  arbitrum,
  mantleTestnet,
  mantle,
} from "wagmi/chains";
import "@rainbow-me/rainbowkit/styles.css";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
  braveWallet,
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  okxWallet,
  rabbyWallet,
  rainbowWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
const SepoliaTest = {
  id: 421614,
  network: 'ARBITRUM_SEPOLIA Testnet',
  name: 'ARBITRUM_SEPOLIA Testnet',
  nativeCurrency: {
    name: 'ARBITRUM_SEPOLIA Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    infura: {
      http: ['https://arbitrum-sepolia.infura.io/v3'],
    },
    default: {
      http: ['https://sepolia-rollup.arbitrum.io/rpc'],
    },
    public: {
      http: ['https://sepolia-rollup.arbitrum.io/rpc'],
    },
  },
  testnet: true,
};

const BLAST_TEST = {
  id: 168587773,
  network: 'BLAST Testnet',
  name: 'BLAST Testnet',
  nativeCurrency: {
    name: 'BLAST Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    infura: {
      http: ['https://blast-sepolia.infura.io/v3'],
    },
    default: {
      http: ['https://sepolia.blast.io'],
    },
    public: {
      http: ['https://sepolia.blast.io'],
    },
  },
  testnet: true,
  hasIcon: true,
  iconUrl: 'https://raw.githubusercontent.com/metaverseblock/token-icon/main/168587773/BLAST.png',
};

const chains = [
  // {
  //   ...SepoliaTest,
  //   rpcUrls: {
  //     default: { http: ["https://sepolia-rollup.arbitrum.io/rpc"] },
  //     public: { http: ["https://sepolia-rollup.arbitrum.io/rpc"] },
  //   },
  // },
  {
    ...BLAST_TEST,
    rpcUrls: {
      default: { http: ["https://sepolia.blast.io"] },
      public: { http: ["https://sepolia.blast.io"] },
    },
  },
];

const projectId = "e55dd0a6717a3f7490b3674e00d8deed";

const { publicClient } = configureChains(
  chains,
  [
    jsonRpcProvider({
      rpc: (chain) => ({ http: chain.rpcUrls.default.http[0] }),
    }),
  ],
  { batch: { multicall: true } }
);

const needsInjectedWalletFallback =
  typeof window !== "undefined" &&
  window.ethereum &&
  !window.ethereum.isMetaMask &&
  !window.ethereum.isCoinbaseWallet;

const walletConnectProjectId = projectId;

const connectors = connectorsForWallets([
  {
    groupName: "DYNA",
    wallets: [
      metaMaskWallet({
        projectId: walletConnectProjectId,
        chains,
        shimDisconnect: true,
      }),
      braveWallet({ chains, shimDisconnect: true }),
      rainbowWallet({ projectId: walletConnectProjectId, chains }),
      walletConnectWallet({
        projectId: walletConnectProjectId,
        chains,
        options: {
          projectId: walletConnectProjectId,
          showQrModal: false,
          qrModalOptions: {
            tokenImages: "",
            chainImages: "",
            explorerRecommendedWalletIds: [
              "0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150",
              "1aedbcfc1f31aade56ca34c38b0a1607b41cccfa3de93c946ef3b4ba2dfab11c",
            ],
          },
        },
        shimDisconnect: true,
      }),
      coinbaseWallet({ appName: "DYNA", chains }),
      rabbyWallet({ chains, shimDisconnect: true }),
      okxWallet({ projectId: walletConnectProjectId, chains }),
      ...(needsInjectedWalletFallback
        ? [injectedWallet({ chains, shimDisconnect: true })]
        : []),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});


export { chains, wagmiConfig };