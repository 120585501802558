const round3 = {
  round: 3,
  fromTimestamp: 1680912000,
  endTimestamp: 1684972800,
  time: "April 8th 00:00 - May 25th 00:00 (UTC)",
  totalPrize: "30000",
  prizeList: [
    {
      id: "1",
      prize: "10500",
    },
    {
      id: "2",
      prize: "6000",
    },
    {
      id: "3",
      prize: "4500",
    },
    {
      id: "4",
      prize: "3000",
    },
    {
      id: "5",
      prize: "2250",
    },
    {
      id: "6",
      prize: "1500",
    },
    {
      id: "7",
      prize: "900",
    },
    {
      id: "8",
      prize: "600",
    },
    {
      id: "9",
      prize: "450",
    },
    {
      id: "10",
      prize: "300",
    }
  ],
}


export const RANK_LIST = [
  {
    round: 1,
    fromTimestamp: 1670655600,
    endTimestamp: 1673334000,
    time: "Dec 10th 07:00 -  Jan 10th 07:00 (UTC)",
    totalPrize: "25000",
    prizeList: [
      {
        id: "1",
        prize: "12500",
      },
      {
        id: "2",
        prize: "6250",
      },
      {
        id: "3",
        prize: "3125",
      },
      {
        id: "4",
        prize: "1875",
      },
      {
        id: "5",
        prize: "1250",
      }
    ],
  },
  {
    round: 2,
    fromTimestamp: 1673427600,
    endTimestamp: 1677056400,
    time: "Jan 11th 09:00 - Feb 22th 09:00 (UTC)",
    totalPrize: "30000",
    prizeList: [
      {
        id: "1",
        prize: "12000",
      },
      {
        id: "2",
        prize: "5000",
      },
      {
        id: "3",
        prize: "3500",
      },
      {
        id: "4",
        prize: "2500",
      },
      {
        id: "5",
        prize: "2000",
      },
      {
        id: "6",
        prize: "1500"
      },
      {
        id: "7",
        prize: "1250"
      },
      {
        id: "8",
        prize: "1000"
      },
      {
        id: "9",
        prize: "750"
      },
      {
        id: "10",
        prize: "500"
      }
    ],
  },
  round3
];

export const RANK_LIST_ARB = [
  {
    round: 1,
    fromTimestamp: 1678492800,
    endTimestamp: 1678752000,
    time: "March 11th 00:00 - March 14th 00:00 (UTC)",
    totalPrize: "6000",
    prizeList: [
      {
        id: "1",
        prize: "3000",
      },
      {
        id: "2",
        prize: "2000",
      },
      {
        id: "3",
        prize: "1000",
      }
    ],
  },
  {
    round: 2,
    fromTimestamp: 1679270400,
    endTimestamp: 1679788800,
    time: "March 20th 00:00 - March 26th 00:00 (UTC)",
    totalPrize: "10000",
    prizeList: [
      {
        id: "1",
        prize: "5000",
      },
      {
        id: "2",
        prize: "3000",
      },
      {
        id: "3",
        prize: "2000",
      }
    ],
  },
  round3
]

export const RANK_LIST_HOLYGRAIL = [
  {
    round: 1,
    fromTimestamp: 1679270400,
    endTimestamp: 1679788800,
    time: "March 20th 00:00 - March 26th 00:00 (UTC)",
    totalPrize: "500",
    prizeList: [
      {
        id: "1",
        prize: "300",
      },
      {
        id: "2",
        prize: "150",
      },
      {
        id: "3",
        prize: "50",
      }
    ],
  },

]
export const RANK_LIST_BRIDGE = [
  {
    round: 1,
    fromTimestamp: 1679270400,
    endTimestamp: 1679788800,
    time: "March 20th 00:00 - March 26th 00:00 (UTC)",
    totalPrize: "500",
    prizeList: [
      {
        id: "1",
        prize: "300",
      },
      {
        id: "2",
        prize: "150",
      },
      {
        id: "3",
        prize: "50",
      }
    ],
  },
]