import { ethers } from "ethers";
import { getContractAddress, getPriceKey } from "../Addresses";

import BTC_icon from "../assets/images/tokens/BTC.svg";
import BNB_icon from "../assets/images/tokens/BNB.svg";
import ETH_icon from "../assets/images/tokens/ETH.svg";
import ARB_icon from "../assets/images/tokens/Arbitrum.svg";
import USDC_icon from "../assets/images/tokens/USDC.svg";
import USDB_icon from "src/assets/images/tokens/USDB.svg";
import DOGE_icon from "../assets/images/tokens/DOGE.svg";
import MATIC_icon from "../assets/images/tokens/MATIC.svg";
import DYNA_icon from '../assets/images/DYNA.svg'
import SOL_icon from 'src/assets/images/tokens/SOL.svg'
import OP_icon from 'src/assets/images/tokens/OP.svg'
import SUI_icon from 'src/assets/images/tokens/SUI.svg'
import ORDI_icon from 'src/assets/images/tokens/ORDI.svg'
import AVAX_icon from 'src/assets/images/tokens/AVAX.svg'
import LINK_icon from 'src/assets/images/tokens/LINK.svg'
import PEPE_icon from 'src/assets/images/tokens/PEPE.png'
import { DLP_TYPES } from "src/constants/tokens";

export const TOKENS = {
  421614: [
    {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      isNative: true,
      address: ethers.constants.AddressZero,
      pirceId: getPriceKey(421614, 'KEY_ETH'),
      imageUrl: ETH_icon,
      isShortable: true,
      isLongable: true,
      isDlp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
      updataPrice: false,
    },
    {
      name: "ETH (Wrapped)",
      symbol: "WETH",
      baseSymbol: "ETH",
      decimals: 18,
      address: getContractAddress(421614, "WETH"),
      pirceId: getPriceKey(421614, 'KEY_ETH'),
      imageUrl: ETH_icon,
      isShortable: false,
      isWrapped: true,
      isDlp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
      updataPrice: true,
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      address: getContractAddress(421614, "BTC"),
      pirceId: getPriceKey(421614, 'KEY_BTC'),
      imageUrl: BTC_icon,
      isShortable: true,
      isLongable: true,
      isDlp1: true,
      tokenDecimals: 2,
      bgcolor: "#F7931A",
    },
    {
      name: "USDB",
      symbol: "USDB",
      decimals: 6,
      address: getContractAddress(421614, "USDB"),
      pirceId: getPriceKey(421614, 'KEY_USDB'),
      imageUrl: USDB_icon,
      isShortable: true,
      isStable: true,
      isShortable: true,
      isLongable: true,
      tokenDecimals: 2,
      bgcolor: "#2775CA",
    },
    {
      name: "DYNA",
      symbol: "DYNA",
      decimals: 18,
      address: getContractAddress(421614, "DYNA"),
      pirceId: getPriceKey(421614, 'KEY_USDB'),
      imageUrl: DYNA_icon,
      tokenDecimals: 2,
      bgcolor: "#F9AF1B",
    },
  ],
  168587773: [
    {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      isNative: true,
      address: ethers.constants.AddressZero,
      pirceId: getPriceKey(168587773, 'KEY_ETH'),
      imageUrl: ETH_icon,
      isShortable: true,
      isLongable: true,
      isDlp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
      updataPrice: false,
    },
    {
      name: "ETH (Wrapped)",
      symbol: "WETH",
      baseSymbol: "ETH",
      decimals: 18,
      address: getContractAddress(168587773, "WETH"),
      pirceId: getPriceKey(168587773, 'KEY_ETH'),
      imageUrl: ETH_icon,
      isShortable: false,
      isWrapped: true,
      isDlp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
      updataPrice: true,
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      address: getContractAddress(168587773, "BTC"),
      pirceId: getPriceKey(168587773, 'KEY_BTC'),
      imageUrl: BTC_icon,
      isShortable: true,
      isLongable: true,
      isDlp1: true,
      tokenDecimals: 2,
      bgcolor: "#F7931A",
    },
    {
      name: "USDB",
      symbol: "USDB",
      decimals: 18,
      address: getContractAddress(168587773, "USDB"),
      pirceId: getPriceKey(168587773, 'KEY_USDB'),
      imageUrl: USDB_icon,
      isShortable: true,
      isStable: true,
      isDlp1: true,
      tokenDecimals: 2,
      bgcolor: "#2775CA",
    },
    {
      name: "DYNA",
      symbol: "DYNA",
      decimals: 18,
      address: getContractAddress(168587773, "DYNA"),
      pirceId: getPriceKey(168587773, 'KEY_USDB'),
      imageUrl: DYNA_icon,
      tokenDecimals: 2,
      bgcolor: "#F9AF1B",
    },
    // {
    //   name: "OP",
    //   symbol: "OP",
    //   decimals: 18,
    //   address: getContractAddress(168587773, "OP"),
    //   pirceId: getPriceKey(168587773, 'KEY_OP'),
    //   imageUrl: OP_icon,
    //   tokenDecimals: 4,
    //   isShortable: true,
    //   isLongable: true,
    //   bgcolor: "#F9AF1B",
    // },
    // {
    //   name: "SUI",
    //   symbol: "SUI",
    //   decimals: 18,
    //   address: getContractAddress(421614, "SUI"),
    //   pirceId: getPriceKey(421614, 'KEY_SUI'),
    //   imageUrl: SUI_icon,
    //   tokenDecimals: 4,
    //   isShortable: true,
    //   isLongable: true,
    //   bgcolor: "#F9AF1B",
    // },
    // {
    //   name: "MATIC",
    //   symbol: "MATIC",
    //   decimals: 18,
    //   address: getContractAddress(421614, "MATIC"),
    //   pirceId: getPriceKey(421614, 'KEY_MATIC'),
    //   imageUrl: MATIC_icon,
    //   isShortable: true,
    //   isLongable: true,
    //   tokenDecimals: 4,
    //   bgcolor: "#F9AF1B",
    // },
    // {
    //   name: "PEPE",
    //   symbol: "PEPE",
    //   decimals: 18,
    //   isShortable: true,
    //   isLongable: true,
    //   address: getContractAddress(421614, "PEPE"),
    //   pirceId: getPriceKey(421614, 'KEY_PEPE'),
    //   imageUrl: PEPE_icon,
    //   tokenDecimals: 10,
    //   bgcolor: "#F9AF1B",
    // },
    // {
    //   name: "DOGE",
    //   symbol: "DOGE",
    //   decimals: 18,
    //   isShortable: true,
    //   isLongable: true,
    //   address: getContractAddress(421614, "DOGE"),
    //   pirceId: getPriceKey(421614, 'KEY_DOGE'),
    //   imageUrl: DOGE_icon,
    //   tokenDecimals: 4,
    //   bgcolor: "#F9AF1B",
    // },
  ],
};

export const WHITE_GNS_TOKEN_ADDRESS = {
  421614: [{
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
    isNative: true,
    address: ethers.constants.AddressZero,
    pirceId: getPriceKey(421614, 'KEY_ETH'),
    imageUrl: ETH_icon,
    isShortable: true,
    isLongable: true,
    isDlp1: true,
    tokenDecimals: 2,
    bgcolor: "#6F7BBA",
    updataPrice: false,
  },
  {
    name: "ETH (Wrapped)",
    symbol: "WETH",
    baseSymbol: "ETH",
    decimals: 18,
    address: getContractAddress(421614, "WETH"),
    pirceId: getPriceKey(421614, 'KEY_ETH'),
    imageUrl: ETH_icon,
    isShortable: false,
    isWrapped: true,
    isDlp1: true,
    tokenDecimals: 2,
    bgcolor: "#6F7BBA",
    updataPrice: true,
  },
  {
    name: "Bitcoin",
    symbol: "BTC",
    decimals: 8,
    address: getContractAddress(421614, "BTC"),
    pirceId: getPriceKey(421614, 'KEY_BTC'),
    imageUrl: BTC_icon,
    isShortable: true,
    isLongable: true,
    isDlp1: true,
    tokenDecimals: 2,
    bgcolor: "#F7931A",
  },
  {
    name: "USDB",
    symbol: "USDB",
    decimals: 6,
    address: getContractAddress(421614, "USDB"),
    pirceId: getPriceKey(421614, 'KEY_USDB'),
    imageUrl: USDB_icon,
    isShortable: true,
    isStable: true,
    isDlp1: true,
    tokenDecimals: 2,
    bgcolor: "#2775CA",
  },
  ],
  168587773: [
    {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      isNative: true,
      address: ethers.constants.AddressZero,
      pirceId: getPriceKey(168587773, 'KEY_ETH'),
      imageUrl: ETH_icon,
      isShortable: true,
      isLongable: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
      updataPrice: false,
    },
    {
      name: "ETH (Wrapped)",
      symbol: "WETH",
      baseSymbol: "ETH",
      decimals: 18,
      address: getContractAddress(168587773, "WETH"),
      pirceId: getPriceKey(168587773, 'KEY_ETH'),
      imageUrl: ETH_icon,
      isShortable: false,
      isWrapped: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
      updataPrice: true,
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      address: getContractAddress(168587773, "BTC"),
      pirceId: getPriceKey(168587773, 'KEY_BTC'),
      imageUrl: BTC_icon,
      isShortable: true,
      isLongable: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#F7931A",
    },
    {
      name: "USDB",
      symbol: "USDB",
      decimals: 18,
      address: getContractAddress(168587773, "USDB"),
      pirceId: getPriceKey(168587773, 'KEY_USDB'),
      imageUrl: USDB_icon,
      isShortable: true,
      isStable: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#2775CA",
    },
    {
      name: "DYNA",
      symbol: "DYNA",
      decimals: 18,
      address: getContractAddress(168587773, "DYNA"),
      pirceId: getPriceKey(168587773, 'KEY_USDB'),
      imageUrl: DYNA_icon,
      tokenDecimals: 2,
      isLongable: false,
      bgcolor: "#F9AF1B",
    },
    {
      name: "ARB",
      symbol: "ARB",
      decimals: 18,
      address: getContractAddress(168587773, "ARB"),
      pirceId: getPriceKey(168587773, 'KEY_ARB'),
      imageUrl: ARB_icon,
      isShortable: true,
      isLongable: true,
      tokenDecimals: 4,
      bgcolor: "#F9AF1B",
    },
    {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
      address: getContractAddress(168587773, "BNB"),
      pirceId: getPriceKey(168587773, 'KEY_BNB'),
      imageUrl: BNB_icon,
      isShortable: true,
      isLongable: true,
      tokenDecimals: 2,
      bgcolor: "#F9AF1B",
    },
    {
      name: "SOL",
      symbol: "SOL",
      decimals: 18,
      address: getContractAddress(168587773, "SOL"),
      pirceId: getPriceKey(168587773, 'KEY_SOL'),
      imageUrl: SOL_icon,
      isShortable: true,
      isLongable: true,
      tokenDecimals: 4,
      bgcolor: "#F9AF1B",
    },
    {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
      address: getContractAddress(168587773, "AVAX"),
      pirceId: getPriceKey(168587773, 'KEY_AVAX'),
      imageUrl: AVAX_icon,
      isShortable: true,
      isLongable: true,
      tokenDecimals: 4,
      bgcolor: "#F9AF1B",
    },
    {
      name: "ORDI",
      symbol: "ORDI",
      decimals: 18,
      address: getContractAddress(168587773, "ORDI"),
      pirceId: getPriceKey(168587773, 'KEY_ORDI'),
      imageUrl: ORDI_icon,
      isShortable: true,
      isLongable: true,
      tokenDecimals: 4,
      bgcolor: "#F9AF1B",
    },
    {
      name: "LINK",
      symbol: "LINK",
      decimals: 18,
      address: getContractAddress(168587773, "LINK"),
      pirceId: getPriceKey(168587773, 'KEY_LINK'),
      imageUrl: LINK_icon,
      isShortable: true,
      isLongable: true,
      tokenDecimals: 4,
      bgcolor: "#F9AF1B",
    },
  ],
};

const ADDITIONAL_TOKENS = {};

const CHAIN_IDS = [421614, 168587773];
const TOKENS_MAP = {};
const TOKENS_BY_SYMBOL_MAP = {};
const TOKENS_UP_MAP = {};

for (let j = 0;j < CHAIN_IDS.length;j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  TOKENS_UP_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }
  if (WHITE_GNS_TOKEN_ADDRESS[chainId]) {
    tokens = tokens.concat(WHITE_GNS_TOKEN_ADDRESS[chainId]);
  }
  if (tokens && tokens.length > 0) {
    for (let i = 0;i < tokens.length;i++) {
      const token = tokens[i];
      TOKENS_MAP[chainId][token.address] = token;
      TOKENS_UP_MAP[chainId][token.address.toLowerCase()] = token;
      TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
    }
  }
}

const WRAPPED_TOKENS_MAP = {};
const NATIVE_TOKENS_MAP = {};
for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId) {
  return WRAPPED_TOKENS_MAP[chainId] ?? ethers.constants.AddressZero;
}

export function getNativeToken(chainId) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId) {
  return TOKENS[chainId];
}

export function isValidToken(chainId, address) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId, address) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}
export function getUpToken(chainId, address) {
  address = address?.toLowerCase();
  if (!TOKENS_UP_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_UP_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_UP_MAP[chainId][address];
}
export function getUpToken2(chainId, address) {
  address = address.toLowerCase();
  return TOKENS_UP_MAP[chainId][address];
}

export function getTokenBySymbol(chainId, symbol) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId) {
  return TOKENS[chainId].filter(token => token.symbol !== "USDX");
}

export function getWhitelistedTokens2(dlpName, chainId) {
  const tokens = TOKENS[chainId]
  const token1 = tokens.filter(token => token.isDlp1)
  const token2 = tokens.filter(token => token.isDlp2)
  return dlpName == DLP_TYPES.DLP_1 ? token1.filter(token => token.symbol !== "USDX") : token2.filter(token => token.symbol !== "USDX");
}

export function getTokenMeta(chainId, address) {
  let supportedTokens = TOKENS[chainId];
  const isGNS = WHITE_GNS_TOKEN_ADDRESS[chainId];
  let obj = supportedTokens
    .concat(isGNS)
    .find((token) => token.address.toLowerCase() === address.toLowerCase());

  return obj;
}

export function getPriceDecimals(chainId, tokenSymbol) {
  if (!tokenSymbol) return 2;
  try {
    const token = getTokenBySymbol(chainId, tokenSymbol);
    return token.tokenDecimals ?? 2;
  } catch (e) {
    return 2;
  }
}

