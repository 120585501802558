import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const BuyBtn = styled(Button)({
  height: "48px",
  lineHeight: "48px",
  maxHeight: "48px",
  width: "100%",
  color: "#FFFD02",
  fontSize: 14,
  fontFamily: "Gilroy-Bold",
  textTransform: "uppercase",
  background: "transparent",
  border: "1px solid",
  borderColor: "#FFFD02",
  borderRadius: "8px",
  ".browserIcon": {
    marginLeft: "12px",
  },
  "&:hover": {
    background: "rgba(255, 253, 2, 0.4)",
    color: "#FFFD02",
    ".browserIcon": {
      path: {
        fill: "#FFFD02",
        stroke: "#FFFD02",
      },
    },
  },
});
