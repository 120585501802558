import { useEffect, useRef, useState } from "react";
import { calTime2 } from "src/helpers";

type Fnc = () => void;
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const useCountDown = (endTime: number | string) => {
  const [time, setTime] = useState<any>({
    day: "00",
    hour: "00",
    minutes: "00",
    seconds: "00",
  });
  const tickRef = useRef<Fnc>(noop);
  const tick = () => {
    const nowDate = Math.floor(Date.now() / 1000);
    const countStartTime1 = Number(endTime) - nowDate;

    if (countStartTime1 > 0) {
      setTime(calTime2(Number(countStartTime1)));
    } else {
      setTime({
        day: "00",
        hour: "00",
        minutes: "00",
        seconds: "00",
      });
    }
  };

  useEffect(() => {
    tickRef.current = tick;
  });

  useEffect(() => {
    const timerId = setInterval(() => tickRef.current(), 1000);
    return () => clearInterval(timerId);
  }, []);

  return time;
};

export default useCountDown;
