import { Trans } from "@lingui/macro";
import { Box } from "@mui/material";
import { MintBtn } from "src/components/Button/MintBtn";
import { ThemedText } from "src/theme/text";
import styled from "styled-components/macro";
import { useEffect, useState } from "react";
import MintDiaolg from "../../MintDiaolg";
import { useHistory, useLocation } from "react-router";

const NoPidBox = styled(Box)`
  .NoPidBox {
    background: #222222;
    border-radius: 4px;
    padding: 38px 12px 24px 12px;
  }
`;

export default function NoPid({ showNo, handleCloses }: any) {
  const [showMint, setShowMint] = useState<boolean>(false);
  const history = useHistory();
  const handleClose = () => {
    setShowMint(false);
  };
  const location = useLocation();
  const handlerMint = (e) => {
    handleCloses && handleCloses();
    e.stopPropagation();
    setShowMint(true);
    if (location.pathname == "/DARMY") {
      setShowMint(true);
    } else {
      history.push({ pathname: "/DARMY", state: { showmint: true } });
    }
  };
  useEffect(() => {
    if (showNo && !showMint) {
      setShowMint(true);
    }
  }, [showMint, showNo]);

  return (
    <NoPidBox className="pid">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        className="NoPidBox"
      >
        <ThemedText.Text9 fontSize={16}>
          <Trans>You do not join D.ARMY yet</Trans>
        </ThemedText.Text9>
        <ThemedText.Text4 fontSize={12} mt="10px" mb="20px">
          <Trans>Join it to get bond & trade rebates</Trans>
        </ThemedText.Text4>
        <MintBtn onClick={handlerMint}>JOIN NOW</MintBtn>
      </Box>
      <MintDiaolg open={showMint} handleClose={handleClose} />
    </NoPidBox>
  );
}
