import { useDispatch } from "react-redux";
import { getPrices_gns, getPythPrices, getTokenPrice } from "src/services/api";
import { useAppSelector } from "../hooks";
import { priceList, priceList_gns, pythPrice } from "./actions";
import { useWeb3Context } from "src/hooks";
import { getTokens } from "src/configs/Tokens";
interface Response {
  code: number | string;
  data: any;
}
export function useQueryPrice() {
  const dispatch = useDispatch();
  // TODO  getTokenPrice // dexscreener price
  return () => {
    getTokenPrice().then((res: Response) => {
      const data: any = res || {};
      dispatch(priceList(data?.pair?.priceUsd || 0.8));
    });
  };
}

export function usePrices() {
  return useAppSelector(({ http: { price } }) => price);
}

export function useQueryPrice_gns() {
  const dispatch = useDispatch();
  return () => {
    getPrices_gns().then((res: Response) => {
      const data = res || [];
      dispatch(priceList_gns(data));
    });
  };
}

export function usePrices_gns() {
  return useAppSelector(({ http: { price_gns } }) => price_gns);
}

export function useQueryPythPrice() {
  const dispatch = useDispatch();
  const { chainID } = useWeb3Context();

  const tokens = getTokens(chainID).filter((x) => x.updataPrice);
  const idx = tokens.map((x) => x.pirceId);
  // TODO  getTokenPrice // dexscreener price
  return () => {
    getPythPrices(idx, chainID).then((res) => {
      if (res && res.priceData) {
        const data = tokens.reduce((curr, pre, index) => {
          curr[pre.address.toLocaleLowerCase()] = res.priceData[index];
          return curr;
        }, {});
        dispatch(pythPrice(data));
      }
    });
  };
}

export function usePyth_Price() {
  return useAppSelector(({ http: { pythPrice } }) => pythPrice);
}
