import styled from "styled-components";

const DotBox = styled.div<{
  bgColor?: string;
  height?: string;
  width?: string;
}>`
  .dot {
    width: ${({ width }) => (width ? width : "8px")};
    height: ${({ height }) => (height ? height : "8px")};
    background: ${({ bgColor }) => (bgColor ? bgColor : "#FDFC68")};
  }
`;
type dataPros = {
  bgColor?: string;
  height?: string;
  width?: string;
};

export default function Dots({ bgColor, height, width }: dataPros) {
  return (
    <DotBox bgColor={bgColor} height={height} width={width}>
      <div className="dot"></div>
    </DotBox>
  );
}
