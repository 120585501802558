import { useMemo } from "react";

import { addresses } from "../configs/constants";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
import { useSingleCallResult } from "src/lib/hooks/multicall";
import { BN, fromBN, toFromBN } from "src/utils/bn";
import {
  useDYNAContract,
  usePUSDBontract,
  useEsDYNAContract,
  useUSDBContract,
  useESDYNAStaingV2,
  useDIDContract,
  usePairContract,
  useDLPManagerContract,
  useDLPContract,
} from "./useContract";
import { useWeb3Context } from "./web3Context";
import { useARSEPriceUSD } from "./useQueryTre";
import { useDYNAMarketPrice } from "./useQueryLpBond";

export const usePUSDAccount = () => {
  const { address: account } = useWeb3Context();
  const PUSDBontract = usePUSDBontract();

  const { result } = useSingleCallResult(PUSDBontract, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const usePUSDTotalSupply = () => {
  const PUSDBontract = usePUSDBontract();

  const { result } = useSingleCallResult(PUSDBontract, "totalSupply");

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useDynaTotalSupply = () => {
  const DYNAContract = useDYNAContract();

  const { result } = useSingleCallResult(DYNAContract, "totalSupply");

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useBalance_DYNA = () => {
  const { address } = useWeb3Context();
  const DYNAContract = useDYNAContract();
  const { result } = useSingleCallResult(DYNAContract, "balanceOf", [
    address ? address : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

export const useBalance_esDYNA = () => {
  const { address: account } = useWeb3Context();
  const esDYNAContract = useEsDYNAContract();
  const { result } = useSingleCallResult(esDYNAContract, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useBalance_usdc = () => {
  const { address: account } = useWeb3Context();
  const contract_usdc = useUSDBContract();
  const { result } = useSingleCallResult(contract_usdc, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data, 6);
  }, [result]);
};
//todo: queryApr
export const useQueryEarnApr = () => {
  const esDYNAStakedAmount = useESTokenStakedV2Balance();
  return useMemo(() => {
    if (!esDYNAStakedAmount) return;
    return {
      esDYNAStaked: esDYNAStakedAmount,
    };
  }, [esDYNAStakedAmount]);
};
export const useUserBoostV2 = () => {
  const { address: account } = useWeb3Context();
  const DIDContract = useDIDContract();

  const { result: boostLevel } = useSingleCallResult(DIDContract, "boost", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!boostLevel) return;
    return BN(Number(boostLevel[0])).div(BN(Number(boostLevel[1])));
  }, [boostLevel]);
};

export const useESTokenTotalStaked = () => {
  const ESSTAKINGV2Contract = useESDYNAStaingV2();

  const { result: tvl } = useSingleCallResult(
    ESSTAKINGV2Contract,
    "totalDepositToken",
    []
  );
  return useMemo(() => {
    if (!tvl) return;
    return toFromBN(tvl[0]);
  }, [tvl]);
};

export const useDYNA_USDB_LiquidityInfo = () => {
  const { chainID } = useWeb3Context();
  const pairContract = usePairContract(addresses[chainID].DYNA_USDB_LP);
  const USDBContract = useUSDBContract();
  const { result: reserves } = useSingleCallResult(
    pairContract,
    "getReserves",
    []
  );
  const { result: totalSupply } = useSingleCallResult(
    pairContract,
    "totalSupply",
    []
  );
  const { result: token0Addr } = useSingleCallResult(
    pairContract,
    "token0",
    []
  );
  const { result: uDecimals } = useSingleCallResult(
    USDBContract,
    "decimals",
    []
  );
  return useMemo(() => {
    if (!token0Addr || !reserves || !totalSupply || !uDecimals) return;
    let uAmount;
    let dynaAmount;
    if (
      token0Addr.toString().toLowerCase() ==
      addresses[chainID].USDB.toLowerCase()
    ) {
      uAmount = reserves[0];
      dynaAmount = reserves[1];
    } else {
      uAmount = reserves[1];
      dynaAmount = reserves[0];
    }
    return {
      liqudityUSD: BN(uAmount.toString())
        .multipliedBy(2)
        .dividedBy(BN(10).pow(uDecimals[0]))
        .toString(),
      lpTotalSupply: totalSupply.toString(),
      uAmount: toFromBN(uAmount, uDecimals[0]).toString(),
      dynaAmount: toFromBN(dynaAmount).toString(),
    };
  }, [token0Addr, reserves, totalSupply, uDecimals, chainID]);
};

export const useDlpTotalValueUSD = () => {
  const managerContract = useDLPManagerContract();
  const { result: dlpUSD } = useSingleCallResult(
    managerContract,
    "getAumInUSD",
    [1]
  );
  return useMemo(() => {
    if (!dlpUSD) return;
    return toFromBN(dlpUSD[0], 30);
  }, [dlpUSD]);
};

export const useDlpTotalSupply = () => {
  const { chainID } = useWeb3Context();
  const dlpContract = useDLPContract(addresses[chainID].PLP_β);
  const { result: totalSupply } = useSingleCallResult(
    dlpContract,
    "totalSupply",
    []
  );
  return useMemo(() => {
    if (!totalSupply) return;
    return totalSupply[0];
  }, [totalSupply]);
};

export const useESTokenTotalStakedBoost = () => {
  const ESSTAKINGV2Contract = useESDYNAStaingV2();

  const { result: tvl } = useSingleCallResult(
    ESSTAKINGV2Contract,
    "totalBoostedSupply",
    []
  );
  return useMemo(() => {
    if (!tvl) return;
    return toFromBN(tvl[0]);
  }, [tvl]);
};
// 每秒奖励Tre,年化收益 * tre单价USD / （质押总量es*esPriceU ）
export const useESStakingAPR = () => {
  const ESSTAKINGV2Contract = useESDYNAStaingV2();
  const { result: rewardRate, error } = useSingleCallResult(
    ESSTAKINGV2Contract,
    "baseRewardPerSec_PREC",
    []
  );
  const arsePrice = useARSEPriceUSD();
  const totalStakedBoost = useESTokenTotalStakedBoost();
  const esDanaPrice = useDYNAMarketPrice();
  return useMemo(() => {
    if (!rewardRate || !esDanaPrice || !totalStakedBoost || !arsePrice) return;
    const yearRewardAmount = toFromBN(rewardRate[0], 38).multipliedBy(
      365 * 86400
    );
    if (totalStakedBoost.eq(0) || rewardRate[0].eq(0) || arsePrice == "0")
      return;
    return BN(arsePrice)
      .multipliedBy(yearRewardAmount)
      .div(BN(totalStakedBoost.toString()))
      .div(BN(esDanaPrice.toString()))
      .multipliedBy(100)
      .toString();
  }, [arsePrice, esDanaPrice, rewardRate, totalStakedBoost]);
};

// stakedEsValue
export const useCircLocked = () => {
  const dynaTotalSupply = useDynaTotalSupply();
  const totalStakedEsToken = useESTokenTotalStaked();
  return useMemo(() => {
    if (!dynaTotalSupply || !totalStakedEsToken) return;
    const frac = BN(dynaTotalSupply.toString()).plus(
      totalStakedEsToken.toString()
    );
    if (frac.eq(0)) return;
    return BN(totalStakedEsToken.toString()).div(frac).multipliedBy(100);
  }, [dynaTotalSupply, totalStakedEsToken]);
};

export const useESTokenStakedV2Balance = () => {
  const { address: account } = useWeb3Context();
  const ESSTAKINGV2Contract = useESDYNAStaingV2();

  const { result: treReward } = useSingleCallResult(
    ESSTAKINGV2Contract,
    "stakedToken",
    [account ? account : PLACEHOLDER_ACCOUNT]
  );
  return useMemo(() => {
    if (!treReward) return;
    return toFromBN(treReward[0]);
  }, [treReward]);
};

export const useESTokenStakedRewardsTre = () => {
  const { address: account } = useWeb3Context();
  const ESSTAKINGV2Contract = useESDYNAStaingV2();

  const { result: treReward } = useSingleCallResult(
    ESSTAKINGV2Contract,
    "claimable",
    [account ? account : PLACEHOLDER_ACCOUNT]
  );
  return useMemo(() => {
    if (!treReward) return;
    return toFromBN(treReward[0]);
  }, [treReward]);
};

export const useEstimateBoostDelta = (
  delatAmount: string,
  isIncrease: boolean
) => {
  const { address: account } = useWeb3Context();
  const DIDContract = useDIDContract();
  const { result } = useSingleCallResult(DIDContract, "estimatePointsDelta", [
    account ? account : PLACEHOLDER_ACCOUNT,
    delatAmount ? fromBN(delatAmount).toString() : undefined,
    isIncrease ? 1 : 0,
  ]);
  return useMemo(() => {
    if (!result || delatAmount == "") return;
    const data = result[1];
    return toFromBN(data, 6).toString();
  }, [delatAmount, result]);
};
