/* eslint-disable global-require */
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { Web3ContextProvider } from "./hooks/web3Context";
import App from "./App";
import store from "./store";
import { MulticallUpdater } from "src/lib/state/multicall";
import HttpUpdater from "src/state/http/updater";
import { BlockNumberProvider } from "src/lib/hooks/useBlockNumber";
import ThemeProvider, { ThemedGlobalStyle } from "./theme";
import { LanguageProvider } from "./i18n";
import { WagmiConfig } from "wagmi";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { chains, wagmiConfig } from "./configs/wagmiClient";

const Updaters = () => {
  return (
    <>
      <MulticallUpdater />
      <HttpUpdater />
    </>
  );
};

export default function Root() {
  return (
    <Provider store={store}>
      <HashRouter>
        <LanguageProvider>
          <WagmiConfig config={wagmiConfig}>
            <RainbowKitProvider chains={chains}>
              <Web3ContextProvider>
                <BlockNumberProvider>
                  <Updaters />
                  <ThemeProvider>
                    <ThemedGlobalStyle />
                    <App />
                  </ThemeProvider>
                </BlockNumberProvider>
              </Web3ContextProvider>
            </RainbowKitProvider>
          </WagmiConfig>
        </LanguageProvider>
      </HashRouter>
    </Provider>
  );
}
