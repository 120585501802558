import styled from "styled-components";
import TipsIcon from "src/assets/images/tips.svg";
import TipsActiveIcon from "src/assets/images/tips-active.svg";

const TipsBox = styled.div`
  .tips {
    width: 24px;
    height: 24px;
    background: url(${TipsIcon});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    &:hover {
      background: url(${TipsActiveIcon});
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  @media only screen and (max-width: 1280px) {
    width: 100%;
  }
`;

export const TipsIconBox = () => {
  return (
    <TipsBox>
      <div className="tips pointer"></div>
    </TipsBox>
  );
};
