import { useSingleCallResult } from "src/lib/hooks/multicall";
import { useTokenContract } from "./useContract";
import { useWeb3Context } from "./web3Context";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
import { useMemo } from "react";
import { toFromBN } from "src/utils/bn";

export function useTokenBalance(tokens?: any) {
  const { address } = useWeb3Context();

  const tokenContract = useTokenContract(tokens?.address);
  const { result } = useSingleCallResult(tokenContract, "balanceOf", [
    address ? address : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0], tokens?.decimals ?? 18);
  }, [result, tokens?.decimals]);
}

export function useAccountTokenBalance(tokensAddr: string, account: string) {
  const tokenContract = useTokenContract(tokensAddr);
  const { result } = useSingleCallResult(tokenContract, "balanceOf", [account]);
  return useMemo(() => {
    if (!result) return;
    return result[0];
  }, [result]);
}
