import React, { useEffect, useCallback, useMemo } from "react";
import { ethers } from "ethers";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import {
  USD_DECIMALS,
  MAX_LEVERAGE,
  BASIS_POINTS_DIVISOR,
  LIQUIDATION_FEE,
  formatAmount,
  getExplorerUrl,
  formatDateTime,
  deserialize,
  getExchangeRateDisplay,
  bigNumberify,
} from "../../helpers/Helpers";
import { useTrades, useLiquidationsData, useTradeHistory } from "../../Api";
import { getContractAddress } from "../../Addresses";

import "./TradeHistory.css";

import { getTokenMeta } from "../../configs/Tokens";
import { DefaultNoData } from "../NoData";
import Row from "../Row";
import Tooltips from "../Tooltips/totalTips";
import Column from "../Column";
import TooltipItem, { TooltipText } from "../TooltipItem";

const { AddressZero } = ethers.constants;

function getPositionDisplay(increase, indexToken, isLong, sizeDelta) {
  const symbol = indexToken ? (indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol) : "";
  return `
    ${increase ? "Increase" : "Decrease"} ${symbol} ${isLong ? "Long" : "Short"}
    ${increase ? "+" : "-"}${formatAmount(sizeDelta, USD_DECIMALS, 2, true)} USD`;
}

function getOrderActionTitle(action) {
  let actionDisplay;

  if (action.startsWith("Create")) {
    actionDisplay = "Create";
  } else if (action.startsWith("Cancel")) {
    actionDisplay = "Cancel";
  } else {
    actionDisplay = "Update";
  }

  return `${actionDisplay} Order`;
}

function renderLiquidationTooltip(liquidationData, label) {
  const minCollateral = liquidationData.size.mul(BASIS_POINTS_DIVISOR).div(MAX_LEVERAGE);
  const text =
    liquidationData.type === "full"
      ? "This position was liquidated as the max leverage of 100x was exceeded"
      : "Max leverage of 100x was exceeded, the remaining collateral after deducting losses and fees have been sent back to your account";
  return (
    <Tooltips
      position="right-bottom"
      handleClassName=""
      renderContent={
        <Column gap='12px' lineHeight="20px" className="font-14" >
          <TooltipText text={<div className="">{text}</div>} />
          <div className="toolBottom2">
            <TooltipItem label="Initial collateral" val={`$${formatAmount(liquidationData.collateral, USD_DECIMALS, 2, true)}`} />
          </div>
          <div className="toolBottom2">
            <TooltipItem label="Min required collateral" val={`$${formatAmount(minCollateral, USD_DECIMALS, 2, true)}`} />
          </div>
          <div className="toolBottom2">
            <TooltipItem label="Funding Fee" val={`$${formatAmount(liquidationData.borrowFee, USD_DECIMALS, 2, true)}`} />
          </div>
          <div className="toolBottom2">
            <TooltipItem label="PnL" val={`-$${formatAmount(liquidationData.loss, USD_DECIMALS, 2, true)}`} />
          </div>
          {liquidationData.type === "full" &&
            <div className="toolBottom2"><TooltipItem label="Liquidation fee" val={`$${formatAmount(LIQUIDATION_FEE, 30, 2, true)}`} /></div>
          }
        </Column>
      }>
      <span className="toolBottom">{label}</span>
    </Tooltips>
  );
}

function getLiquidationData(liquidationsDataMap, key, timestamp) {
  return liquidationsDataMap && liquidationsDataMap[`${key}:${timestamp}`];
}

export default function TradeHistory(props) {
  const { account, infoTokens, getTokenInfo, nativeTokenAddress, chainID } = props;


  const TradeListBox = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 18px 12px 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  line-height: 20px;
`;


  const trades = useTradeHistory(chainID, account);
  const liquidationsData = useLiquidationsData(chainID, account);
  const liquidationsDataMap = useMemo(() => {
    if (!liquidationsData) {
      return null;
    }
    return liquidationsData.reduce((memo, item) => {
      const liquidationKey = `${item.key}:${item.timestamp}`;
      memo[liquidationKey] = item;
      return memo;
    }, {});
  }, [liquidationsData]);

  const renderTime = (timestamp) => {
    return <div>{formatDateTime(timestamp)}</div>
  }

  const renderSymbol = (imageUrl, symbol) => {
    return <Row >
      <img src={imageUrl} height={30} />
      <div className="ml-8">{symbol}</div>
    </Row>
  }

  const renderSide = (isLong) => {
    return <div className={`${isLong ? "positive" : "negative"}`}>{isLong ? "Long" : "Short"}</div>
  }


  const getMsg = useCallback(
    (trade) => {

      if (trade.action === "Swap") {
        const tokenIn = getTokenMeta(chainID, trade.tokenIn);
        const tokenOut = getTokenMeta(chainID, trade.tokenOut);
        if (!tokenIn || !tokenOut) {
          return;
        }
        return {
          timestamp: trade.timestamp,
          imageUrl: tokenIn.imageUrl,
          symbol: tokenIn.symbol,
          sideStr: "Swap",
          typeStr: "Swap",
          priceDelta: "--",
          sizeDelta: `${formatAmount(trade.amountIn, tokenIn.decimals, 4, true)} ${tokenIn.symbol}`
        }
      }

      if (trade.action === "CreateIncreasePosition") {
        const indexToken = getTokenMeta(chainID, trade.indexToken);
        if (!indexToken) {
          return;
        }
        return {
          timestamp: trade.timestamp,
          imageUrl: indexToken.imageUrl,
          symbol: indexToken.symbol,
          sideStr: renderSide(trade.isLong),
          typeStr: bigNumberify(trade.sizeDelta).eq(0) ? "Request deposit" : "Request increase",
          priceDelta: bigNumberify(trade.sizeDelta).eq(0) ?
            <>--</>
            :
            <>{trade.isLong ? "<" : ">"} {formatAmount(trade.acceptablePrice, USD_DECIMALS, 2, true,)} USD</>,
          sizeDelta: bigNumberify(trade.sizeDelta).eq(0) ?
            <>--</>
            :
            <>{formatAmount(trade.sizeDelta, USD_DECIMALS, 2, true,)} USD</>
        }
      }

      if (trade.action === "CreateDecreasePosition") {
        const indexToken = getTokenMeta(chainID, trade.indexToken);
        if (!indexToken) {
          return;
        }
        return {
          timestamp: trade.timestamp,
          imageUrl: indexToken.imageUrl,
          symbol: indexToken.symbol,
          sideStr: renderSide(trade.isLong),
          typeStr: bigNumberify(trade.sizeDelta).eq(0) ? "Request withdrawal" : "Request decrease",
          priceDelta: bigNumberify(trade.sizeDelta).eq(0) ?
            <>--</>
            :
            <>{trade.isLong ? ">" : "<"} {formatAmount(trade.acceptablePrice, USD_DECIMALS, 2, true,)} USD</>,
          sizeDelta: bigNumberify(trade.sizeDelta).eq(0) ?
            <>--</>
            :
            <>{formatAmount(trade.sizeDelta, USD_DECIMALS, 2, true,)} USD</>
        }
      }

      if (trade.action === "CancelIncreasePosition") {
        const indexToken = getTokenMeta(chainID, trade.indexToken);
        if (!indexToken) {
          return;
        }
        return {
          timestamp: trade.timestamp,
          imageUrl: indexToken.imageUrl,
          symbol: indexToken.symbol,
          sideStr: renderSide(trade.isLong),
          typeStr: bigNumberify(trade.sizeDelta).eq(0) ? "Could not execute deposit" : "Could not increase",
          priceDelta:
            bigNumberify(trade.sizeDelta).eq(0) ?
              <div className="">--</div> :
              <div className="">
                {trade.isLong ? "<" : ">"}&nbsp;
                <Tooltips
                  position="left-top"
                  handleClassName=""
                  renderContent={
                    <>
                      <TooltipText text={<div className="">Try increasing the "Allowed Slippage", under the Settings menu on the top right</div>} />
                    </>
                  }>
                  <span className="toolBottom">{formatAmount(trade.acceptablePrice, USD_DECIMALS, 2, true)} USD</span>
                </Tooltips>
              </div>,
          sizeDelta: bigNumberify(trade.sizeDelta).eq(0) ?
            <div className="">--</div> :
            <div className="">
              +{formatAmount(trade.sizeDelta, USD_DECIMALS, 2, true)}
            </div>
        }
      }

      if (trade.action === "CancelDecreasePosition") {
        const indexToken = getTokenMeta(chainID, trade.indexToken);
        if (!indexToken) {
          return;
        }
        return {
          timestamp: trade.timestamp,
          imageUrl: indexToken.imageUrl,
          symbol: indexToken.symbol,
          sideStr: renderSide(trade.isLong),
          typeStr: bigNumberify(trade.sizeDelta).eq(0) ? "Could not execute withdrawal" : "Could not decrease",
          priceDelta: bigNumberify(trade.sizeDelta).eq(0) ?
            <div className="">--</div> :
            <div className="">
              {trade.isLong ? ">" : "<"}&nbsp;
              <Tooltips
                position="left-top"
                handleClassName=""
                renderContent={
                  <>
                    <TooltipText text={
                      <div className="">Try increasing the "Allowed Slippage", under the Settings menu on the top right</div>} />
                  </>
                }>
                <span className="toolBottom">{formatAmount(trade.acceptablePrice, USD_DECIMALS, 2, true)} USD</span>
              </Tooltips>
            </div>,
          sizeDelta: bigNumberify(trade.sizeDelta).eq(0) ?
            <div className="">--</div> :
            <div className="">
              +{formatAmount(trade.sizeDelta, USD_DECIMALS, 2, true)}
            </div>
        }
      }

      if (trade.action === "IncreasePosition-Long" || trade.action === "IncreasePosition-Short") {
        if (trade.flags?.isOrderExecution) {
          return;
        }
        const indexToken = getTokenMeta(chainID, trade.indexToken);
        if (!indexToken) {
          return;
        }
        return {
          timestamp: trade.timestamp,
          imageUrl: indexToken.imageUrl,
          symbol: indexToken.symbol,
          sideStr: renderSide(trade.isLong),
          typeStr: bigNumberify(trade.sizeDelta).eq(0) ? "Deposit" : "Increase",
          priceDelta: <>{bigNumberify(trade.sizeDelta).eq(0) ? "--" : formatAmount(trade.acceptablePrice, USD_DECIMALS, 2, true)} USD</>,
          sizeDelta: bigNumberify(trade.sizeDelta).eq(0) ?
            <div className="">{formatAmount(trade.collateralDelta, USD_DECIMALS, 2, true)} USD</div>
            :
            <div className="">+{formatAmount(trade.sizeDelta, USD_DECIMALS, 2, true,)} USD</div>
        }
      }

      if (trade.action === "DecreasePosition-Long" || trade.action === "DecreasePosition-Short") {
        if (trade.flags?.isOrderExecution) {
          return;
        }
        const indexToken = getTokenMeta(chainID, trade.indexToken);
        if (!indexToken) {
          return;
        }
        const isLiquidation = trade.flags?.isLiquidation;
        const liquidationData = getLiquidationData(liquidationsDataMap, trade.key, trade.timestamp);
        const actionDisplay = isLiquidation ? "Partially Liquidated" : "Decreased";

        return {
          timestamp: trade.timestamp,
          imageUrl: indexToken.imageUrl,
          symbol: indexToken.symbol,
          sideStr: renderSide(trade.isLong),
          typeStr: isLiquidation && liquidationData ? renderLiquidationTooltip(liquidationData, "Partial Liquidation") : actionDisplay,
          priceDelta: <>{formatAmount(trade.acceptablePrice, USD_DECIMALS, 2, true)} USD</>,
          sizeDelta: bigNumberify(trade.sizeDelta).eq(0) ?
            <>
              {formatAmount(trade.collateralDelta, USD_DECIMALS, 2, true)} USD
            </>
            : <>
              -{formatAmount(trade.sizeDelta, USD_DECIMALS, 2, true)} USD
            </>
        }
      }

      if (trade.action === "LiquidatePosition-Long" || trade.action === "LiquidatePosition-Short") {
        const indexToken = getTokenMeta(chainID, trade.indexToken);
        if (!indexToken) {
          return;
        }
        const liquidationData = getLiquidationData(liquidationsDataMap, trade.key, trade.timestamp);
        return {
          timestamp: trade.timestamp,
          imageUrl: indexToken.imageUrl,
          symbol: indexToken.symbol,
          sideStr: renderSide(trade.isLong),
          typeStr: liquidationData ? renderLiquidationTooltip(liquidationData, "Liquidated") : "Liquidated",
          priceDelta: <>{formatAmount(trade.markPrice, USD_DECIMALS, 2, true)} USD</>,
          sizeDelta: <>-{formatAmount(trade.size, USD_DECIMALS, 2, true)} USD</>
        }
      }

      if (["ExecuteIncreaseOrder", "ExecuteDecreaseOrder"].includes(trade.action)) {
        const order = deserialize(trade.order);
        const indexToken = getTokenMeta(chainID, trade.indexToken);
        if (!indexToken) {
          return;
        }
        const executionPriceDisplay = formatAmount(trade.executionPrice, USD_DECIMALS, 2, true);
        const sizeDeltaDisplay = `${trade.type === "Increase" ? "+" : "-"}${formatAmount(
          trade.sizeDelta,
          USD_DECIMALS,
          2,
          true,
        )}`;

        return {
          timestamp: trade.timestamp,
          imageUrl: indexToken.imageUrl,
          symbol: indexToken.symbol,
          sideStr: renderSide(trade.isLong),
          typeStr: 'Execute Order',
          priceDelta: <>{executionPriceDisplay} USD</>,
          sizeDelta: <>{sizeDeltaDisplay} USD</>
        }
      }

      if (["CreateIncreaseOrder", "CancelIncreaseOrder", "UpdateIncreaseOrder", "CreateDecreaseOrder", "CancelDecreaseOrder", "UpdateDecreaseOrder",].includes(trade.action)) {
        const indexToken = getTokenMeta(chainID, trade.indexToken);
        if (!indexToken) {
          return;
        }
        const increase = trade.action.includes("Increase");
        const priceDisplay = `${trade.triggerAboveThreshold ? ">" : "<"} ${formatAmount(
          trade.triggerPrice,
          USD_DECIMALS,
          2,
          true,
        )}`;
        return {
          timestamp: trade.timestamp,
          imageUrl: indexToken.imageUrl,
          symbol: indexToken.symbol,
          sideStr: renderSide(trade.isLong),
          typeStr: getOrderActionTitle(trade.action),
          priceDelta: priceDisplay,
          sizeDelta: getPositionDisplay(increase, indexToken, trade.isLong, trade.sizeDelta)
        }
      }

      if (trade.action === "ExecuteSwapOrder") {
        const nativeTokenAddress = getContract(chainID, "NATIVE_TOKEN");
        const fromToken = getTokenInfo(infoTokens, trade.path[0] === nativeTokenAddress ? AddressZero : trade.path[0]);
        const toToken = getTokenInfo(infoTokens, trade.shouldUnwrap ? AddressZero : trade.path[trade.path.length - 1]);
        if (!fromToken || !toToken) {
          return;
        }
        const fromAmountDisplay = formatAmount(trade.amountIn, fromToken.decimals, fromToken.isStable ? 2 : 4, true);
        const toAmountDisplay = formatAmount(trade.amountOut, toToken.decimals, toToken.isStable ? 2 : 4, true);
        return {
          timestamp: trade.timestamp,
          imageUrl: indexToken.imageUrl,
          symbol: indexToken.symbol,
          sideStr: renderSide(trade.isLong),
          typeStr: "Execute Order: Swap",
          priceDelta: "--",
          sizeDelta: <>{fromAmountDisplay} {fromToken.symbol}</>
        }
      }

      if (["CreateSwapOrder", "UpdateSwapOrder", "CancelSwapOrder"].includes(trade.action)) {
        const order = deserialize(trade.order);
        const nativeTokenAddress = getContract(chainID, "NATIVE_TOKEN");
        const fromToken = getTokenInfo(infoTokens, trade.path[0] === nativeTokenAddress ? AddressZero : trade.path[0]);
        const toToken = getTokenInfo(infoTokens, trade.shouldUnwrap ? AddressZero : trade.path[trade.path.length - 1]);
        if (!fromToken || !toToken) {
          return;
        }
        const amountInDisplay = fromToken
          ? formatAmount(trade.amountIn, fromToken.decimals, fromToken.isStable ? 2 : 4, true)
          : "";
        const minOutDisplay = toToken
          ? formatAmount(trade.minOut, toToken.decimals, toToken.isStable ? 2 : 4, true)
          : "";
        return {
          timestamp: trade.timestamp,
          imageUrl: indexToken.imageUrl,
          symbol: indexToken.symbol,
          sideStr: renderSide(trade.isLong),
          typeStr: getOrderActionTitle(trade.action),
          priceDelta: getExchangeRateDisplay(trade.triggerRatio, fromToken, toToken),
          sizeDelta: "--"
        }
      }
    },
    [getTokenInfo, infoTokens, nativeTokenAddress, chainID, liquidationsDataMap],
  );

  const tradesWithMessages = useMemo(() => {
    if (!trades) {
      return [];
    }
    return trades
      .map(trade => ({
        msg: getMsg(trade),
        ...trade,
      }))
      .filter(trade => trade.msg);
  }, [trades, getMsg]);

  return (
    <div className="TradeHistory">
      <div className="Exchange-list small">
        {tradesWithMessages?.length > 0 &&
          tradesWithMessages.map((trade, i) => {
            const txUrl = getExplorerUrl(chainID) + "tx/" + trade.txhash;
            const obj = getMsg(trade);
            return <a className="plain" href={txUrl} target="_blank" rel="noopener noreferrer">
              <TradeListBox key={i}>
                <div className="color9 font-14 font-weight-6">{formatDateTime(obj.timestamp)}</div>
                <Row className="mt-18">
                  <Row className="flex-1">
                    <img src={obj.imageUrl} height={30} />
                    <div className="ml-8 font-weight-6">
                      <div className="font-16">{obj.symbol}</div>
                      <div className="">{obj.sideStr}</div>
                    </div>
                  </Row>
                  <div className="flex-1">
                    <div className=" font-weight-b color9">size</div>
                    <div className="mt-4 font-14 font-weight-6">
                      {obj.sizeDelta}
                    </div>
                  </div>
                </Row>
                <Row className="mt-18">
                  <div className="flex-1">
                    <div className=" font-weight-b color9">type</div>
                    <div className="mt-4 font-14 font-weight-6">
                      {obj.typeStr}
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className=" font-weight-b color9">price</div>
                    <div className="mt-4 font-14 font-weight-6">
                      {obj.priceDelta}
                    </div>
                  </div>
                </Row>
              </TradeListBox>
            </a>
          }
          )
        }
      </div>
      <table className="Exchange-list large App-box">
        <tbody>
          <tr className="Exchange-list-header">
            <th>Time</th>
            <th>Symbol</th>
            <th>Side</th>
            <th>Type</th>
            <th>Price</th>
            <th>Size</th>
          </tr>
          {tradesWithMessages?.length > 0 &&
            tradesWithMessages.map((trade, i) => {
              const txUrl = getExplorerUrl(chainID) + "tx/" + trade.txhash;
              const obj = getMsg(trade);
              return <tr key={i}>
                <td>
                  <a className="plain" href={txUrl} target="_blank" rel="noopener noreferrer">
                    {renderTime(obj.timestamp)}
                  </a>
                </td>
                <td>
                  <a className="plain" href={txUrl} target="_blank" rel="noopener noreferrer">
                    {renderSymbol(obj.imageUrl, obj.symbol)}
                  </a>
                </td>
                <td>
                  <a className="plain" href={txUrl} target="_blank" rel="noopener noreferrer">
                    {obj.sideStr}
                  </a>
                </td>
                <td>
                  <a className="plain" href={txUrl} target="_blank" rel="noopener noreferrer">
                    {obj.typeStr}
                  </a>
                </td>
                <td>
                  <a className="plain" href={txUrl} target="_blank" rel="noopener noreferrer">
                    {obj.priceDelta}
                  </a>
                </td>
                <td>
                  <a className="plain" href={txUrl} target="_blank" rel="noopener noreferrer">
                    {obj.sizeDelta}
                  </a>
                </td>
              </tr>
            }
            )
          }
        </tbody>
      </table >
      {tradesWithMessages?.length === 0 && <DefaultNoData title="No trades yet" />
      }
    </div>
  );
}
