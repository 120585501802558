import { setAll } from "../helpers";
import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IBaseAddressAsyncThunk } from "./interfaces";
import { addresses } from "../configs/constants";
import { ethers } from "ethers";
import { abi as DisTokenABI } from "../abis/DisToken.json";
import { abi as DYNAStakeABI } from "../abis/DYNAStake.json";
import moment from "moment";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
import { getDynaPrice } from "src/hooks/get_price_dyna";

const getEndTime = async (
  networkID: number,
  address: string,
  signer: ethers.Signer | ethers.providers.Provider | undefined
) => {
  const veDYNAContract = new ethers.Contract(
    addresses[networkID].veDYNA,
    DYNAStakeABI,
    signer
  );
  const lockedInfo = await veDYNAContract.locked(
    address || PLACEHOLDER_ACCOUNT
  );
  const currtimestamp = moment().valueOf();
  const currtimestampS = (currtimestamp / 1000).toFixed(0);
  const endTime = Number(lockedInfo[1]) - Number(currtimestampS);
  return endTime;
};

export const loadEarnDetails = createAsyncThunk(
  "earn/loadEarn",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {
    // console.log("loadEarnDetails");

    const veDYNAContract = new ethers.Contract(
      addresses[networkID].veDYNA,
      DYNAStakeABI,
      provider
    );
    const veDYNAContractold = new ethers.Contract(
      addresses[networkID].oldveDYNA,
      DYNAStakeABI,
      provider
    );
    const veDYNABalance = Number(
      ethers.utils.formatUnits(
        await veDYNAContract.balanceOfAtNow(address || PLACEHOLDER_ACCOUNT),
        18
      )
    );

    const allveDYNABalance = veDYNABalance;

    const DisTokenContract = new ethers.Contract(
      addresses[networkID].dis_token,
      DisTokenABI,
      provider
    );

    const weekRewards_dyna = Number(
      ethers.utils.formatUnits(await DisTokenContract.getYieldForDuration(), 18)
    );
    const DisFeeContract = new ethers.Contract(
      addresses[networkID].dis_fee,
      DisTokenABI,
      provider
    );

    const weekRewards_usdc = Number(
      ethers.utils.formatUnits(await DisFeeContract.getYieldForDuration(), 18)
    );

    const totalSupply_veDYNA = Number(
      ethers.utils.formatUnits(await veDYNAContract.totalSupplyAtNow(), 18)
    );

    const totalSupply_veDYNA_old = Number(
      ethers.utils.formatUnits(await veDYNAContractold.totalSupplyAtNow(), 18)
    );

    const all_totalSupply_veDYNA = totalSupply_veDYNA + totalSupply_veDYNA_old;

    const DYNAPrice = await getDynaPrice({ networkID, provider });

    let dynaApr1 = 0;
    let dynaApr2 = 0;
    let usdcApr1 = 0;
    let usdcApr2 = 0;
    // console.log("weekRewards_usdc", weekRewards_usdc, "totalSupply_veDYNA", totalSupply_veDYNA)
    if (weekRewards_dyna > 0 && all_totalSupply_veDYNA > 0) {
      dynaApr1 = ((weekRewards_dyna / 7) * 365) / all_totalSupply_veDYNA; //*(1 + 3 / 4 / 365 * 7)
      dynaApr2 = (((weekRewards_dyna / 7) * 365) / all_totalSupply_veDYNA) * 4;
    }
    if (weekRewards_usdc > 0 && all_totalSupply_veDYNA > 0) {
      usdcApr1 =
        ((weekRewards_usdc / DYNAPrice / 7) * 365) / all_totalSupply_veDYNA; //* (1 + 3 / 4 / 365 * 7)
      usdcApr2 =
        (((weekRewards_usdc / DYNAPrice / 7) * 365) / all_totalSupply_veDYNA) *
        4;
    }
    // console.log("dynaApr1", dynaApr1, "dynaApr2", dynaApr2, "usdcApr1", usdcApr1, "usdcApr2", usdcApr1)
    const minApr = dynaApr1 + usdcApr1;
    const maxApr = dynaApr2 + usdcApr2;

    const lockedInfo = await veDYNAContract.locked(
      address || PLACEHOLDER_ACCOUNT
    );
    const DYNAStakedAmount = Number(
      ethers.utils.formatUnits(lockedInfo[0], 18)
    );
    const dynaApr = (allveDYNABalance / DYNAStakedAmount) * dynaApr1; // / (1 + 3 / 4 / 365 * 7)
    const usdcApr = (allveDYNABalance / DYNAStakedAmount) * usdcApr1; // / (1 + 3 / 4 / 365 * 7)
    const estApr = dynaApr + usdcApr;

    const endTime = await getEndTime(
      networkID,
      address || PLACEHOLDER_ACCOUNT,
      provider
    );

    return {
      veDYNABalance: allveDYNABalance,
      dynaApr1,
      dynaApr2,
      usdcApr1,
      usdcApr2,
      minApr,
      maxApr,
      dynaApr,
      usdcApr,
      estApr,
      endTime,
    };
  }
);

const initialState = {
  loading: false,
};

const earnSlice = createSlice({
  name: "earn",
  initialState,
  reducers: {
    fetchEarnSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadEarnDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadEarnDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadEarnDetails.rejected, (state, {}) => {
        state.loading = false;
      });
  },
});

const baseInfo = (state: RootState) => state.earn;
export default earnSlice.reducer;
export const { fetchEarnSuccess } = earnSlice.actions;
export const getEarnState = createSelector(baseInfo, (earn) => earn);
