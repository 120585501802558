import { ReactComponent as ICON_F } from "../assets/images/rank/F.svg";
import { ReactComponent as ICON_E } from "../assets/images/rank/E.svg";
import { ReactComponent as ICON_D } from "../assets/images/rank/D.svg";
import { ReactComponent as ICON_C } from "../assets/images/rank/C.svg";
import { ReactComponent as ICON_B } from "../assets/images/rank/B.svg";
import { ReactComponent as ICON_A } from "../assets/images/rank/A.svg";
import { ReactComponent as ICON_S } from "../assets/images/rank/S.svg";
import { ReactComponent as ICON_SS } from "../assets/images/rank/SS.svg";

export const RANK_LIST = { 0: "F", 1: "E", 2: "D", 3: "C", 4: "B", 5: "A", 6: "S", 7: "SS" }
export const RANK_LIST_ICON = { 0: <ICON_F width="16px" height="22px" />, 1: <ICON_E width="16px" height="22px" />, 2: <ICON_D width="16px" height="22px" />, 3: <ICON_C width="16px" height="22px" />, 4: <ICON_B width="16px" height="22px" />, 5: <ICON_A width="16px" height="22px" />, 6: <ICON_S width="16px" height="22px" />, 7: <ICON_SS width="16px" height="22px" /> }
export const RANK_LIST_POINTS = { 0: 0, 1: 100, 2: 1000, 3: 5000, 4: 20000, 5: 60000, 6: 150000, 7: 300000 }
export const RANK_LIST_REBATES = { 0: 5, 1: 6, 2: 7, 3: 8, 4: 9, 5: 11, 6: 13, 7: 15 }
export const TYPE_CODE = {
  "0": "Mint DID",
  "1": "Open/Close Position",
  "11": "Open/Close Position (Invitee)",
  "101": "Open/Close Position",
  "111": "Open/Close Position (Invitee)",
  "2": "Swap",
  "12": "Swap (Invitee)",
  "3": "Mint DLP",
  "13": "MInt DLP (Invitee)",
  "103": "Redeem DLP",
  "113": "Redeem DLP (Invitee)",
  "999": "Monthly Deduction",
  "9": "Burn esDYNA",
}