interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  168587773: {
    pyth: "0xA2aa501b19aff244D90cc15a4Cf739D2725B5729",
    VaultPriceFeed: "0x4f5c7ACf95aF3dB9c66Cce74AfA7428873fCd1E0",
    NATIVE_TOKEN: "0x4200000000000000000000000000000000000023",
    WETH: "0x4200000000000000000000000000000000000023",
    BTC: "0xDdd8B9c93338Cdf4C79fd8D201Caff0295D467D4",
    // USDB: "0x205D72F677e6Da7aBF98Ede9C074281929758305",
    USDB: "0x4200000000000000000000000000000000000022",

    SOL: "0x927Ab8dA30A404152C77584A21C0263D69303De3",
    AVAX: "0x77bbFdD503670dcE7b797B9606C539e7C42fbD33",
    BNB: "0xc59D5f42afeeaDE5f845961C6e0759a45A589461",
    ARB: "0xfdfd68cDF46EA8f3AE95be3DbC0430fBa668d37b",
    ORDI: "0x9098EFaD63DaD2c1eE970cEA27438e40b12Ee7aE",
    LINK: "0xC8955a1Da280a8A8b31e9aDde351EDFe086Cc0DA",

    DYNA: "0x7C2692B6ff27D4F4897B860018eF32F43F0c9beA",
    esDYNA: "0x89d014381e49B269B130683d6D0dB44FB7589A7f",
    ARSE: "0x06dF3e51676ec36673aeD7974B2d170270c4185b",

    univ2Router: "0x14e554F49836F5d50000aacC67f92199164c4E01",
    AlgebraInterfaceMulticall: "0x4969f77a90E21a222C8e10c6A3b7F911df32adB8",

    DID: "0xFE43A4B1dbFfA12D080410ef6386861AF2831683",
    DIDHelper: "0xF6a242fEddFF27E617D1Ffd99ACdC69CB99686E9",
    TradeRebate: "0x39273cE48338Bf78AFa09aabcCa4D9484E1FBD44",
    Treasury: "0x7c3B7Db7827A3Dc734Ab7cF90dc507d136AB8162",
    TreasuryResv: "0x7d7FCFce0D1A346A98f3489f80aD149c9b1EaF86",
    RedeemTreasury: "0xd65c672afD8c5cEfa65F5Cc1e49536835a3D9AF1",
    ARSEStaking: "0x309dEa8079f64E47e6f070BF6027a42E099a001B",
    esDYNAStaking: "0x6E4714777A3C1e9e37049A23682181195530c129",
    TokenVesting: "0xC44F61E968614aE987bbBF6507c115342B0ee228",
    Reader: "0x4D611B5c3389269163C8B6FAAF6e7356cb016C96",
    VaultReader: "0x5E9c10b105d989476BB1c2Ca4b64085Bf93EBcE6",
    PositionReader: "0x3777dDF1ead40cfA0f97F0ac39905728817a4291",

    PLP_β: "0xC9A7cbB1FaB7672cF280DF56CEDc5eaA543D7216",
    Vault_PLP_β: "0x09393b00366EAa531fDf083Ae64c9745AB6246B2",
    VaultUtils_PLP_β: "0xe7883AB0Bf49e8F28307cFdc9e71893AcFe3BcC6",
    VaultStorage_PLP_β: "0xF3A0dd5e210e9d90b93366Ed51DF9ADBFF1ddffF",
    TradeStorage_PLP_β: "0xd7714f06fA224c7B99f1D6D347861d36cc808F6b",
    OrderBook_PLP_β: "0xf47EC7BBE577a6bd63B01C0ff1D50a7dbd642a78",
    DlpManager_PLP_β: "0x2B85f96880fD92AF318A23a458A310b17023a0a6",
    RouterSign_PLP_β: "0xfACF766D27Ca77F9FE4f38e962bbe8AaC5c558Da",
    FeeRouter_PLP_β: "0x4A5493B1a99c6cA0B0e8926eA5F244Fd221279df",

    LpBond: "0x3d5659187DD36268Df21F8AE8D829c16626Cde9e",
    DYNAUSDBLpToken: "0xB68031fa0dE549fcB5a935dE8222Ed501756A4f3",
    DYNA_USDB_LP: "0xB68031fa0dE549fcB5a935dE8222Ed501756A4f3",

    faucet: "0x84370fA3e72cC1c944b160a790B42dFF3C2242ef",
  },
  /*
     "DID" : "0xE9a650E9e598c167851840AEC4D86D15684f2B6b",
    "DIDHelper" : "0x4D00BAbC00d5B898EFFa185b677A0fcA2bCB385a",
    "TradeRebate" : "0xDAac88083FabFe0857c24543570C6aF7A66f1931",
    "ARSEStaking" : "0xfa0c6C70EE3A21BE4183C756058Db9EFF7dd0405",
    "esDYNAStaking" : "0xB7DF003eE19556946e6244f163583643414fF9F6",
    "TokenVesting" : "0x48EfF97Ad61B76f0bDFC6048D320441C2ab8122B",

    "DYNABondDepository" : "0x0F07E08cef2f2746737202aDd6934E7ac90602e7",
  */
  421614: {
    pyth: "0xA2aa501b19aff244D90cc15a4Cf739D2725B5729",
    VaultPriceFeed: "0x4f5c7ACf95aF3dB9c66Cce74AfA7428873fCd1E0",
    NATIVE_TOKEN: "0x4200000000000000000000000000000000000023",
    WETH: "0x4200000000000000000000000000000000000023",
    BTC: "0xDdd8B9c93338Cdf4C79fd8D201Caff0295D467D4",
    USDB: "0x4200000000000000000000000000000000000022",
    DYNA: "0x7C2692B6ff27D4F4897B860018eF32F43F0c9beA",
    esDYNA: "0x89d014381e49B269B130683d6D0dB44FB7589A7f",
    ARSE: "0x06dF3e51676ec36673aeD7974B2d170270c4185b",

    univ2Router: "0xF3C241c878394F7edcB0B6E3Ce17D2601023c9D5",
    AlgebraInterfaceMulticall: "0x6223dA5df2fE02B5e9e793A5f18d75F4167C9aB1",

    DID: "0x71Dc620ba5E583e9D64A98804baA91ff3370993d",
    DIDHelper: "0x00599642F2FB4B986FA010Be58cAe8AB863f0Cf7",
    TradeRebate: "0xfFEE5E24F27bcAF8d839d9B0D42eF99A868051EC",
    Treasury: "0xe9baD94e0D8489a257fC2B7cb06830B9dcB7516f",
    TreasuryResv: "0x49643Ad59D9f9D539143Ac54920DfB9912813A00",
    RedeemTreasury: "0x00808406Ac72E8334C377dE89b0518630456E782",
    ARSEStaking: "0x071073D4F7Da38f068b020b56973d3FEB58Fd78E",
    esDYNAStaking: "0x1D683e39bc48e68b8790A308eBD8b55ad98bf62d",
    DYNAStaking: "0x1D683e39bc48e68b8790A308eBD8b55ad98bf62d",
    Reader: "0x6588E0A87Ee5c1dD222a89Ad9e1A14d80EE96be4",
    VaultReader: "0x8e186db468Cf21EC65CB5F36851Df626B10E1fF5",
    PositionReader: "0x3777dDF1ead40cfA0f97F0ac39905728817a4291",

    PLP_β: "0x9f689DB23B5ccC926E78749E0A8aEe3343eE21ab",
    Vault_PLP_β: "0x843A9f69739e88EEEBE791D330b67E70D39ef566",
    VaultUtils_PLP_β: "0x684F7C588Ad0b63A955F21D44B3f37bAc3E8d37E",
    VaultStorage_PLP_β: "0xA5DFA2028d32f53AB71d27024051326fE9723749",
    TradeStorage_PLP_β: "0xDf8B4eb859876d71970CF5Acf60A867FeEB5822b",
    OrderBook_PLP_β: "0x5b7e312C47E499A761AeC30647D0E1314bBe9C44",
    DlpManager_PLP_β: "0xce6B53808a5C9CBcaE1977BDB8C965812C4ad751",
    RouterSign_PLP_β: "0x8b5C99DfEa719Bd02593fc1B0B093cEA3bF9b787",
    FeeRouter_PLP_β: "0x13841A837a499801e5DEf33b0798bc70A1F06980",

    LpBond: "0x590070198Ca783a3350F3f0d25bBcFe8C566DFA1",
    DYNA_USDB_LP: "0xB68031fa0dE549fcB5a935dE8222Ed501756A4f3",
    TokenVesting: "0xFdCCF0FfE71fA46Eb7EAf86407eA659ea5aC671D",
  },
};

export const PRICE_KEY = {
  [421614]: {
    KEY_WETH:
      "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
    KEY_ETH:
      "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
    KEY_BTC:
      "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
    KEY_USDB:
      "0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a",
    KEY_ARB:
      "0x3fa4252848f9f0a1480be62745a4629d9eb1322aebab8a791e344b3b9c1adcf5",
  },
  [168587773]: {
    KEY_BTC:
      "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
    KEY_ETH:
      "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
    KEY_WETH:
      "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
    KEY_USDB:
      "0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a",
    KEY_SOL:
      "0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d",
    KEY_AVAX:
      "0x93da3352f9f1d105fdfe4971cfa80e9dd777bfc5d0f683ebb6e1294b92137bb7",
    KEY_BNB:
      "0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
    KEY_ARB:
      "0x3fa4252848f9f0a1480be62745a4629d9eb1322aebab8a791e344b3b9c1adcf5",
    KEY_ORDI:
      "0x193c739db502aadcef37c2589738b1e37bdb257d58cf1ab3c7ebc8e6df4e3ec0",
    KEY_LINK:
      "0x8ac0c70fff57e9aefdf5edf44b51d62c2d433653cbb2cf5cc06bb115af04d221",
  },
};
