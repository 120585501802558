import { ethers } from "ethers";

const { parseEther } = ethers.utils;

const constants = {
  [421614]: {
    nativeTokenSymbol: "ETH",
    defaultCollateralSymbol: "USDB",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: false,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
  },
  [168587773]: {
    nativeTokenSymbol: "ETH",
    defaultCollateralSymbol: "USDB",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: false,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
  },
};

export const getConstant = (chainId, key) => {
  if (!constants[chainId]) {
    throw new Error(`Unsupported chainId ${chainId}`);
  }
  if (!(key in constants[chainId])) {
    throw new Error(`Key ${key} does not exist for chainId ${chainId}`);
  }
  return constants[chainId][key];
};
