import { createAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { setAll } from "../helpers";
import { RootState } from "../store";

export const loadAppDetails = createAction<any>("app/loadAppDetails");

const initialState = {
  loading: true,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadAppDetails, (state, { payload }) => {
      if (payload.networkID) return;
      setAll(state, payload);
      state.loading = false;
    });
  },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, (app) => app);
