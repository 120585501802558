import { AddressEnvMap, SupportedChainId } from "./chains.base";
export const SUPPORTED_CHAIN_IDS_MAP: AddressEnvMap = {
  // [SupportedChainId.ZKTEST]: SupportedChainId.ZKTEST,
  // [SupportedChainId.MANTLE_TEST]: SupportedChainId.MANTLE_TEST,

  [SupportedChainId.BLAST_TEST]: SupportedChainId.BLAST_TEST,
};

export const SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  // SupportedChainId.ZKTEST,
  // SupportedChainId.MANTLE_TEST,

  SupportedChainId.BLAST_TEST,
];
