import { SupportedLocale } from "src/constants/locales";
import { initialLocale, useActiveLocale } from "src/hooks/useActiveLocale";
import { dynamicActivate, Provider } from "src/lib/i18n";
import { ReactNode, useCallback } from "react";
// import { useUserLocaleManager } from 'state/user/hooks'

dynamicActivate(initialLocale);

export function LanguageProvider({ children }: { children: ReactNode }) {
  const locale = useActiveLocale();
  // const [, setUserLocale] = useUserLocaleManager();

  const onActivate = useCallback((locale: SupportedLocale) => {
    document.documentElement.setAttribute("lang", "en-US");
    // setUserLocale(locale) // stores the selected locale to persist across sessions
  }, []);

  return (
    <Provider
      locale={locale}
      forceRenderAfterLocaleChange={false}
      onActivate={onActivate}
    >
      {children}
    </Provider>
  );
}
