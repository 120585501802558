import { ethers } from "ethers";
import { useEthersSigner, useWeb3Context } from "./web3Context";
import { abi as IERC20ABI } from "../abis/IERC20.json";
import { useCallback, useMemo } from "react";
import { clearPendingTxn, fetchPendingTxns } from "src/slices/PendingTxnsSlice";
import { useDispatch } from "react-redux";
import { toastSuccess } from "src/helpers/toastHelpers";
import { useTokenContract } from "./useContract";
import { useSingleCallResult } from "src/lib/hooks/multicall";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
import { useUpDataBlockNumber } from "src/lib/hooks/useUpDataBlockNumber";
import { BN } from "src/utils/bn";
import { handlerError } from "src/helpers/errorHelpers";
export default function useTokenApprove() {
  const { address, provider } = useWeb3Context();
  const dispatch = useDispatch();
  const signer = useEthersSigner();
  const getERC20Approved = async (
    tokenAddress: string,
    spenderAddress: string
  ) => {
    const tokenContract = new ethers.Contract(
      tokenAddress,
      IERC20ABI,
      provider
    );
    if (!tokenAddress || !spenderAddress) return false;

    const allowance = await tokenContract.allowance(
      address ? address : PLACEHOLDER_ACCOUNT,
      spenderAddress
    );
    return Number(allowance) > 0;
  };

  const approveToken = useCallback(
    async (tokenAddress: string, spenderAddress: string) => {
      if (provider && address) {
        let tx;
        try {
          const tokenContract = new ethers.Contract(
            tokenAddress,
            IERC20ABI,
            signer
          );
          tx = await tokenContract.approve(
            spenderAddress,
            ethers.constants.MaxUint256
          );
          dispatch(
            fetchPendingTxns({
              txnHash: tx.hash,
              text: "Approve",
              type: "approveToken",
            })
          );
          await tx.wait();
          return tx;
        } catch (e: unknown) {
          handlerError(e);
        } finally {
          if (tx) {
            dispatch(clearPendingTxn(tx.hash));
            toastSuccess("Approve Success!", tx.hash);
          }
        }
      }
    },
    [address, dispatch, provider]
  );

  return { getERC20Approved, approveToken };
}

export const useQueryApproved = (
  token?: string,
  spender?: string,
  inputValue_DLP?: string | number
) => {
  const { address, provider } = useWeb3Context();
  const updataBlock = useUpDataBlockNumber();
  const dispatch = useDispatch();
  const tokenContract = useTokenContract(token);
  const { result: routerApporve } = useSingleCallResult(
    tokenContract,
    "allowance",
    [address ? address : PLACEHOLDER_ACCOUNT, spender ?? undefined]
  );

  const approveToken = useCallback(async () => {
    if (provider && address && spender && tokenContract) {
      let tx;
      try {
        tx = await tokenContract.approve(spender, ethers.constants.MaxUint256);
        dispatch(
          fetchPendingTxns({
            txnHash: tx.hash,
            text: "Approve",
            type: "approveToken",
          })
        );
        const data = await tx.wait();
        updataBlock(data);
        return tx;
      } catch (e: unknown) {
        handlerError(e);
      } finally {
        if (tx) {
          dispatch(clearPendingTxn(tx.hash));
          toastSuccess("Approve Success!", tx.hash);
        }
      }
    }
  }, [address, dispatch, provider, spender, tokenContract, updataBlock]);

  const needApprove = useMemo(() => {
    if (!routerApporve || !inputValue_DLP) return false;
    const data = routerApporve[0];
    const mde = BN(inputValue_DLP).times(BN(10).pow(18));

    return mde.gt(data.toString());
  }, [inputValue_DLP, routerApporve]);
  const ApprovedNumber = useMemo(() => {
    if (!routerApporve) return;
    return routerApporve[0];
  }, [routerApporve]);
  return {
    needApprove,
    ApprovedNumber,
    approveToken,
  };
};
