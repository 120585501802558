import { getContractAddress } from "src/Addresses";
import { DLP_TYPES } from "src/constants/tokens";

export const getOrderBookAddress = (chainID, dlpName) => {
  let orderBookAddress1 = getContractAddress(chainID, "OrderBook_PLP_β");
  if (dlpName == DLP_TYPES.DLP_2) {
    orderBookAddress1 = getContractAddress(chainID, "OrderBook_DLP_α");
  }
  return orderBookAddress1
}

export const getPositionManagerAddress = (chainID, dlpName) => {
  let positionManagerAddress1 = getContractAddress(chainId, "PositionManager_PLP_β");
  if (dlpName == DLP_TYPES.DLP_2) {
    positionManagerAddress1 = getContractAddress(chainID, "PositionManager_DLP_α");
  }
  return positionManagerAddress1
}