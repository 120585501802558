import { Box } from "@mui/material";
import Row, { RowBetween } from "src/components/Row";
import styled from "styled-components/macro";
import cx from "classnames";
import { LIMIT, MARKET } from "src/helpers/Helpers";
import Column from "src/components/Column";
const MarketBox = styled(Box)`
  padding: 13px 16px 14px 16px;
  font-family: "Gilroy";
  .mt_size {
    color: rgba(255, 255, 255, 0.54);
    font-weight: 500;
    font-size: 14px;
  }
  .mt_size1 {
    text-align: right;
  }
  .mt_cc {
    opacity: 0;
  }
  .Exchange_inputs {
    font-weight: 500;
    font-size: 23px;
    color: rgba(255, 255, 255, 0.87);
    width: 100%;
    padding-left: 0;
    font-family: "Gilroy-medium";
  }
  .dinputszie {
    color: rgba(255, 255, 255, 0.54);
    font-weight: 500;
    font-size: 24px;
    flex: 1;
    font-family: "Gilroy-medium";
    height: 29px;
    line-height: 29px;
  }
  .swchBox {
    padding: 2px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    gap: 8px;
    .swch {
      font-weight: 700;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.38);
      padding: 4px 18px;
      cursor: pointer;
      &.active {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        color: rgba(255, 255, 255, 0.87);
      }
    }
  }
  .lets {
    background: #000000;
    border-radius: 8px;
    padding: 14px 0 10px 16px;
    line-height: 1;
    flex: 1;
  }
  .row_ad {
    color: rgba(255, 255, 255, 0.87);
    font-weight: 500;
    font-size: 24px;
  }

  .lopes {
    color: rgba(255, 255, 255, 0.54);
  }
`;
type MarketTablePorps = {
  option: string;
  onChange: (value: string) => void;
  setTriggerPrice: () => void;
  entryMarkPrice: any;
  triggerPriceValue: any;
  onTriggerPriceChange: any;
};
export default function MarketTable({
  option,
  onChange,
  setTriggerPrice,
  entryMarkPrice,
  triggerPriceValue,
  onTriggerPriceChange,
}: MarketTablePorps) {
  return (
    <MarketBox>
      <RowBetween gap={"8px"}>
        <Column className="lets">
          <div className="mt_size">Price</div>
          {option == LIMIT ? (
            <Row gap={"6px"}>
              <span className="row_ad">$</span>
              <input
                type="number"
                min="0"
                placeholder="0.0"
                disabled={option != LIMIT}
                className={cx("Exchange_inputs", { lopes: option != LIMIT })}
                value={triggerPriceValue}
                defaultValue={triggerPriceValue}
                onChange={onTriggerPriceChange}
              />
            </Row>
          ) : (
            <Row gap={"6px"}>
              <span className="row_ad lopes">$</span>
              <div className="dinputszie" onClick={setTriggerPrice}>
                {entryMarkPrice}
              </div>
            </Row>
          )}
        </Column>

        <Column gap={"6px"}>
          <div className={cx("mt_size mt_size1", { mt_cc: option != LIMIT })}>
            {` Mark:  $` + entryMarkPrice}
          </div>
          <Row width={"atuo"} className="swchBox">
            <div
              className={cx("swch", {
                active: !option || option === MARKET,
              })}
              onClick={() => onChange(MARKET)}
            >
              Market
            </div>
            <div
              className={cx("swch", { active: option === LIMIT })}
              onClick={() => onChange(LIMIT)}
            >
              Limit
            </div>
          </Row>
        </Column>
      </RowBetween>
    </MarketBox>
  );
}
