import { useMediaQuery } from "@mui/material";
import { Box } from "rebass";
import { ThemedText } from "src/theme/text";
import styled from "styled-components";

const CoundownBox = styled(Box)`
  .divided {
    margin: 0 8px;
  }
`;
type dataPros = {
  coundown?: any;
};

export default function CountDown({ coundown }: dataPros) {
  const isVerySmallScreen = useMediaQuery("(max-width: 768px)");
  return (
    <CoundownBox className="coundown">
      <Box display="flex" alignItems="flex-start" className="">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          className=""
        >
          <ThemedText.Text9Bold
            fontSize={isVerySmallScreen ? 14 : 18}
            className="font-weight-900"
          >
            {coundown?.hour}
          </ThemedText.Text9Bold>
        </Box>
        <div className="divided">
          <ThemedText.Text9 fontSize={14}>:</ThemedText.Text9>
        </div>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          className=""
        >
          <ThemedText.Text9Bold
            fontSize={isVerySmallScreen ? 14 : 18}
            className="font-weight-900"
          >
            {coundown?.minutes}
          </ThemedText.Text9Bold>
        </Box>
        <div className="divided">
          <ThemedText.Text9 fontSize={14}>:</ThemedText.Text9>
        </div>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          className=""
        >
          <ThemedText.PrimaryText1Bold
            fontSize={isVerySmallScreen ? 14 : 18}
            className="font-weight-900"
          >
            {coundown?.seconds}
          </ThemedText.PrimaryText1Bold>
        </Box>
      </Box>
    </CoundownBox>
  );
}
