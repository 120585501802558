// import Arbitrum from "src/assets/images/tokens/Arbitrum.svg";
import Blast from "src/assets/images/tokens/blast.svg";

export type AddressEnvMap = { [chainId: number]: number };
export enum SupportedChainId {
  ARB_TEST = 421614,
  BLAST_TEST = 168587773,
}

export const BLOCKEXPLOERER_URLS: {
  [chainId: number]: string;
} = {
  [SupportedChainId.ARB_TEST]: "https://sepolia.arbiscan.io",
  [SupportedChainId.BLAST_TEST]: "https://testnet.blastscan.io",
};

export const RPC_URLS: {
  [chainId: number]: string;
} = {
  [SupportedChainId.ARB_TEST]:
    "https://arbitrum-sepolia.blockpi.network/v1/rpc/public",
  [SupportedChainId.BLAST_TEST]: "https://sepolia.blast.io",
};

export const CHAINNAME_LIST = {
  // [SupportedChainId.ARB_TEST]: {
  //   chainName: "Arbitrum Sepolia",
  //   nativeCurrency: "ETH",
  //   icon: Arbitrum,
  // },
  [SupportedChainId.BLAST_TEST]: {
    chainName: "BLAST Sepolia ",
    nativeCurrency: "ETH",
    icon: Blast, //TODO: BLAST svg
  },
};
