import React from "react";

import cx from "classnames";
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import "./Checkbox.css";
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";
import { ReactComponent as CheckboxChecked } from 'src/assets/images/CheckboxChecked.svg'
import { ReactComponent as CheckboxUnchecked } from 'src/assets/images/CheckboxUnchecked.svg'
import { red } from "@material-ui/core/colors";

export default function Checkbox(props) {
  const { isChecked, setIsChecked, disabled, className } = props;

  return (

    <div
      className={cx("Checkbox", { disabled, selected: isChecked }, className)}
      onClick={() => setIsChecked(!isChecked)}
    >
      <span className="Checkbox-icon-wrapper">
        {isChecked && <CheckboxChecked className="App-icon Checkbox-icon active" />}
        {!isChecked && <CheckboxUnchecked className="App-icon Checkbox-icon inactive" />}
      </span>

      <span className={cx('Checkbox-label  font-weight-6', { isChecked: isChecked })}>{props.children}</span>
    </div>
  );
}


