import { Trans } from "@lingui/macro";
import { Box, useMediaQuery } from "@mui/material";
import { ThemedText } from "src/theme/text";
import styled from "styled-components";
import { ReactComponent as ReferralCode } from "src/assets/images/pid/referralCode.svg";
import { ReactComponent as ReferralLink } from "src/assets/images/pid/referralLink.svg";
import { CopyBtn } from "src/components/Button/CopyBtn";
import { useBasicInfo } from "src/hooks/useQueryDID";
import copy from "copy-to-clipboard";
import { toastSuccess } from "src/helpers/toastHelpers";
import { shorten2 } from "src/helpers";
import { useWeb3Context } from "src/hooks";

const ReferralBox = styled(Box)`
  display: flex;
  align-items: stretch;
  .referralItem {
    min-height: 60px;
    padding: 16px 20px 16px 20px;
    border: 1px solid ${({ theme }) => theme.bg4};
    border-radius: 8px;
  }
  @media only screen and (max-width: 1280px) {
    flex-direction: column;
  }
`;

export default function Referral() {
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 768px)");
  const baseInfo = useBasicInfo();
  const { chainID } = useWeb3Context();

  return (
    <ReferralBox className="referral-view">
      <Box width={isSmallScreen ? "100%" : "450px"}>
        <Box
          display="flex"
          flexDirection={isVerySmallScreen ? "column" : "row"}
          justifyContent="space-between"
          alignItems={isVerySmallScreen ? "flex-start" : "center"}
          className="referralItem"
          gap="8px"
        >
          <Box display="flex" alignItems="center">
            <ReferralCode />
            <ThemedText.Text1Bold fontSize={18} ml="12px">
              <Trans>Referral Code</Trans>
            </ThemedText.Text1Bold>
          </Box>
          <Box display="flex" alignItems="center">
            <ThemedText.Text2 mr="16px">
              {baseInfo?.refCode ? baseInfo?.refCode : "--"}
            </ThemedText.Text2>
            <CopyBtn
              onClick={() => {
                copy(baseInfo?.refCode), toastSuccess("copy success");
              }}
            >
              Copy
            </CopyBtn>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={isVerySmallScreen ? "column" : "row"}
          justifyContent="space-between"
          alignItems={isVerySmallScreen ? "flex-start" : "center"}
          className="referralItem"
          mt="16px"
          gap="8px"
        >
          <Box display="flex" alignItems="center">
            <ReferralLink />
            <ThemedText.Text1Bold fontSize={18} ml="12px">
              <Trans>Referral Link</Trans>
            </ThemedText.Text1Bold>
          </Box>
          <Box display="flex" alignItems="center">
            <ThemedText.Text2 mr="16px">
              {shorten2(
                `https://app.dyna.finance/#/DARMY?ref=${baseInfo?.refCode}`,
                chainID
              )}
            </ThemedText.Text2>
            <CopyBtn
              onClick={() => {
                copy(
                  `https://app.dyna.finance/#/DARMY?ref=${baseInfo?.refCode}`
                ),
                  toastSuccess("copy success");
              }}
            >
              Copy
            </CopyBtn>
          </Box>
        </Box>
      </Box>
    </ReferralBox>
  );
}
