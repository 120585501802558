import { ApolloClient, InMemoryCache } from "@apollo/client";

const chainID = process.env.REACT_APP_NETWORKID

export const dynaGraphClient_blast_test = createClient("https://api.studio.thegraph.com/query/42478/dyna_blast_sepolia_stats/version/latest");
export const dynaGraphClient_arb_dev = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/pnk_stats");

// export const pidGraphClient_arb_dev = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/pnk_sepolia_stats")
export const pidGraphClient_arb_dev = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/pnk_pid_arb_sepolia");
export const pidGraphClient_blast_test = createClient("https://api.studio.thegraph.com/query/42478/dyna_blast_sepolia_pid/version/latest");

export const rankClient_blast_test = createClient("https://api.studio.thegraph.com/query/42478/dyna_blast_sepolia_ranking/version/latest")
export const rankClient_arb_dev = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/pnk_sepolia_ranking")


export const dynaGraphTradeClient_blast_test = createClient("https://api.studio.thegraph.com/query/42478/dyna_graph_blast_sepolia/version/latest");
export const dynaGraphTradeClient_arb_dev = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/pnk_graph_arb_sepolia");

export const chainlinkClient = createClient("https://api.thegraph.com/subgraphs/name/deividask/chainlink");

export function createClient(uri) {
  return new ApolloClient({
    uri,
    cache: new InMemoryCache(),
  });
}

export const getDynaGraphClient_all = (roundid) => {
  return `?roundid=${roundid}`
}

export const getDynaGraphClient = (chainID) => {
  switch (chainID) {
    case 421614:
      return dynaGraphClient_arb_dev
      break;
    case 168587773:
      return dynaGraphClient_blast_test
      break;
    default:
      return dynaGraphClient_arb_dev
      break;
  }
}

export const getTradesGraphClient = (chainID) => {
  switch (chainID) {
    case 421614:
      return dynaGraphTradeClient_arb_dev
      break;
    case 168587773:
      return dynaGraphTradeClient_blast_test
      break;
    default:
      return dynaGraphTradeClient_arb_dev
      break;
  }
}

export const getDynaGraphClient_daily = (chainID) => {
  switch (chainID) {
    case 421614:
      return rankClient_arb_dev
      break;
    case 168587773:
      return rankClient_blast_test
      break;
    default:
      return rankClient_arb_dev
      break;
  }
}

export const getPidGraphClient = (chainID) => {
  switch (chainID) {
    case 421614:
      return pidGraphClient_arb_dev
      break;
    case 168587773:
      return pidGraphClient_blast_test
      break;
    default:
      return pidGraphClient_arb_dev
      break;
  }
}


