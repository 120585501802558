import { Trans } from "@lingui/macro";
import { Box, useMediaQuery } from "@mui/material";
import { ThemedText } from "src/theme/text";
import styled from "styled-components";
import PointsInfo from "./AccountInfo/Points";
import Referral from "./Referral";
import { shorten } from "src/helpers";
import { useWeb3Context } from "src/hooks";
import { useBasicInfo } from "src/hooks/useQueryDID";
import NoPid from "./AccountInfo/NoPid";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import Dots from "src/components/Dots";
import { RANK_LIST, RANK_LIST_ICON } from "src/helpers/rankConstants";
import CountDown from "./AccountInfo/CountDown";
import LevelTooltip from "./AccountInfo/LevelTooltip";
import EPointProgress from "./AccountInfo/EPointProgress";
import RebatesInfo from "./RebatesInfo";
import TeamInfo from "./TeamInfo";
import TeamList from "./TeamList";
import { getPidGraphClient } from "src/Api/common";
import { gql } from "@apollo/client";

const PortfolioBox = styled(Box)`
  width: 100%;
  .titleBox {
    padding-bottom: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.bg4};

    .account {
      margin-top: 48px;
    }
  }
  @media only screen and (max-width: 1280px) {
    .titleBox {
      padding-bottom: 16px;

      .account {
        margin-top: 24px;
      }
      .infoItem {
        width: 100%;
      }
    }
  }
`;

export default function Portfolio() {
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 768px)");
  const { chainID, address } = useWeb3Context();
  const [teamList, setTeamList] = useState<any>([]);
  const [teamBond, setTeamBond] = useState<any>(0);
  const [teamStaked, setTeamStaked] = useState<any>(0);
  const baseInfo = useBasicInfo();
  const location: any = useLocation();

  const [showNo, SetShowNo] = useState(false);
  useEffect(() => {
    if (location.state?.showmint) {
      SetShowNo(true);
    }
  }, [location]);

  const query_str = gql(`{
  accounts
  (where:{address:"${address.toLowerCase()}"})
  {
    address
    teamStaked
    teamBond
  }
}`);
  useEffect(() => {
    if (address) {
      getPidGraphClient(chainID)
        ?.query({ query: query_str })
        .then((res) => {
          const data = res.data.accounts;
          setTeamList(data);
          // const bondSum = data.reduce((pre, curr) => {
          //   pre = pre.plus(BN(Number(curr.teamBond) / 1e30));
          //   return pre;
          // }, BN(0));
          // setTeamBond(bondSum);
          // const stakedSum = data.reduce((pre, curr) => {
          //   pre = pre.plus(BN(Number(curr.teamStaked) / 1e30));
          //   return pre;
          // }, BN(0));
          // setTeamStaked(stakedSum);
          // console.log("data", data, address.toLowerCase(), bondSum);
        })
        .catch(console.warn);
    }
  }, [chainID, address, query_str]);

  return (
    <PortfolioBox className="portfolio-view">
      <Box display="flex" justifyContent="center" className="titleBox">
        <div className="container">
          {/* <TradeInfo /> */}
          <ThemedText.Title mt="20px">
            <Trans>Profile</Trans>
          </ThemedText.Title>
          {baseInfo?.id > 0 && (
            <Box
              display="flex"
              flexDirection={isSmallScreen ? "column" : "row"}
              alignItems="center"
              className="account"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                className="infoItem"
              >
                <ThemedText.Text1Bold mt="4px">
                  {baseInfo?.nickName}
                </ThemedText.Text1Bold>
                <ThemedText.Text4 fontSize={12} lineHeight="168%">
                  {isSmallScreen ? shorten(address) : address}
                </ThemedText.Text4>
              </Box>
              <Box display="flex" alignItems="stretch">
                <Box
                  ml={isVerySmallScreen ? 0 : "96px"}
                  mt={isVerySmallScreen ? "30px" : 0}
                  className="infoItem"
                >
                  <Box display="flex" alignItems="center" className="">
                    <Dots bgColor="#FFFD02" />
                    <ThemedText.Text4Bold ml="8px">
                      <Trans>Rank</Trans>
                    </ThemedText.Text4Bold>
                    <ThemedText.Text5 fontSize={12} ml="16px">
                      <CountDown />
                    </ThemedText.Text5>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt="12px"
                    className=""
                  >
                    {RANK_LIST_ICON[baseInfo?.rank]}
                    <ThemedText.Text1Bold fontSize={18} ml="8px">
                      {RANK_LIST[baseInfo?.rank]}
                    </ThemedText.Text1Bold>
                    <Box display="inline-flex" ml="10px" className="pointer">
                      <LevelTooltip />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    mt="8px"
                    className=""
                  >
                    <EPointProgress />
                  </Box>
                </Box>
                <Box
                  ml={isVerySmallScreen ? 0 : "48px"}
                  mt={isVerySmallScreen ? "30px" : 0}
                  className="infoItem"
                >
                  <PointsInfo />
                </Box>
              </Box>
              <Box
                ml={isVerySmallScreen ? 0 : "40px"}
                mt={isVerySmallScreen ? "30px" : 0}
                className="infoItem"
              >
                <Referral />
              </Box>
            </Box>
          )}
        </div>
      </Box>
      <Box display="flex" justifyContent="center">
        <div className="container">
          {Number(baseInfo?.id) > 0 ? (
            <>
              <RebatesInfo />
              <TeamInfo teamBond={teamBond} teamStaked={teamStaked} />
              <TeamList teamList={teamList} />
            </>
          ) : (
            <Box mt={isSmallScreen ? "20px" : "60px"}>
              <NoPid showNo={showNo} />
            </Box>
          )}
          {/* <ReferStep /> */}
        </div>
      </Box>
    </PortfolioBox>
  );
}
